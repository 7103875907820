package com.rabbitsign.web

import com.rabbitsign.common.UserInfo
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass


const val IDLE_WARNING = 60 * 20  // warn user if idle for 20 min
const val IDLE_TIMEOUT = 60 * 30  // kick user if idle for 30 min
const val FIVE_MINUTES = 60 * 5

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("RabbitSignPage")
abstract class RabbitSignPage {
    companion object val log = Logger(this::class.simpleName)

    @JsName("run")
    fun run() {
        try {
            runPage()
        } catch (e: Exception) {
            window.alert(e.message ?: (e::class.simpleName ?: "Error!"))
        }
    }

    abstract fun runPage()

    protected val userInfo get() = UserInfo.newInstance(window.atob(getCookie("user_info")))

    fun initializeNavbar() {
        log.info("RabbitSignPage.initializeNavbar")

        fun handleScroll() {
            if (window.scrollY > 0) {
                document.querySelector(".js-header-scroll")!!.addClass("header__sticky")
            } else {
                document.querySelector(".js-header-scroll")!!.removeClass("header__sticky")
            }
        }

        handleScroll()
        window.addEventListener("scroll", { handleScroll() })

        // ensure the sidebar modal shows up above the modal backdrop
        // this is a rather ugly solution, caused by the need to have both the sidebar and headerContainer under the same div because that's how DSL generates it
        js("""
            $('#sidebarModal').on('show.bs.modal', function() {
                document.getElementById("navbarContainer").classList.add("high-overlay");
                document.getElementById("headerContainer").classList.add("invisible");
            });
            $('#sidebarModal').on('hidden.bs.modal', function() {
                document.getElementById("navbarContainer").classList.remove("high-overlay");
                document.getElementById("headerContainer").classList.remove("invisible");
            });
        """)

        if (userLoggedIn()) {
            hide(".logged-out-display")
            show(".logged-in-display")
        }
    }

    fun initializeInactivity() {
        log.info("RabbitSignPage.initializeInactivity")

        if (userLoggedIn()) createSessionEventHandlers()
        else window.location.replace("/login")
    }

    private fun createSessionEventHandlers() {
        var lastActiveTime = currentTimeInSeconds()

        window.setInterval({
            val idleSeconds = currentTimeInSeconds() - lastActiveTime
            if (idleSeconds % 30 == 0L && idleSeconds > 0) {
                log.info("user has been idle for $idleSeconds seconds")
            }
            if (idleSeconds >= IDLE_WARNING && !SessionInfoModal.visible) {
                displayIdleMessage(idleSeconds, IDLE_TIMEOUT - idleSeconds)
            }

            if (currentTimeInSeconds() + FIVE_MINUTES >= SessionInfoModal.expirationTime) {
                displayExpiringSessionMessage(SessionInfoModal.expirationTime - currentTimeInSeconds())
            }
            if (idleSeconds >= IDLE_TIMEOUT || currentTimeInSeconds() >= SessionInfoModal.expirationTime) {
                window.location.assign("/logout")
            }
        }, 1000)  // check every second

        document.addEventListener("click", { lastActiveTime = currentTimeInSeconds() })
        document.addEventListener("mousemove", { lastActiveTime = currentTimeInSeconds() })
        document.addEventListener("keydown", { lastActiveTime = currentTimeInSeconds() })
    }
}

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("PageWithNavbar")
object PageWithNavbar : RabbitSignPage() {
    override fun runPage() {
        log.info("run")
        initializeNavbar()
    }
}
