package com.rabbitsign.common

import kotlinx.serialization.Serializable

typealias Name = String
typealias Email = String
typealias SignerMap = Map<Email, SignerNameAndFields>

enum class FolderState {
    CREATED, SIGNED, CANCELED
}

@Serializable
data class Folder(
    val title: String, val summary: String, val docInfo: List<DocInfo>,
    val signerInfo: SignerMap, val dateFormat: DateFormat = DateFormat.MM_DD_YYYY_SLASH,
    val ccList: List<Email> = emptyList(), val senderFieldValues: SenderFieldList = emptyList(),
    val sourceTemplateId: String = "", val batchId: String = "N/A",
    val signerNotifLang: IsoLangCode = IsoLangCode.en
)

@Serializable
data class FolderInfo(
    val folderId: String,
    val creatorEmail: Email,
    val title: String,
    val summary: String,
    val folderStatus: FolderState,
    val signerNames: List<String>,
    val thisUserNeedsToSign: Boolean,
    val othersNeedToSign: Boolean,
    val ccList: List<String>,
    val creationTimeUtc: String,
    val downloadUrl: String
)

@Serializable
data class ApiFolderInfo(
    val folderId: String,
    val creatorEmail: Email,
    val title: String,
    val summary: String,
    val folderStatus: FolderState,
    val signers: List<SignerSummary>,
    val ccList: List<Email>,
    val creationTimeUtc: String,
    val downloadUrl: String
)

@Serializable
data class FolderPage(val folderInfo: List<FolderInfo>, val hasMore: Boolean)

@Serializable
data class ApiFolderPage(val folderInfo: List<ApiFolderInfo>, val hasMore: Boolean)

@Serializable
data class FolderInstantiation(val folder: Folder, val date: String)

const val SIGNING_ORDER_1 = 1
const val SIGNING_ORDER_2 = 2

@Serializable
data class SignerNameAndFields(val name: Name, val fields: SignerFields, val signingOrder: Int = SIGNING_ORDER_1)

@Serializable
data class FolderId(val folderId: String)

@Serializable
data class BatchId(val batchId: String)

const val ME_NOW = "Me (Now)"
