package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.get
import kotlin.math.*
import kotlin.random.Random
import kotlin.random.nextULong

const val FILLED_SIGNATURE_TOOLTIP = "This is a signature field. To customize your signature, click on the signature field."
const val FILLED_INITIALS_TOOLTIP = "This is an initials field. To customize your initials, click the initials field."

const val MIN_IMAGE_WIDTH = 60.0
const val MIN_IMAGE_HEIGHT = 40.0

const val NEW_SIGNING_OPTION_ID = "newSigningOptionId"
const val S3_SIGNING_OPTION = "S3SigningOption"
const val TEXT_SIGNING_OPTION = "TextSigningOption"

const val RESOLUTION_SCALE = 2

private enum class SigningOptionSelection {
    EXISTING_TEXT, EXISTING_IMAGE, NEW_TEXT, NEW_HAND_DRAWN, NEW_IMAGE, NONE
}

private enum class ResizeType {
    HORIZONTAL, VERTICAL, DIAGONAL, NONE
}

class SigningOptionsHandler(private val fieldType: FieldType, private val signingOptionToken: SigningOptionToken) {
    companion object val log = Logger(this::class.simpleName)

    private val modalName = "${fieldType.toString().lowercase()}OptionsModal"
    private val modal = document.getElementById("RabbitSign-display-$modalName-div") as HTMLElement

    // A triple which either represents a signature signing option or an initials signing option
    // For signature: (font family, text content, id)
    // For initials: (the constant val IMAGE, image src, id)
    var default = Triple("", "", "")

    init {
        log.info("init with fieldType=$fieldType, signingOptionToken=$signingOptionToken")
    }

    fun initializeModal(defaultValue: String) {
        log.info("initializeModal with defaultValue=$defaultValue")

        // prevent the ghost image dragging stuff
        val modalChildren = document.querySelectorAll("#RabbitSign-display-$modalName-div *").asList().map { it as HTMLElement }
        for (modalChild in modalChildren) {
            modalChild.ondragstart = { it.preventDefault() }
        }

        val existingSigningOptions = modal.querySelectorAll(".signing-option").asList().map { it as HTMLElement }
        for (existingSigningOption in existingSigningOptions) {
            val deleteButton = existingSigningOption.querySelector(".close") as HTMLElement
            deleteButton.onclick = { askForDeleteConfirmation(existingSigningOption, existingSigningOption.getAttribute("data-signing-option-id")!!) }
        }

        val textOptionInput = document.getElementById("RabbitSign-display-$modalName-textOption-input") as HTMLInputElement
        textOptionInput.value = defaultValue
        textOptionInput.autoResize()

        // https://github.com/harvesthq/chosen/pull/2574, using https://github.com/JJJ/chosen
        if (fieldType == FieldType.SIGNATURE) {
            js(
                "$('#RabbitSign-display-signatureOptionsModal-fontFamily-select').chosen({" +
                        "width: '10rem', disable_search_threshold: 12, parser_config: {copy_data_attributes: true}});"
            )
        } else {
            js(
                "$('#RabbitSign-display-initialsOptionsModal-fontFamily-select').chosen({" +
                        "width: '10rem', disable_search_threshold: 12, parser_config: {copy_data_attributes: true}});"
            )
        }
        val fontFamilySelect = document.getElementById("RabbitSign-display-$modalName-fontFamily-select") as HTMLSelectElement
        val fontFamilySelectChosen = document.querySelector("#RabbitSign-display-$modalName-fontFamily-select + .chosen-container") as HTMLElement?
        if (fontFamilySelect.selectedOptions.length > 0) {
            fontFamilySelectChosen?.fontFamily = (fontFamilySelect.selectedOptions[0] as HTMLElement).fontFamily
        }
        fontFamilySelect.onchange = {
            if (fontFamilySelect.selectedOptions.length > 0) {
                val selectedFontFamily = (fontFamilySelect.selectedOptions[0] as HTMLElement).fontFamily
                fontFamilySelectChosen?.fontFamily = selectedFontFamily
                textOptionInput.fontFamily = selectedFontFamily
            }
        }

        val resetCanvasButton = document.getElementById("RabbitSign-display-$modalName-resetHandDrawnCanvas-button") as HTMLElement
        resetCanvasButton.onclick = { clearHandDrawnCanvas() }

        clearHandDrawnCanvas()
    }

    private fun clearHandDrawnCanvas() {
        log.info("clearHandDrawnCanvas")
        val canvas = document.getElementById("RabbitSign-display-$modalName-handDrawn-canvas") as HTMLCanvasElement
        val context = canvas.getContext("2d") as CanvasRenderingContext2D
        context.clearCanvas()
    }

    fun openSigningOptionsModal(field: HTMLElement, onsubmit: () -> Unit) {
        log.info("openSigningOptionsModal with field.classList=${field.classList}")

        var currentSelection = SigningOptionSelection.NONE

        val submitButton = document.getElementById("RabbitSign-display-$modalName-use-button") as HTMLButtonElement
        submitButton.disabled = true
        submitButton.onclick = {
            val selectedOption = getSelectedOption(currentSelection)

            val useAsDefaultCheckbox = document.getElementById("RabbitSign-display-$modalName-useAsDefault-input") as HTMLInputElement
            if (useAsDefaultCheckbox.checked) {
                default = selectedOption
            }

            when (currentSelection) {
                SigningOptionSelection.EXISTING_TEXT, SigningOptionSelection.NEW_TEXT -> {
                    transformIntoSigningText(field, selectedOption)
                }
                SigningOptionSelection.EXISTING_IMAGE, SigningOptionSelection.NEW_HAND_DRAWN, SigningOptionSelection.NEW_IMAGE -> {
                    transformIntoSigningImage(field, selectedOption)
                }
                SigningOptionSelection.NONE -> {
                    displayWarningMessage(906, "Unable to sign field.")
                }
            }

            if (currentSelection in listOf(SigningOptionSelection.NEW_TEXT, SigningOptionSelection.NEW_IMAGE, SigningOptionSelection.NEW_HAND_DRAWN)) {
                createNewSigningOption(selectedOption, currentSelection)
            }

            onsubmit()
            0
        }

        modal.querySelector(".displayed")?.removeClass("displayed")

        val addNewOptionButton = document.getElementById("RabbitSign-display-$modalName-addNewOption-button") as HTMLElement
        addNewOptionButton.onclick = {
            currentSelection = SigningOptionSelection.NONE
            modal.querySelector(".displayed")?.removeClass("displayed")
            hide(".signing-options-body")
            hide(".add-new-option-button-container")
            show("#RabbitSign-display-$modalName-addNewDialog-div")
            submitButton.disabled = true
            0
        }

        for (textSigningOptionNode in modal.querySelectorAll(".signing-option.text-option").asList()) {
            val textSigningOption = textSigningOptionNode as HTMLElement
            textSigningOption.onclick = {
                currentSelection = SigningOptionSelection.EXISTING_TEXT
                submitButton.disabled = false

                updateDisplayedOption(textSigningOption)
                hide(".signing-options-body")
                show(".add-new-option-button-container")
                showTextPreview(textSigningOption)

                if (!(it.target as HTMLElement).isCloseButton()) {
                    hide(".delete-signing-option-confirmation")
                }
                0
            }
        }
        for (imageSigningOptionNode in modal.querySelectorAll(".signing-option.image-option").asList()) {
            val imageSigningOption = imageSigningOptionNode as HTMLElement
            imageSigningOption.onclick = {
                currentSelection = SigningOptionSelection.EXISTING_IMAGE
                submitButton.disabled = false

                updateDisplayedOption(imageSigningOption)
                hide(".signing-options-body")
                show(".add-new-option-button-container")
                showImagePreview(imageSigningOption)

                if (!(it.target as HTMLElement).isCloseButton()) {
                    hide(".delete-signing-option-confirmation")
                }
                0
            }
        }

        val addNewTextOptionButton = document.getElementById("RabbitSign-display-$modalName-addNewTextOption-button") as HTMLElement
        addNewTextOptionButton.onclick = {
            currentSelection = SigningOptionSelection.NEW_TEXT
            submitButton.disabled = false

            hide(".signing-options-body")
            show(".add-new-option-button-container")
            show("#RabbitSign-display-$modalName-textEditor-div")
        }

        val addNewHandDrawnOptionButton = document.getElementById("RabbitSign-display-$modalName-addNewHandDrawnOption-button") as HTMLElement
        addNewHandDrawnOptionButton.onclick = {
            currentSelection = SigningOptionSelection.NEW_HAND_DRAWN
            submitButton.disabled = false

            hide(".signing-options-body")
            show(".add-new-option-button-container")
            showHandDrawnCanvas()
        }

        val widthToHeightRatio = if (fieldType == FieldType.INITIALS) 2 else 6
        val newImageUploadForm = document.getElementById("RabbitSign-display-$modalName-newImageUpload-form") as HTMLFormElement
        val newImageUploadInput = document.getElementById("RabbitSign-display-$modalName-newImageUpload-input") as HTMLInputElement
        newImageUploadInput.oninput = {
            currentSelection = SigningOptionSelection.NEW_IMAGE
            submitButton.disabled = false

            val file = newImageUploadInput.files?.get(0)
            if (file != null) {
                hide(".signing-options-body")
                show(".add-new-option-button-container")
                showImageCropper(widthToHeightRatio, file)
                newImageUploadForm.reset()
            }
            0
        }

        hide(".signing-options-body")

        if (modal.querySelector(".signing-option") != null) {
            show(".add-new-option-button-container")
            show("#RabbitSign-display-$modalName-chooseExistingOption-div")
        } else {
            hide(".add-new-option-button-container")
            show("#RabbitSign-display-$modalName-addNewDialog-div")
        }

        when (fieldType) {
            FieldType.SIGNATURE -> js("$('#RabbitSign-display-signatureOptionsModal-div').modal();")
            FieldType.INITIALS -> js("$('#RabbitSign-display-initialsOptionsModal-div').modal();")
            else -> throw NoWhenBranchMatchedException("Only signatures and initials fields have signingOptionsModals")
        }
    }

    private fun createNewSigningOption(selectedOption: Triple<String, String, String>, currentSelection: SigningOptionSelection) {
        log.info("createNewSigningOption with selectedOption=$selectedOption, currentSelection=$currentSelection")

        val previewCarousel = document.getElementById("RabbitSign-display-$modalName-previewCarousel-div") as HTMLElement
        val newOptionContainer = document.createDiv("signing-option", "m-2", "new-signing-option")
        newOptionContainer.setAttribute("data-value", selectedOption.second)
        newOptionContainer.setAttribute("data-signing-option-id", selectedOption.third)
        if (currentSelection == SigningOptionSelection.NEW_TEXT) {
            val newOption = document.createDiv("signing-option-content", "user-select-none")
            newOption.textContent = selectedOption.second

            newOptionContainer.addClass("text-option")
            newOptionContainer.setAttribute("data-font-family", selectedOption.first)
            newOptionContainer.appendChild(newOption)
        } else {
            val newOption = document.createElement("img") as HTMLImageElement
            newOption.addClass("signing-option-content", "user-select-none")
            newOption.src = selectedOption.second

            newOptionContainer.addClass("image-option")
            newOptionContainer.appendChild(newOption)
        }
        val deleteButton = createDeleteButton()
        deleteButton.addClass("red-delete-button", "top-right-button")
        deleteButton.onclick = { askForDeleteConfirmation(newOptionContainer, selectedOption.third) }
        newOptionContainer.appendChild(deleteButton)
        previewCarousel.prepend(newOptionContainer)
    }

    private fun askForDeleteConfirmation(signingOptionPreview: HTMLElement, signingOptionId: String) {
        log.info("askForDeleteConfirmation with signingOptionPreview.classList=${signingOptionPreview.classList}, signingOptionId=$signingOptionId")

        val typeStr = fieldType.toString().lowercase()
        if (document.querySelector(".filled[data-signing-option-id=\"$signingOptionId\"]") != null) {
            displayWarningMessage(
                907,
                "${
                    if (fieldType == FieldType.INITIALS) "These initials are" else "This signature is"
                } currently being used to sign the document and can't be deleted. " +
                        "Please replace it with another $typeStr option.",
            )
            return
        }

        show(".delete-signing-option-confirmation")

        val cancelButtons = modal.querySelectorAll(".cancel-delete-signing-option").asList()
        for (cancelButton in cancelButtons) {
            (cancelButton as HTMLElement).onclick = {
                hide(".delete-signing-option-confirmation")
            }
        }

        val deleteButtons = modal.querySelectorAll(".confirm-delete-signing-option").asList().map { it as HTMLElement }
        for (deleteButton in deleteButtons) {
            deleteButton.onclick = {
                if (!signingOptionId.startsWith(NEW_SIGNING_OPTION_ID)) {
                    MainScope().launch {
                        getApi().callDeleteSigningOption(signingOptionToken, signingOptionId)
                        Unit
                    }
                }

                signingOptionPreview.remove()

                hide(".delete-signing-option-confirmation")
                hide(".signing-options-body")

                if (modal.querySelector(".signing-option") != null) {
                    show(".add-new-option-button-container")
                    show("#RabbitSign-display-$modalName-chooseExistingOption-div")
                } else {
                    hide(".add-new-option-button-container")
                    show("#RabbitSign-display-$modalName-addNewDialog-div")
                }

                if (default.third == signingOptionId) {
                    default = Triple("", "", "")
                }

                val submitButton = document.getElementById("RabbitSign-display-$modalName-use-button") as HTMLButtonElement
                submitButton.disabled = true

                Unit
            }
        }
    }

    private fun showHandDrawnCanvas() {
        log.info("showHandDrawnCanvas")

        val canvas = document.getElementById("RabbitSign-display-$modalName-handDrawn-canvas") as HTMLCanvasElement
        val context = canvas.getContext("2d") as CanvasRenderingContext2D

        var pos: Pair<Double, Double>? = null
        canvas.onclick = {
            val x = it.offsetX * RESOLUTION_SCALE
            val y = it.offsetY * RESOLUTION_SCALE
            context.beginPath()
            context.lineWidth = 5.0 * RESOLUTION_SCALE
            context.lineCap = CanvasLineCap.ROUND
            context.moveTo(x, y)
            context.lineTo(x, y)
            context.stroke()
            0
        }
        canvas.onpointerdown = {
            pos = Pair(it.offsetX * RESOLUTION_SCALE, it.offsetY * RESOLUTION_SCALE)
            0
        }
        canvas.onpointermove = {
            if (pos != null) {
                val (oldX, oldY) = pos!!
                val x = it.offsetX * RESOLUTION_SCALE
                val y = it.offsetY * RESOLUTION_SCALE
                val movement = sqrt((x - oldX).pow(2) + (y - oldY).pow(2))
                context.beginPath()
                context.lineWidth = max(2.0 * RESOLUTION_SCALE, 5 * 0.99.pow(movement) * RESOLUTION_SCALE)
                context.lineCap = CanvasLineCap.ROUND
                context.moveTo(oldX, oldY)
                context.lineTo(x, y)
                context.stroke()

                pos = Pair(x, y)
            }
        }
        modal.onpointerup = {
            pos = null
            0
        }

        show("#RabbitSign-display-$modalName-handDrawnEditor-div")
    }

    private fun showImageCropper(widthToHeightRatio: Int, file: File) {
        log.info("showImageCropper with widthToHeightRatio=$widthToHeightRatio, file.name=${file.name}")

        val fadedImageContainer = document.getElementById("RabbitSign-display-$modalName-fadedImageContainer-div") as HTMLElement
        val fadedImagePreview = document.getElementById("RabbitSign-display-$modalName-fadedImagePreview-img") as HTMLImageElement
        fadedImagePreview.onload = {
            if (fadedImagePreview.naturalWidth == 0 || fadedImagePreview.naturalHeight == 0) {
                displayWarningMessage(904, "Your image seems to have a width and/or height of 0. Please upload a different image.")
            } else {
                val scale = min(
                    100.0 * widthToHeightRatio / fadedImagePreview.naturalWidth,
                    100.0 / fadedImagePreview.naturalHeight
                )
                fadedImagePreview.setWidth(fadedImagePreview.naturalWidth * scale)
                fadedImagePreview.setHeight(fadedImagePreview.naturalHeight * scale)

                val canvas = document.getElementById("RabbitSign-display-$modalName-croppedImagePreview-canvas") as HTMLCanvasElement
                val canvasWidth = canvas.getWidth() / RESOLUTION_SCALE
                val canvasHeight = canvas.getHeight() / RESOLUTION_SCALE
                moveImageContainer((canvasWidth - fadedImagePreview.getWidth()) / 2.0, (canvasHeight - fadedImagePreview.getHeight()) / 2.0)

                val resetImagePositionButton = document.getElementById("RabbitSign-display-$modalName-resetImagePosition-button") as HTMLElement
                resetImagePositionButton.onclick = {
                    moveImageContainer((canvasWidth - fadedImagePreview.getWidth()) / 2.0, (canvasHeight - fadedImagePreview.getHeight()) / 2.0)
                }

                val resetImageSizeButton = document.getElementById("RabbitSign-display-$modalName-resetImageSize-button") as HTMLElement
                resetImageSizeButton.onclick = {
                    fadedImagePreview.setWidth(fadedImagePreview.naturalWidth * scale)
                    fadedImagePreview.setHeight(fadedImagePreview.naturalHeight * scale)

                    val x = fadedImageContainer.fieldPosX
                    val y = fadedImageContainer.fieldPosY
                    renderImageOntoCanvas(canvas, fadedImagePreview, x, y)
                }

                initializeImageResizerMover(fadedImageContainer, fadedImagePreview, canvas)
            }
            0
        }

        getDataUrl(file) {
            fadedImagePreview.src = it
        }

        show("#RabbitSign-display-$modalName-imageEditor-div")
    }

    private fun initializeImageResizerMover(fadedImageContainer: HTMLElement, fadedImagePreview: HTMLImageElement, canvas: HTMLCanvasElement) {
        log.info("initializeImageResizerMover with fadedImageContainer, fadedImagePreview, canvas")

        var resizeType = ResizeType.NONE
        val horizontalResizeButton = fadedImageContainer.querySelector(".horizontal-resize") as HTMLElement
        horizontalResizeButton.onpointerdown = { resizeType = ResizeType.HORIZONTAL; 0 }

        val diagonalResizeButton = fadedImageContainer.querySelector(".diagonal-resize") as HTMLElement
        diagonalResizeButton.onpointerdown = { resizeType = ResizeType.DIAGONAL; 0 }

        val verticalResizeButton = fadedImageContainer.querySelector(".vertical-resize") as HTMLElement
        verticalResizeButton.onpointerdown = { resizeType = ResizeType.VERTICAL; 0 }

        val imageEditor = document.getElementById("RabbitSign-display-$modalName-imageEditor-div") as HTMLElement
        var moveOffsetX: Double? = null
        var moveOffsetY: Double? = null
        fadedImageContainer.onpointerdown = {
            moveOffsetX = it.offsetX
            moveOffsetY = it.offsetY
            0
        }
        modal.onpointerup = {
            moveOffsetX = null
            moveOffsetY = null
            resizeType = ResizeType.NONE
            0
        }
        modal.onpointermove = {
            val container = document.getElementById("RabbitSign-display-$modalName-imageEditor-div") as HTMLElement
            if (!container.hasClass("hidden")) {
                // here, we can't use it.offsetX since the imageEditor element has different-sized children
                val clientX = it.clientX.toDouble().coerceIn(imageEditor.getBoundingClientRect().left, imageEditor.getBoundingClientRect().right)
                val clientY = it.clientY.toDouble().coerceIn(imageEditor.getBoundingClientRect().top, imageEditor.getBoundingClientRect().bottom)
                val newWidth = (clientX - fadedImagePreview.getBoundingClientRect().x).coerceAtLeast(MIN_IMAGE_WIDTH)
                val newHeight = (clientY - fadedImagePreview.getBoundingClientRect().y).coerceAtLeast(MIN_IMAGE_HEIGHT)
                when (resizeType) {
                    ResizeType.HORIZONTAL -> {
                        fadedImagePreview.setWidth(newWidth)
                    }
                    ResizeType.DIAGONAL -> {
                        val currentRatio = fadedImagePreview.getWidth() / fadedImagePreview.getHeight()
                        var dimensions = if (newHeight * currentRatio <= newWidth) {
                            Pair(newHeight * currentRatio, newHeight)
                        } else {
                            Pair(newWidth, newWidth / currentRatio)
                        }
                        if (dimensions.first < MIN_IMAGE_WIDTH) {
                            dimensions = Pair(MIN_IMAGE_WIDTH, MIN_IMAGE_WIDTH / currentRatio)
                        }
                        if (dimensions.second < MIN_IMAGE_HEIGHT) {
                            dimensions = Pair(MIN_IMAGE_HEIGHT * currentRatio, MIN_IMAGE_HEIGHT)
                        }
                        fadedImagePreview.setWidth(dimensions.first)
                        fadedImagePreview.setHeight(dimensions.second)
                    }
                    ResizeType.VERTICAL -> {
                        fadedImagePreview.setHeight(newHeight)
                    }
                    ResizeType.NONE -> {
                        // do nothing
                    }
                }

                if (moveOffsetX != null && moveOffsetY != null && resizeType == ResizeType.NONE) {  // move the image
                    val minX = imageEditor.getBoundingClientRect().left
                    val minY = imageEditor.getBoundingClientRect().top
                    val maxX = imageEditor.getBoundingClientRect().right - fadedImagePreview.getWidth()
                    val maxY = imageEditor.getBoundingClientRect().bottom - fadedImagePreview.getHeight()
                    val offsetX = clientX.coerceIn(minX + moveOffsetX!!, maxX + moveOffsetX!!) - imageEditor.getBoundingClientRect().x
                    val offsetY = clientY.coerceIn(minY + moveOffsetY!!, maxY + moveOffsetY!!) - imageEditor.getBoundingClientRect().y
                    val (ox, oy) = getCanvasOffset()
                    val x = offsetX - moveOffsetX!! - ox
                    val y = offsetY - moveOffsetY!! - oy
                    moveImageContainer(x, y)
                } else {
                    val x = fadedImageContainer.fieldPosX
                    val y = fadedImageContainer.fieldPosY
                    renderImageOntoCanvas(canvas, fadedImagePreview, x, y)
                }
            }
        }
    }

    private fun updateDisplayedOption(signingOption: HTMLElement) {
        log.info("updateDisplayedOption with signingOption")
        modal.querySelector(".displayed")?.removeClass("displayed")
        signingOption.addClass("displayed")
    }

    private fun getSelectedOption(currentSelection: SigningOptionSelection): Triple<String, String, String> {
        log.info("getSelectedOption with currentSelection=$currentSelection")

        return when (currentSelection) {
            SigningOptionSelection.EXISTING_TEXT -> {
                val existingTextOption = document.getElementById("RabbitSign-display-$modalName-textPreview-div") as HTMLElement
                Triple(
                    existingTextOption.getAttribute("data-font-family")!!,
                    existingTextOption.textContent!!,
                    existingTextOption.getAttribute("data-signing-option-id")!!
                )
            }
            SigningOptionSelection.EXISTING_IMAGE -> {
                val existingImageOption = document.getElementById("RabbitSign-display-$modalName-imagePreview-img") as HTMLImageElement
                Triple(IMAGE, existingImageOption.src, existingImageOption.getAttribute("data-signing-option-id")!!)
            }
            SigningOptionSelection.NEW_TEXT -> {
                val newTextOption = document.getElementById("RabbitSign-display-$modalName-textOption-input") as HTMLInputElement
                Triple(newTextOption.getAttribute("data-font-family")!!, newTextOption.value, generateTempOptionId(TEXT_SIGNING_OPTION))
            }
            SigningOptionSelection.NEW_HAND_DRAWN -> {
                val canvas = document.getElementById("RabbitSign-display-$modalName-handDrawn-canvas") as HTMLCanvasElement
                Triple(IMAGE, canvas.toDataURL(), generateTempOptionId(S3_SIGNING_OPTION))
            }
            SigningOptionSelection.NEW_IMAGE -> {
                val canvas = document.getElementById("RabbitSign-display-$modalName-croppedImagePreview-canvas") as HTMLCanvasElement
                Triple(IMAGE, canvas.toDataURL(), generateTempOptionId(S3_SIGNING_OPTION))
            }
            SigningOptionSelection.NONE -> Triple("", "", "")
        }
    }

    // using the regular random is okay here since this ID is temporary
    private fun generateTempOptionId(typeStr: String): String = "$NEW_SIGNING_OPTION_ID-${Random.nextULong()}${Random.nextULong()}-$typeStr"

    private fun showTextPreview(selectedOption: HTMLElement) {
        log.info("showTextPreview with selectedOption=$selectedOption")

        val preview = document.getElementById("RabbitSign-display-$modalName-textPreview-div") as HTMLElement
        preview.textContent = selectedOption.getAttribute("data-value")!!
        preview.setAttribute("data-signing-option-id", selectedOption.getAttribute("data-signing-option-id")!!)
        preview.setAttribute("data-font-family", selectedOption.getAttribute("data-font-family")!!)
        show("#RabbitSign-display-$modalName-textPreviewContainer-div")
    }

    private fun showImagePreview(selectedOption: HTMLElement) {
        log.info("showImagePreview with selectedOption=$selectedOption")

        val preview = document.getElementById("RabbitSign-display-$modalName-imagePreview-img") as HTMLImageElement
        preview.src = selectedOption.getAttribute("data-value")!!
        preview.setAttribute("data-signing-option-id", selectedOption.getAttribute("data-signing-option-id")!!)
        show("#RabbitSign-display-$modalName-imagePreviewContainer-div")
    }

    // move the center of the image container, coordinates relative to top left of canvas
    private fun moveImageContainer(x: Double, y: Double) {
        log.info("moveImageContainer with x=$x, y=$y")

        val canvas = document.getElementById("RabbitSign-display-$modalName-croppedImagePreview-canvas") as HTMLCanvasElement
        val fadedImage = document.getElementById("RabbitSign-display-$modalName-fadedImagePreview-img") as HTMLImageElement

        val (ox, oy) = getCanvasOffset()

        val fadedImageContainer = document.getElementById("RabbitSign-display-$modalName-fadedImageContainer-div") as HTMLElement
        fadedImageContainer.style.left = "${x + ox}px"
        fadedImageContainer.style.top = "${y + oy}px"

        fadedImageContainer.fieldPosX = x
        fadedImageContainer.fieldPosY = y

        renderImageOntoCanvas(canvas, fadedImage, x, y)
    }

    private fun getCanvasOffset(): Pair<Double, Double> {
        log.info("getCanvasOffset")

        val canvas = document.getElementById("RabbitSign-display-$modalName-croppedImagePreview-canvas") as HTMLCanvasElement
        val container = document.getElementById("RabbitSign-display-$modalName-imageEditor-div") as HTMLElement

        val offsetX = canvas.getBoundingClientRect().x - container.getBoundingClientRect().x
        val offsetY = canvas.getBoundingClientRect().y - container.getBoundingClientRect().y
        return Pair(offsetX, offsetY)
    }

    private fun renderImageOntoCanvas(canvas: HTMLCanvasElement, image: HTMLImageElement, x: Double, y: Double) {
        log.info("renderImageOntoCanvas with canvas, image, x=$x, y=$y")
        val context = canvas.getContext("2d") as CanvasRenderingContext2D
        context.clearCanvas()
        context.drawImage(image, x * RESOLUTION_SCALE, y * RESOLUTION_SCALE, image.getWidth() * RESOLUTION_SCALE, image.getHeight() * RESOLUTION_SCALE)
    }

    fun transformIntoSigningImage(field: HTMLElement, imageTriple: Triple<String, String, String>) {
        val (_, imageSource, optionId) = imageTriple
        log.info("transformIntoSigningImage with field.classList=${field.classList}, imageSource[:60]=${imageSource.substring(0, 60)}")

        field.setAttribute("data-signing-option-id", optionId)

        field.querySelector(".content-container")!!.remove()

        val image = document.createElement("img") as HTMLImageElement
        image.src = imageSource
        image.draggable = false
        image.height = 1000  // allow image to expand up to 1000px tall
        image.addClass("signing-image", "content-container", "align-baseline")

        field.appendChild(image)
    }

    fun transformIntoSigningText(field: HTMLElement, textTriple: Triple<String, String, String>) {
        val (fontFamily, value, optionId) = textTriple
        log.info("transformIntoSigningText with field.classList=${field.classList}, fontFamily=$fontFamily, value=$value")

        field.querySelector(".content-container")!!.remove()
        field.setAttribute("data-font-family", fontFamily)
        field.setAttribute("data-signing-option-id", optionId)

        val textDiv = document.createDiv("content", "content-container")
        textDiv.textContent = value
        field.appendChild(textDiv)
    }
}

suspend fun uploadNewSignatureOptions(fields: List<FieldSpec>, signingOptionToken: SigningOptionToken) {
    val newSigningOptionIdMap = mutableMapOf<String, String>()

    for (field in fields) {
        if (field.signingOptionId.startsWith(NEW_SIGNING_OPTION_ID)) {
            field.signingOptionId = newSigningOptionIdMap.getOrPut(field.signingOptionId) {
                val modalId = "RabbitSign-display-${field.type.toString().lowercase()}OptionsModal-div"
                val signingOptionElem =
                    document.querySelector("#$modalId .signing-option[data-signing-option-id=\"${field.signingOptionId}\"]") as HTMLElement
                val value = signingOptionElem.getAttribute("data-value")!!
                if (field.signingOptionId.endsWith(S3_SIGNING_OPTION)) {
                    val uploadUrl = getApi().callGetSigningOptionUploadUrl(signingOptionToken)
                    val imageBlob = dataUriToBlob(value)
                    callApiRaw("PUT", uploadUrl.uploadUrl, imageBlob, headers = CONTENT_TYPE_BINARY_OCTET_STREAM, apiId = 920)
                    getApi().callCreateS3SigningOption(
                        S3SigningOption(signingOptionToken.signingOptionToken, field.type, uploadUrl.uploadUrl)
                    ).signingOptionId
                } else {
                    getApi().callCreateTextSigningOption(
                        TextSigningOption(signingOptionToken.signingOptionToken, field.type, value, signingOptionElem.fontFamily)
                    ).signingOptionId
                }
            }
        }
    }
}
