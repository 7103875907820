@file:Suppress("UNCHECKED_CAST_TO_EXTERNAL_INTERFACE")

package com.rabbitsign.web

import org.w3c.dom.Element
import org.w3c.dom.RenderingContext
import org.w3c.dom.events.EventTarget
import kotlin.js.Promise

external class ResizeObserverEntry
external class ResizeObserver(callback: (List<ResizeObserverEntry>, ResizeObserver) -> Unit) {
//    fun disconnect()
    fun observe(target: Element)
//    fun unobserve(target: Element)
}


external fun jQuery(x: EventTarget): dynamic


external class RenderTask {
    var promise: Promise<Unit>
    fun cancel()
}

external class PDFPageProxy {
    fun getViewport(params: PageViewportParameters): PageViewport
    fun render(params: RenderParameters): RenderTask
}

external class PageViewport {
    var height: Double
    var width: Double
}

// https://kotlinlang.org/docs/js-interop.html#external-interfaces
external interface PageViewportParameters {
    var scale: Double
}

external interface RenderParameters {
    var canvasContext: RenderingContext
    var viewport: PageViewport
}


fun PageViewportParameters(scale: Double): PageViewportParameters =
    (js("{}") as PageViewportParameters).apply { this.scale = scale }
fun RenderParameters(canvasContext: RenderingContext, viewport: PageViewport): RenderParameters =
    (js("{}") as RenderParameters).apply { this.canvasContext = canvasContext; this.viewport = viewport }
