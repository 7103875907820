package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.common.DocumentType
import com.rabbitsign.web.filemanager.FileData
import com.rabbitsign.web.filemanager.FileLink
import com.rabbitsign.web.filemanager.FileObject
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.hasClass
import org.w3c.dom.*


fun fieldPosition(field: HTMLDivElement, docNumber: Int): FieldPosition {
    val x = field.fieldPosX.round(2)
    val y = field.fieldPosY.round(2)

    val width = field.fieldWidth.round(2)
    val height = field.fieldHeight.round(2)

    val fontSize = field.fieldFontSize.round(2)
    return FieldPosition(docNumber, field.page, x, y, width, height, fontSize)
}


class FieldSaver(
    private val files: List<FileData<Any>>,
    private val assignees: Map<String, SignerInfo>,
    private val title: String,
    private val message: String,
    private val docType: DocumentType,
    private val ccList: List<Email>,
    private val signingOptionToken: SigningOptionToken,
    private val sourceTemplateId: String
) {
    companion object val log = Logger(this::class.simpleName)
    
    // fields can be saved even when a different doc is being viewed
    private fun getDocFields(docNumber: Int): Map<Email, SignerNameAndFields> {
        log.info("getDocFields with docNumber=$docNumber")

        val assigneeMap = assignees.values.associateBy(
            { it.email },
            { SignerNameAndFields(it.name, mutableListOf(), if (it.email == ME_NOW) 1 else it.signingOrder) }
        )

        val fieldElems = document.querySelectorAll(".doc-wrapper[data-doc-number='$docNumber'] .field:not(.disabled)").asList()
        for ((fieldIdx, fieldNode) in fieldElems.withIndex()) {
            val field = fieldNode as HTMLDivElement

            val assigneeTmpId = field.getAttribute("data-assignee-id")!!
            if (assigneeTmpId !in assignees) continue  // if a role is excluded, don't include their fields
            val assigneeId = assignees[assigneeTmpId]!!.email  // in the createTemplate case, assigneeId is actually the role name

            val value = if (field.hasClass("filled")) {
                when (field.fieldType) {
                    // signature and initials fields will have an empty value; value is determined by signingOptionId instead
                    FieldType.SIGNATURE, FieldType.INITIALS -> ""
                    FieldType.LOCAL_DATE, FieldType.TEXTBOX -> (field.querySelector("input") as HTMLInputElement).value
                    FieldType.CHECKBOX -> field.getAttribute("data-checked")!!
                }
            } else ""

            val fieldName = field.getAttribute("data-field-name") ?: "__default_field_name$docNumber-$fieldIdx"

            val signingOptionId = if (field.hasClass("filled") && field.fieldType in setOf(FieldType.SIGNATURE, FieldType.INITIALS)) {
                field.getAttribute("data-signing-option-id")!!
            } else ""

            assigneeMap[assigneeId]!!.fields.add(
                FieldSpec(fieldIdx, field.fieldType, value, fieldPosition(field, docNumber), name = fieldName, signingOptionId = signingOptionId)
            )
        }
        return assigneeMap
    }

    private fun openUnassignedPopup(unassigned: List<String>) {
        log.info("openUnassignedPopup with unassigned=$unassigned")

        val unassignedList = document.getElementById("unassignedList") as HTMLElement
        unassignedList.textContent = when (unassigned.size) {
            1 -> "${unassigned[0]} doesn't "
            2 -> "${unassigned[0]} and ${unassigned[1]} don't "
            else -> "${unassigned.dropLast(1).joinToString(", ")}, and ${unassigned.last()} don't "
        }
        val continueAnywayButton = document.getElementById("RabbitSign-display-prepDoc-unassignedWarningModal-continueAnyway-button") as HTMLElement
        continueAnywayButton.onclick = { saveFields(warned = true) }
        js("$('#RabbitSign-display-prepDoc-unassignedWarningModal-div').modal('show')")
    }

    fun saveFields(warned: Boolean) {
        log.info("saveFields with warned=$warned")

        // merge all maps into 1 map
        val assigneeMap = files.indices.map { getDocFields(it) }.reduce { m1, m2 ->
            (m1.asSequence() + m2.asSequence())
                .groupBy({ it.key }, { it.value })
                .mapValues { (_, nameAndFields) ->
                    nameAndFields.reduce { f1, f2 ->
                        // same email (key) should always have same name
                        SignerNameAndFields(f1.name, (f1.fields + f2.fields).toMutableList(), f1.signingOrder)
                    }
                }
        }

        val assigneeSelect = document.getElementById("RabbitSign-display-prepDoc-assignee-select") as HTMLSelectElement
        val unassigned = assigneeSelect.options.asList().map { Pair((it as HTMLOptionElement).value, it.textContent) }
            .filter { it.first !in setOf(ME_NOW, SENDER_ME) && (it.first !in assigneeMap || assigneeMap[it.first]!!.fields.isEmpty()) }
            .map { it.second!! }
        if (!warned && unassigned.isNotEmpty()) {
            openUnassignedPopup(unassigned)
            return
        }
        // warn if only ME_NOW is a signer and has no fields
        if (!warned && assigneeMap[ME_NOW]?.fields?.isEmpty() == true && assigneeMap.filter { it.key != ME_NOW }.isEmpty()) {
            openUnassignedPopup(listOf(ME_NOW))
            return
        }

        val allFieldNames = assigneeMap.values.flatMap { it.fields.map { field -> field.name } }
        val duplicates = allFieldNames.groupingBy { it }.eachCount().filter { it.value > 1 }
        if (duplicates.isNotEmpty()) {
            // This shouldn't happen since we check field name uniqueness in HTMLElement.fieldName's setter!
            displayErrorMessage(931, "Field names must be unique. But there are multiple fields named ${duplicates.keys.first()}.")
            return
        }

        if (assigneeMap[ME_NOW]?.fields?.isNotEmpty() == true) {
            openLegalTextModal {
                addFullPageSpinnerAndLeaveDialog()
                uploadDocument(assigneeMap)
            }
        } else {
            addFullPageSpinnerAndLeaveDialog()
            uploadDocument(assigneeMap)
        }
    }

    private fun uploadDocument(assigneeMap: SignerMap) {
        log.info("uploadDocument with assigneeMap=$assigneeMap")

        val dateFormatSelect = document.getElementById("RabbitSign-display-prepDoc-dateFormat-select") as HTMLSelectElement
        val dateFormatIndex = dateFormatSelect.selectedIndex
        val dateFormat = DateFormat.values()[dateFormatIndex]

        when (docType) {
            DocumentType.FOLDER -> {
                val senderFields = assigneeMap[ME_NOW]?.fields ?: emptyList()
                val futureSignerMap = assigneeMap.filter { it.key != ME_NOW }

                MainScope().launch {
                    val docInfo = List(files.size) {
                        when (val fileData = files[it]) {
                            is FileObject -> DocInfo(getApi().callUploadFile(fileData.file, docType).uploadUrl, fileData.name)
                            is FileLink -> DocInfo(fileData.file, fileData.name)
                        }
                    }
                    val folderPayload = Folder(title, message, docInfo, futureSignerMap, dateFormat, ccList, senderFields, sourceTemplateId)
                    val localCreationDate = getFormattedDateString(DateFormat.YYYY_MM_DD_DASH)  // localCreationDate is always in ISO format
                    val payload = FolderInstantiation(folderPayload, localCreationDate)

                    uploadNewSignatureOptions(senderFields, signingOptionToken)
                    val folderId = getApi().callCreateFolder(payload)
                    log.info("folderId=$folderId")

                    delay(100)  // give DynamoDb some time to update
                    openPage(
                        if (futureSignerMap.isEmpty()) "/folder/signed/${folderId.folderId}"  // send directly to signed if there are no other signers
                        else "/folder/sent/${folderId.folderId}"
                    )

                    // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                    Unit
                }
            }
            DocumentType.TEMPLATE -> {
                val senderFields = assigneeMap[SENDER_ME]?.fields ?: emptyList()
                // for now, Me (Now) isn't supported for templates
                val roleMap = assigneeMap.filter { it.key !in setOf(ME_NOW, SENDER_ME) }.mapValues { it.value.fields }
                val roleInfoMap = assigneeMap.filter { it.key !in setOf(ME_NOW, SENDER_ME) }.mapValues { RoleInfo(it.value.signingOrder) }

                MainScope().launch {
                    val docInfo = List(files.size) {
                        when (val fileData = files[it]) {
                            is FileObject -> DocInfo(getApi().callUploadFile(fileData.file, docType).uploadUrl, fileData.name)
                            is FileLink -> DocInfo(fileData.file, fileData.name)
                        }
                    }
                    val payload = Template(title, message, docInfo, roleMap, roleInfoMap, senderFields, dateFormat, ccList)
                    val templateId = getApi().callCreateTemplate(payload)
                    log.info("templateId=$templateId")

                    delay(100)  // give DynamoDb some time to update
                    openPage("/template/created/${templateId.templateId}")

                    // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                    Unit
                }
            }
            DocumentType.BATCH -> throw IllegalStateException("docType should never be BATCH")
            DocumentType.USER_LOGO -> throw IllegalStateException("docType should never be USER_LOGO")
        }
    }
}
