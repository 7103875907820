package com.rabbitsign.web

import com.rabbitsign.common.DisableHipaa
import com.rabbitsign.web.util.addFullPageSpinnerAndLeaveDialog
import com.rabbitsign.web.util.removeLeaveConfirmDialog
import com.rabbitsign.web.util.toLocalTime
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.dom.addClass
import kotlinx.dom.appendText
import kotlinx.dom.clear
import org.w3c.dom.*


@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("EnableHipaaPage")
object EnableHipaaPage : ViewingPage() {
    private val isHipaa = mainScriptElement.getAttribute("data-is-hipaa").toBoolean()

    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        val wrapperElem = document.querySelector(".doc-wrapper[data-file]") as HTMLDivElement
        wrapperElem.addClass("current-wrapper")
        MainScope().launch {
            val renderer = withContext(Dispatchers.Default) {
                createPDFRenderer(wrapperElem.getAttribute("data-file")!!, wrapperElem)
            }

            var canvasResolution = window.devicePixelRatio
            window.setInterval({
                val dpr = window.devicePixelRatio
                if (canvasResolution < dpr || canvasResolution > dpr * 2) {
                    canvasResolution = dpr
                    renderer.renderPages(updateRender = true)
                } else {
                    renderer.renderPages(updateRender = false)
                }
            }, 200)

            Unit
        }

        if (isHipaa) {
            val dateSpan = document.querySelector("span[data-date]") as HTMLSpanElement
            val (dateString, _) = toLocalTime(dateSpan.getAttribute("data-date")!!)

            dateSpan.clear()
            dateSpan.appendText(dateString)

            val disableHipaaButton = document.getElementById("RabbitSign-hipaa-disable-button") as HTMLButtonElement?
            disableHipaaButton?.onclick = {
                MainScope().launch {
                    getApi().callEnableHipaa(DisableHipaa(disableHipaa = true))
                    delay(500)
                    window.location.reload()
                }
            }
        } else {
            val acceptCheckboxes = listOf(
                document.getElementById("RabbitSign-hipaa-acceptBaa-input") as HTMLInputElement,
                document.getElementById("RabbitSign-hipaa-healthcareProviderOrBusinessAssociate-input") as HTMLInputElement
            )
            val enableButton = document.getElementById("RabbitSign-hipaa-enableHipaa-button") as HTMLButtonElement
            enableButton.disabled = !acceptCheckboxes.all { it.checked }
            acceptCheckboxes.forEach { checkbox ->
                checkbox.onchange = {
                    enableButton.disabled = !acceptCheckboxes.all { box -> box.checked }
                    Unit
                }
            }

            val confirmButton = document.getElementById("RabbitSign-hipaa-confirm-button") as HTMLButtonElement
            confirmButton.onclick = {
                addFullPageSpinnerAndLeaveDialog()
                MainScope().launch {
                    getApi().callEnableHipaa(null)
                    removeLeaveConfirmDialog()
                    window.location.reload()  // reload to show new status
                    Unit
                }
            }
        }
    }
}
