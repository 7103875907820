package com.rabbitsign.web.filemanager

import com.rabbitsign.web.Logger
import com.rabbitsign.web.util.createDeleteButton
import com.rabbitsign.web.util.createTableCell
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.clear
import kotlinx.dom.removeClass
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTableRowElement
import org.w3c.files.File
import org.w3c.files.FileList


sealed class FileData<out T> (val file: T, val name: String)
class FileObject(file: File) : FileData<File>(file, file.name)
class FileLink(fileUrl: String, fileName: String) : FileData<String>(fileUrl, fileName)

abstract class FileManager(idPrefix: String) {
    companion object val log = Logger(this::class.simpleName)

    protected var fileList: MutableList<FileData<Any>> = mutableListOf()
    val files get() = fileList.toList()

    init {
        log.info("init with idPrefix=$idPrefix")

        val dropArea = document.getElementById("$idPrefix-dropArea-div") as HTMLElement
        listOf("dragenter", "dragover", "dragleave", "drop").forEach { event ->
            dropArea.addEventListener(event, {
                it.preventDefault()
                it.stopPropagation()
            }, false)
        }

        dropArea.addEventListener("dragover", {
            (it.currentTarget as HTMLElement).addClass("active")
        }, false)
        dropArea.addEventListener("dragleave", {
            (it.currentTarget as HTMLElement).removeClass("active")
        }, false)
        dropArea.addEventListener("drop", { handleDrop(it as DragEvent) }, false)

        val fileInput = document.getElementById("$idPrefix-fileInput-input") as HTMLInputElement
        fileInput.onchange = {
            onFileSelected(fileInput.files)
            fileInput.value = ""
            // set the file input back to empty so that files with the same name can be uploaded
            // onFileSelected will filter files with the same name

            Unit
        }
    }

    private fun handleDrop(e: DragEvent) {
        log.info("handleDrop")
        (e.currentTarget as HTMLElement).removeClass("active")
        onFileSelected(e.dataTransfer!!.files)
    }

    abstract fun onFileSelected(files: FileList?)

    protected fun updateFileListDisplay(func: () -> Unit) {
        log.info("updateFileListDisplay")

        val noFileChosenText = document.getElementById("RabbitSign-creation-noFileChosenText-p") as HTMLElement
        val fileListTable = document.getElementById("RabbitSign-creation-fileList-table") as HTMLElement
        val fileListBody = document.getElementById("RabbitSign-creation-fileList-tbody") as HTMLElement
        fileListBody.clear()

        if (fileList.isEmpty()) {
            noFileChosenText.removeClass("d-none")
            fileListTable.addClass("d-none")
        } else {
            noFileChosenText.addClass("d-none")
            fileListTable.removeClass("d-none")
            for (file in fileList) {
                fileListBody.appendChild(createFileRow(file.name, func))
            }
        }

        func()
    }

    private fun createFileRow(filename: String, func: () -> Unit): HTMLTableRowElement {
        log.info("createFileRow with filename=$filename")
        val row = document.createElement("tr") as HTMLTableRowElement

        val nameCell = document.createTableCell("file-name-cell", text = filename)
        row.appendChild(nameCell)

        val deleteCell = document.createTableCell("file-delete-cell")
        val deleteButton = createDeleteButton()
        deleteButton.onclick = {
            fileList = fileList.filter { it.name != filename }.toMutableList()
            updateFileListDisplay(func)
        }
        deleteCell.appendChild(deleteButton)
        row.appendChild(deleteCell)

        return row
    }
}
