package com.rabbitsign.web

import com.rabbitsign.web.util.displayInfoMessage
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

const val RENDER_RANGE_SIZE = 3

abstract class PDFDisplay(private val renderers: List<PDFRenderer>, private val fieldsManager: StaticFieldsManager) {
    companion object val log = Logger(this::class.simpleName)

    private fun handlePreviousButton(oldFileIdx: Int) = true
    abstract fun handleNextButton(oldFileIdx: Int): Boolean
    open fun handleIndexChange(fileIdx: Int) {
        log.info("PDFDisplay.handleIndexChange with fileIdx=$fileIdx")
        fieldsManager.updateFieldRemainingCount()
        fieldsManager.scalePositions(document.querySelector(".doc-wrapper[data-doc-number='$fileIdx']") as HTMLDivElement, renderers[fileIdx].pdfScale)
    }
    abstract fun handleSubmitButton()
    abstract fun handleCancelButton()

    private val previousDocButton = document.getElementById("RabbitSign-display-previousDoc-button") as HTMLElement
    private val nextDocButton = document.getElementById("RabbitSign-display-nextDoc-button") as HTMLElement
    private val submitButton = document.getElementById("RabbitSign-display-submit-button") as HTMLElement?
    private val cancelButton = document.getElementById("RabbitSign-display-cancel-button") as HTMLElement?

    private val fileCount = renderers.size

    private var currentFileIdx = 0

    fun initialize() {  // can't use init since that will run before the child classes' attributes are initialized
        log.info("PDFDisplay.initialize with fileCount=$fileCount")

        changeDisplayedIndex()

        previousDocButton.onclick = {
            log.info("PDFDisplay.previousDocButton.onclick with currentFileIdx=$currentFileIdx")
            if (canLeaveCurrentPage() && handlePreviousButton(currentFileIdx)) {
                renderers[currentFileIdx].cleanup()
                --currentFileIdx
                changeDisplayedIndex()
            }
        }

        nextDocButton.onclick = {
            log.info("PDFDisplay.nextDocButton.onclick with currentFileIdx=$currentFileIdx")
            if (canLeaveCurrentPage() && handleNextButton(currentFileIdx)) {
                renderers[currentFileIdx].cleanup()
                ++currentFileIdx
                changeDisplayedIndex()
            }
        }

        val canvasResolution = Array(fileCount) { window.devicePixelRatio }
        val intervalId = window.setInterval({
            val dpr = window.devicePixelRatio
            if (canvasResolution[currentFileIdx] < dpr || canvasResolution[currentFileIdx] > dpr * 2) {
                canvasResolution[currentFileIdx] = dpr
                renderers[currentFileIdx].renderPages(updateRender = true)
            } else {
                renderers[currentFileIdx].renderPages(updateRender = false)
            }
        }, 200)

        cancelButton?.onclick = {
            window.clearInterval(intervalId)
            handleCancelButton()
        }
        submitButton?.onclick = {
            if (canLeaveCurrentPage()) handleSubmitButton()
        }
    }

    private fun changeDisplayedIndex() {
        log.info("PDFDisplay.changeDisplayedIndex with currentFileIdx=$currentFileIdx")

        document.querySelector(".current-wrapper")?.removeClass("current-wrapper")

        val currentWrapper = document.querySelector(".doc-wrapper[data-doc-number='$currentFileIdx']") as HTMLDivElement
        currentWrapper.addClass("current-wrapper")

        if (currentFileIdx == 0) previousDocButton.addClass("hidden")
        else previousDocButton.removeClass("hidden")

        if (currentFileIdx + 1 == fileCount) {
            nextDocButton.addClass("hidden")
            submitButton?.removeClass("hidden")
        } else {
            nextDocButton.removeClass("hidden")
            submitButton?.addClass("hidden")
        }

        handleIndexChange(currentFileIdx)
    }

    private fun canLeaveCurrentPage(): Boolean {
        if (document.querySelectorAll(".dragging").length > 0) {
            displayInfoMessage("You still have a field that you need to position. Please place it or delete it by pressing either the backspace or escape key.")
            return false
        }

        return true
    }
}
