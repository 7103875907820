package com.rabbitsign.web

import com.rabbitsign.common.Email
import com.rabbitsign.web.util.createDiv
import com.rabbitsign.web.util.deleteButtonHandler
import com.rabbitsign.web.util.newDeleteButton
import com.rabbitsign.web.util.newFormControl
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*

class CCEmailsCollector(private val emailInputSize: Int) {
    companion object val log = Logger(this::class.simpleName)
    
    private val ccEmailsForm = document.getElementById("RabbitSign-creation-ccEmails-form") as HTMLFormElement
    private val ccCheckbox = document.getElementById("RabbitSign-creation-ccCheckbox-input") as HTMLInputElement

    init {
        log.info("init with emailInputSize=$emailInputSize")

        (document.getElementById("RabbitSign-creation-addCcEmail-button") as HTMLButtonElement).onclick = { addCCEmail() }
        ccEmailsForm.querySelectorAll("button.remove-button").asList().forEach {
            val button = (it as HTMLButtonElement)
            button.onclick = { deleteButtonHandler(button) }
        }

        fun changeCCFormVisibility(show: Boolean) {
            log.info("init.changeCCVisibility with show=$show")
            val inputs = ccEmailsForm.querySelectorAll("input.form-control").asList()
            val ccEmailsFormContainer = document.getElementById("RabbitSign-creation-ccEmailsFormContainer-div") as HTMLElement
            if (show) {
                ccEmailsFormContainer.removeClass("hidden")
                inputs.forEach { (it as HTMLInputElement).disabled = false }
                if (inputs.isEmpty()) {
                    addCCEmail()
                }
            } else {
                ccEmailsFormContainer.addClass("hidden")
                inputs.forEach { (it as HTMLInputElement).disabled = true }
                // disable inputs so they don't get validation checks
            }
        }
        ccCheckbox.onchange = { changeCCFormVisibility(ccCheckbox.checked) }
        changeCCFormVisibility(ccCheckbox.checked)
    }

    private fun addCCEmail(): HTMLDivElement {
        log.info("addCCEmail")
        val newCCEmail = document.createDiv("cc-email-container", "form-row")
        newCCEmail.appendChild(newFormControl("email", "", emailInputSize))
        newCCEmail.appendChild(newDeleteButton())

        ccEmailsForm.appendChild(newCCEmail)
        return newCCEmail
    }

    fun collectEmails(): List<Email> =
        if (ccCheckbox.checked) {
            ccEmailsForm.querySelectorAll("input.email-input").asList().map { (it as HTMLInputElement).value }
        } else emptyList()
}
