package com.rabbitsign.common

import kotlinx.serialization.Serializable

@Serializable
data class UserSettings(
    val dateFormat: DateFormat,
    val notifyUponDocSigning: Boolean = false,
    val notifyUponDocViewing: Boolean = false,
    val shareLink: String,
    val shareLinkReferer: String,
    val showAds: Boolean,
    val isHipaa: Boolean = false,
    val signerNotifLang: IsoLangCode = IsoLangCode.en,
    val logoUrl: String = "",
    val isDeveloper: Boolean
)


@Serializable
data class DisableHipaa(
    val disableHipaa: Boolean
)

@Serializable
data class ApiKeyName(
    val apiKeyName: String
)

@Serializable
data class Webhook(
    val url: String
)

// 1x1px transparent image
const val EMPTY_IMAGE = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII="
