package com.rabbitsign.common.util

fun String.extractInitials(): String {
    return this.split(" ")
        .filter { it.isNotEmpty() }
        .map { it.first() }
        .joinToString("")
    // .joinToString(".", postfix = ".")
}

//fun String.capitalizeFirst() = this.replaceFirstChar {
//    if (it.isLowerCase()) it.titlecase(Locale.getDefault()) else it.toString()
//}

fun String.capitalizeWords() =
    split(" ").joinToString(" ") { it.replaceFirstChar(Char::titlecase) }.trimEnd()
