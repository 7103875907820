package com.rabbitsign.web

import com.rabbitsign.common.DocumentType
import com.rabbitsign.common.ME_NOW
import com.rabbitsign.common.SignerInfo
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import kotlin.math.abs
import kotlin.math.round
import kotlin.math.roundToInt


const val ADDED_SIGNER = "addedSigner"

internal fun addExcludeButtonHandlers() {
    console.log("invoked addExcludeButtonHandlers")
    val excludeButtons = document.querySelectorAll(".exclude-button").asList()
    for (excludeButtonNode in excludeButtons) {
        val excludeButton = excludeButtonNode as HTMLElement
        excludeButton.onclick = {
            val container = excludeButton.parentElement!!.parentElement as HTMLDivElement

            container.removeClass("included-container")

            val nameContainer = container.querySelector(".name-container > label") as HTMLElement
            nameContainer.removeClass("was-validated")

            val emailContainer = container.querySelector(".email-container > label") as HTMLElement
            emailContainer.removeClass("was-validated")

            container.querySelectorAll(".custom-feedback").asList().forEach {
                (it as HTMLElement).addClass("hidden")
            }

            val nameInput = container.querySelector(".name-input") as HTMLInputElement
            nameInput.value = ""
            nameInput.disabled = true

            val emailInput = container.querySelector(".email-input") as HTMLInputElement
            emailInput.value = ""
            emailInput.disabled = true

            val signingOrderInput = container.querySelector(".signing-order-input") as HTMLInputElement?
            signingOrderInput?.value = ""
            signingOrderInput?.disabled = true

            container.querySelector(".include-button")!!.removeClass("d-none")
            excludeButton.addClass("d-none")
            0
        }
    }
}

internal fun addIncludeButtonHandlers() {
    console.log("addIncludeButtonHandlers")
    val includeButtons = document.querySelectorAll(".include-button").asList()
    for (includeButtonNode in includeButtons) {
        val includeButton = includeButtonNode as HTMLElement
        includeButton.onclick = {
            val container = includeButton.parentElement!!.parentElement as HTMLDivElement

            container.addClass("included-container")

            (container.querySelector(".name-input") as HTMLInputElement).disabled = false
            (container.querySelector(".email-input") as HTMLInputElement).disabled = false
            (container.querySelector(".signing-order-input") as HTMLInputElement?)?.disabled = false

            container.querySelector(".exclude-button")!!.removeClass("d-none")
            includeButton.addClass("d-none")
            0
        }
    }
}

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("CreateFolderPage")
object CreateFolderPage : CreationPage(DocumentType.FOLDER) {
    private val fromTemplate = mainScriptElement.getAttribute("data-from-template")?.toBoolean() ?: false
    private val iAmOnlySignerInput = document.getElementById("RabbitSign-creation-iAmOnlySigner-input") as HTMLInputElement

    override fun runPage() {
        log.info("run with fromTemplate=$fromTemplate")

        initializeCreationPage()

        iAmOnlySignerInput.onclick = {
            if (iAmOnlySignerInput.checked) hide("#assigneeContainer") else show("#assigneeContainer")
        }
        if (iAmOnlySignerInput.checked) hide("#assigneeContainer") else show("#assigneeContainer")

        if (fromTemplate) {
            initializeExistingSignerInputs()
            val sendImmediatelyButton = document.getElementById("RabbitSign-creation-submit-button") as HTMLButtonElement?
            sendImmediatelyButton?.onclick = {
                val ccList = ccEmailsCollector.collectEmails()
                val signers = validateInputAndRetrieveAssignees()
                if (signers != null) {
                    val title = getInputValue("RabbitSign-creation-title-input")
                    val message = (document.getElementById("RabbitSign-creation-message-textarea") as HTMLTextAreaElement).value
                    val fieldSaver = FieldSaver(fileManager.files, signers, title, message, DocumentType.FOLDER, ccList, signingOptionToken, sourceTemplateId)
                    fieldSaver.saveFields(warned = true)
                }
                Unit
            }
        }
        else {
            addSigner("", "")
            // self signer button will only appear in regular folder creation
            val addSelfSignerButton = document.getElementById("RabbitSign-creation-addSelfSigner-button") as HTMLButtonElement
            addSelfSignerButton.onclick = { addSelfSigner() }
        }

        addAssigneeButton.onclick = { addSigner() }


        addNextFieldButtonHandler()

        val showMeOnlyInput = document.getElementById("RabbitSign-display-showMeOnly-input") as HTMLInputElement
        val showAllSignersInput = document.getElementById("RabbitSign-display-showAllSigners-input") as HTMLInputElement
        fun showMeOnly() {
            hide("div.field")
            show("div.field[data-assignee-id='$ME_NOW']")
        }
        fun showAllSigners() = show("div.field")
        showMeOnlyInput.onchange = {
            if (showMeOnlyInput.checked) showMeOnly()
            else showAllSigners()
        }
        showAllSignersInput.onchange = {
            if (showAllSignersInput.checked) showAllSigners()
            else showMeOnly()
        }
        if (showMeOnlyInput.checked) showMeOnly()
        else showAllSigners()
    }

    override fun validateInputAndRetrieveAssignees(): Map<String, SignerInfo>? {
        return if (iAmOnlySignerInput.checked) {  // check only files, title, and message
            val titleElem = document.getElementById("RabbitSign-creation-title-input") as HTMLInputElement
            val messageElem = document.getElementById("RabbitSign-creation-message-textarea") as HTMLTextAreaElement
            if (fileManager.files.isEmpty() || !titleElem.checkValidity() || !messageElem.checkValidity()) {
                null.also {
                    if (!titleElem.checkValidity()) titleElem.parentElement!!.addClass("was-validated")
                    if (!messageElem.checkValidity()) messageElem.parentElement!!.addClass("was-validated")
                }
            }
            else emptyMap()
        } else super.validateInputAndRetrieveAssignees()
    }

    private fun initializeExistingSignerInputs() {
        log.info("initializeExistingSignerInputs")
        document.querySelectorAll(".signer-container input").asList().forEach {
            val inputElem = it as HTMLInputElement
            addValidityListeners(inputElem)
        }
        addExcludeButtonHandlers()
        addIncludeButtonHandlers()
    }

    private fun addSigner(name: String = "", email: String = ""): HTMLDivElement {
        log.info("addSigner with name=$name, email=$email")
        val signerFormContainer = document.createDiv("signer-container", "form-row")
        signerFormContainer.setAttribute("data-assignee-id", "addedSigner$idCounter").also { idCounter++ }
        signerFormContainer.appendChild(newFormControl("name", name, 4))
        signerFormContainer.appendChild(newFormControl("email", email, 4))
        signerFormContainer.appendChild(newSigningOrderInput())
        signerFormContainer.appendChild(newDeleteButton())

        assigneesForm.appendChild(signerFormContainer)
        return signerFormContainer
    }

    private fun addSelfSigner() {
        log.info("addSelfSigner")
        if (!userLoggedIn()) {
            displayWarningMessage(901, "You aren't signed in.")
            return
        }

        val name = userInfo.given_name + ' ' + userInfo.family_name

        val formGroups = assigneesForm.querySelectorAll(".form-row")
        for (formGroupNode in formGroups.asList()) {
            val formGroup = formGroupNode as HTMLElement
            val nameInput = formGroup.querySelector("input.name-input") as HTMLInputElement
            val emailInput = formGroup.querySelector("input.email-input") as HTMLInputElement
            if (nameInput.value.isEmpty() && emailInput.value.isEmpty()) {
                nameInput.value = name
                if (nameInput.checkValidity()) {
                    nameInput.parentElement!!.removeClass("was-validated")
                }

                emailInput.value = userInfo.email
                if (emailInput.checkValidity()) {
                    emailInput.parentElement!!.removeClass("was-validated")
                }
                return
            }
        }

        // if there's no empty form group, add one and populate it
        addSigner(name, userInfo.email)
    }

    override fun getSignerInfo(): Map<String, SignerInfo>? {
        log.info("getSignerInfo")
        val signers = assigneesForm.querySelectorAll("div.signer-container").asList()
            .map { it as HTMLDivElement }
            .filter { it.querySelectorAll("input:not(:disabled").length >= 2 }  // filter out excluded roles for template instantiation
            .associate { container ->
                val nameElem = container.querySelector("input.name-input") as HTMLInputElement
                val emailElem = container.querySelector("input.email-input") as HTMLInputElement
                val email = emailElem.value.lowercase()  // emails will all be lowercase

                val signingOrderInput = container.querySelector(".signing-order-input") as HTMLInputElement
                val signingOrder = if (signingOrderInput.disabled) 1.0 else signingOrderInput.valueAsNumber

                if (abs(signingOrder - round(signingOrder)) > 1e-8 || signingOrder.isInfinite() || signingOrder.isNaN()) {
                    displayWarningMessage(907, "Please use only whole numbers in the Signing Order.")
                    return null
                }

                // data-assignee-id should be unique for each signer
                val roleName = container.getAttribute("data-assignee-id")!!
                roleName to SignerInfo(nameElem.value, email, mutableListOf(), signingOrder.roundToInt())
            }
        // https://kotlinlang.org/api/latest/jvm/stdlib/kotlin.sequences/associate.html
        // "The returned map preserves the entry iteration order of the original sequence."
        // this is important because the assigneeSelect element will be populated by iterating through this map

        if (signers.values.size != signers.values.distinctBy { it.email }.size) {
            displayInfoMessage("Please remove duplicate emails. Note that emails are case-insensitive.")
            return null
        }
        if (signers.values.find { it.email == ME_NOW } != null) {
            displayInfoMessage("$ME_NOW is a reserved email. Please use a different one.")
            return null
        }

        val signingOrderValidityMessage = isSigningOrderValid(signers.values.map { it.signingOrder })
        if (signingOrderValidityMessage != null) {
            displayInfoMessage(signingOrderValidityMessage)
            return null
        }

        return signers
    }
}
