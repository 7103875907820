package com.rabbitsign.common

enum class DateFormat(val pattern: String, val example: String) {
    // N.B. enum values such as MM_DD_YYYY_SLASH are persisted into table UserSettings. DON"T CHANGE THEM!
    MM_DD_YYYY_SLASH("MM/dd/yyyy", "02/15/2022"),
    DD_MM_YYYY_SLASH("dd/MM/yyyy", "15/02/2022"),
    DD_MM_YYYY_DASH("dd-MM-yyyy", "15-02-2022"),
    DD_MM_YYYY_DOT("dd.MM.yyyy", "15.02.2022"),
    YYYY_MM_DD_SLASH("yyyy/MM/dd", "2022/02/15"),
    YYYY_MM_DD_DASH("yyyy-MM-dd", "2022-02-15"),
    YYYY_MM_DD_DOT("yyyy.MM.dd", "2022.02.15"),
    MMM_D_YYYY("MMM d, yyyy", "Feb 15, 2022"),
    D_MMM_YYYY("d MMM yyyy", "15 Feb 2022");

    companion object {
        fun valueOfOrDefault(type: String?): DateFormat {
            return values().find { it.name == type } ?: MM_DD_YYYY_SLASH
        }
        fun fromPattern(pattern: String): DateFormat {
            return values().find { it.pattern == pattern }!!
        }
    }
}

val ISO_FORMAT = DateFormat.YYYY_MM_DD_DASH
