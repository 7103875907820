package com.rabbitsign.web

import com.rabbitsign.web.util.clearCanvas
import com.rabbitsign.web.util.distance
import com.rabbitsign.web.util.setHeight
import com.rabbitsign.web.util.setWidth
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.*
import kotlin.math.*


// all canvases have the same dimensions
// these are stored since canvas.width and canvas.height will be scaled in Animation.init
private const val CANVAS_WIDTH = 1000.0
private const val CANVAS_HEIGHT = 700.0

private const val MAX_DPR = 4.0


abstract class Animation(canvasId: String) {
    companion object val log = Logger(this::class.simpleName)

    protected val canvas = document.getElementById(canvasId) as HTMLCanvasElement
    protected val context = canvas.getContext("2d") as CanvasRenderingContext2D
    private var currentScale = window.devicePixelRatio.coerceAtMost(MAX_DPR)

    init {
        log.info("Animation.init with canvasId=$canvasId")
        currentScale = window.devicePixelRatio.coerceAtMost(MAX_DPR)
        canvas.setWidth(CANVAS_WIDTH * currentScale)
        canvas.setHeight(CANVAS_HEIGHT * currentScale)
        context.scale(currentScale, currentScale)
    }

    protected abstract val totalFrames: Int
    abstract fun draw(time: Int)
    protected fun rescaleIfNeeded(extraAction: (Double) -> Unit = {}) {
        if (abs(currentScale - window.devicePixelRatio.coerceAtMost(MAX_DPR)) > 0.00001) {
            currentScale = window.devicePixelRatio.coerceAtMost(MAX_DPR)
            canvas.setWidth(CANVAS_WIDTH * currentScale)
            canvas.setHeight(CANVAS_HEIGHT * currentScale)
            context.scale(currentScale, currentScale)

            extraAction(currentScale)
        }
    }

    protected fun createSquareImage(size: Int) = Image(size, size)
}

object UnlimitedFreeTemplateAnimation : Animation("RabbitSign-index-unlimitedFreeTemplates-canvas") {
    private const val FILL_FRAMES = 80
    private const val FADE_FRAMES = 16
    override val totalFrames = FILL_FRAMES + FADE_FRAMES

    private const val SIGNING_IMAGE_X_SHIFT = 12
    private const val SIGNING_IMAGE_X = 424.0 + SIGNING_IMAGE_X_SHIFT
    private const val SIGNING_IMAGE_Y = 276.0
    private const val SIGNING_IMAGE_SIZE = 160.0
    private const val SIGNING_IMAGE_CENTER_X = SIGNING_IMAGE_X + SIGNING_IMAGE_SIZE / 2
    private const val SIGNING_IMAGE_CENTER_Y = SIGNING_IMAGE_Y + SIGNING_IMAGE_SIZE / 2
    private const val ARROW_MAGNITUDE = 260

    private const val PERSON_IMAGE_SIZE = 125.0

    private const val LINE_WIDTH = 12.0

    private const val BASE_COLOR = "#444240"
    private const val FILL_COLOR = "#1d95cd"

    private val documentImage = createSquareImage((SIGNING_IMAGE_SIZE * 2).roundToInt()).apply { src = "/assets/svg/signing.svg" }
    private val personImage = createSquareImage((PERSON_IMAGE_SIZE * 2).roundToInt()).apply { src = "/assets/svg/person.svg" }

    override fun draw(time: Int) {
        rescaleIfNeeded()

        context.clearCanvas()

        context.drawImage(documentImage, SIGNING_IMAGE_X, SIGNING_IMAGE_Y, SIGNING_IMAGE_SIZE, SIGNING_IMAGE_SIZE)

        context.lineWidth = LINE_WIDTH
        for (angle in 0 until 360 step 60) {
            val currentFrame = ((time / 40) + angle / 12) % totalFrames

            val dx = cos(angle * PI / 180)
            val dy = sin(angle * PI / 180)
            val (scaleX, scaleY) = when (angle) {
                0, 180 -> Pair(1.0, 1.4)
                300 -> Pair(1.2, 1.3)
                else -> Pair(1.4, 1.4)
            }

            val fromX = SIGNING_IMAGE_CENTER_X + scaleX * dx * SIGNING_IMAGE_SIZE / 2 - SIGNING_IMAGE_X_SHIFT
            val fromY = SIGNING_IMAGE_CENTER_Y + scaleY * dy * SIGNING_IMAGE_SIZE / 2
            val toX = SIGNING_IMAGE_CENTER_X + dx * ARROW_MAGNITUDE - SIGNING_IMAGE_X_SHIFT
            val toY = SIGNING_IMAGE_CENTER_Y + dy * ARROW_MAGNITUDE

            val actualAngle = atan2(toY - fromY, toX - fromX)
            val actualMagnitude = distance(fromX, fromY, toX, toY)

            val personX = SIGNING_IMAGE_CENTER_X + dx * (ARROW_MAGNITUDE + PERSON_IMAGE_SIZE / 2) - SIGNING_IMAGE_X_SHIFT - PERSON_IMAGE_SIZE / 2
            val personY = SIGNING_IMAGE_CENTER_Y + dy * (ARROW_MAGNITUDE + PERSON_IMAGE_SIZE / 2) - PERSON_IMAGE_SIZE / 2
            context.drawImage(personImage, personX, personY, PERSON_IMAGE_SIZE, PERSON_IMAGE_SIZE)

            if (currentFrame < FILL_FRAMES) {
                val midSize = currentFrame.toDouble() / FILL_FRAMES
                val midX = fromX + actualMagnitude * midSize * cos(actualAngle)
                val midY = fromY + actualMagnitude * midSize * sin(actualAngle)
                drawFillingArrow(fromX, fromY, midX, midY, toX, toY)
            } else {
                val opacity = (currentFrame - FILL_FRAMES) * 256 / FADE_FRAMES
                val opacityHex = opacity.toString(16).padStart(2, '0')
                drawFillingArrow(fromX, fromY, toX, toY, toX, toY, arrowColor = FILL_COLOR, fillColor = BASE_COLOR + opacityHex)
            }
        }
    }

    private fun drawFillingArrow(
        fromX: Double, fromY: Double, midX: Double, midY: Double, toX: Double, toY: Double,
        arrowSize: Int = 18, arrowColor: String = BASE_COLOR, fillColor: String = FILL_COLOR
    ) {
        context.save()
        val oldLineWidth = context.lineWidth

        val headlen = arrowSize + context.lineWidth // length of head from tip to one of the other corners
        val dx = toX - fromX
        val dy = toY - fromY
        val angle = atan2(dy, dx)

        val headX1 = toX - headlen * cos(angle - PI / 6)
        val headY1 = toY - headlen * sin(angle - PI / 6)
        val headX2 = toX - headlen * cos(angle + PI / 6)
        val headY2 = toY - headlen * sin(angle + PI / 6)

        val headHeight = (distance(headX1, headY1, headX2, headY2) / 2) / tan(PI / 6)  // length of head from tip to opposite side of triangle
        val endX = toX - headHeight * cos(angle)
        val endY = toY - headHeight * sin(angle)

        context.fillStyle = arrowColor
        context.strokeStyle = arrowColor

        context.lineCap = CanvasLineCap.ROUND
        context.beginPath()
        context.moveTo(fromX, fromY)
        context.lineTo(endX, endY)
        context.stroke()

        context.beginPath()
        context.lineWidth = 1.0
        context.moveTo(toX, toY)
        context.lineTo(headX1, headY1)
        context.lineTo(headX2, headY2)
        context.fill()

        context.lineWidth = oldLineWidth
        context.strokeStyle = fillColor
        context.beginPath()
        context.moveTo(fromX, fromY)
        context.lineTo(midX.coerceIn(min(fromX, endX), max(fromX, endX)), midY.coerceIn(min(fromY, endY), max(fromY, endY)))
        context.stroke()

        if (min(endX, toX) <= midX + oldLineWidth / 2 && midX - oldLineWidth / 2 <= max(endX, toX) &&
            min(endY, toY) <= midY + oldLineWidth / 2 && midY - oldLineWidth / 2 <= max(endY, toY)
        ) {
            context.lineWidth = 1.0
            context.fillStyle = fillColor
            context.beginPath()

            val fillTipScale = distance(midX, midY, toX, toY).coerceAtMost(oldLineWidth / 2)

            context.moveTo(midX + fillTipScale * cos(angle), midY + fillTipScale * sin(angle))
            context.lineTo(headX1, headY1)
            context.lineTo(headX2, headY2)
            context.fill()
        }

        context.restore()
    }
}

object ProofOfExistenceAnimation : Animation("RabbitSign-index-proofOfExistence-canvas") {
    private const val MOVE_SPEED = 0.15

    private val HASHES = listOf(
        "0111000100010011010000011011110111111110111010000010100100101100001001000011010000111101110100100011010101100110100110111011100010111010011000100001111011101000011101100111011111111101111001011010101001111111001011001111001000111100010001111100111110010010",
        "1110110110100000001111101010111110000100111010000100001100101110100000001011000011110100110100000000111001100001001011110101011000001110101011011101101001111001001000011100010101011010001111011010011011101011111110100100011010011110110000110001101101101110",
        "0001010110011101010110010010001011100101011010111010101100010011101101110101111000001101001111110110010101100010000101000011100100110110111000111110010010111100000011110011000010100100000110010000110110011010011001011101010111101000000111000010101110010000",
        "0101111011000011110100101011111001010111100101110001101111111011001001011110100000110111100111001001011010101110111100110010011111001100000010101111100011000010100001110000001011100011011000111011110000100010010110001011100011000101110111110000110011011100",
        "0011100110100110111001000001110111001011101010110101011001110101011011001110011000001110011000111101010110111010011000101110100011101101100010111000011001111000011001110001010100110000101000100001000010101100011001110100000101010011000100110110010111111111",
        "0000000011100101101101011011110011100011011111001101110000001101011110110010000100110010100001111110010011101100001001001010110110000110010010101110101111001101101110011010011010010001001101001011001111011111100011001110111011111011000010101001000110111001",
        "1000101001101110011100110111000001101000010011100000000100111100001111101110000010000010111100101111001111111101011011011011101011000011101100000111100101000101001111111111110010110111000101011101011110100111001111111110001010110011010000110111000011001000",
        "0011000000011110000010000111011110000010000111100110101010110110100100000000000000111010100010110010111001101101001101011010011101101110000111110011000101010101100010010100100001100101010101010101110111110111011000010100011001000010111101111111100101111011"
    )
    private val BLOCK_CONTENTS = HASHES + HASHES

    private const val BLOCK_SIZE = 170.0
    private const val BLOCK_SPACING_X = 270.0
    private const val BLOCK_CENTER_Y = 350.0

    override val totalFrames = (HASHES.size * (BLOCK_SIZE + BLOCK_SPACING_X) / MOVE_SPEED).roundToInt()

    private const val FONT_SIZE = 20
    private const val LINES = ((BLOCK_SIZE - 4) / FONT_SIZE).toInt()
    private val CHAR_WIDTH get() = context.measureText("0").width
    private val CHARS_PER_LINE get() = ((BLOCK_SIZE - 4) / CHAR_WIDTH).toInt()
    // use get() since we change the font inside init
    // using monospace font, so we can get width of 1 character

    private const val BLUR_WIDTH = 40.0

    private const val SIGNING_IMAGE_SIZE = 100.0
    private const val SIGNING_IMAGE_OFFSET_Y = 270.0  // offset from block center

    private const val TRANSFER_SPEED = 0.23
    private const val TRANSFER_LINES = ((SIGNING_IMAGE_OFFSET_Y - (BLOCK_SIZE + SIGNING_IMAGE_SIZE) / 2) / FONT_SIZE).toInt()

    private const val DOCUMENT_FADE_FACTOR = 1.2

    private const val TEXT_OFFSET_X = 6.6  // needs to be adjusted manually
    private const val TEXT_OFFSET_Y = 1.3  // needs to be adjusted manually

    private val documentImage = createSquareImage((SIGNING_IMAGE_SIZE * 2).roundToInt()).apply { src = "/assets/svg/signing.svg" }

    override fun draw(time: Int) {
        rescaleIfNeeded()
        context.font = "${FONT_SIZE}px Courier"
        context.lineWidth = 4.0

        val currentFrame = (time / 2) % totalFrames

        context.clearRect(0.0, 0.0, CANVAS_WIDTH, CANVAS_HEIGHT)

        val startX = -currentFrame * MOVE_SPEED
        for (blockIdx in BLOCK_CONTENTS.indices) {
            val blockX = startX + blockIdx * (BLOCK_SIZE + BLOCK_SPACING_X)
            drawBlock(blockIdx, blockX, BLOCK_CENTER_Y - BLOCK_SIZE / 2)
            context.beginPath()
            context.moveTo(blockX + BLOCK_SIZE, BLOCK_CENTER_Y)
            context.lineTo(blockX + BLOCK_SIZE + BLOCK_SPACING_X, BLOCK_CENTER_Y)
            context.stroke()
        }

        context.save()
        context.fillStyle = "#ffffff20"
        for (x in 0..BLUR_WIDTH.toInt()) {
            context.fillRect(0.0, 0.0, x.toDouble(), CANVAS_HEIGHT)
            context.fillRect(CANVAS_WIDTH - x, 0.0, CANVAS_WIDTH, CANVAS_HEIGHT)
        }
        context.restore()
    }

    private fun drawBlock(blockIdx: Int, x: Double, y: Double) {
        val content = BLOCK_CONTENTS[blockIdx].substring(0, LINES * CHARS_PER_LINE)

        context.strokeRect(x, y, BLOCK_SIZE, BLOCK_SIZE)

        val charCount = ((CANVAS_WIDTH - x + BLOCK_SIZE / 2) * TRANSFER_SPEED).roundToInt()
        val currentContent = content.substring(0, charCount.coerceAtMost(content.length))
        for (line in 0 until LINES) {
            context.fillText(
                currentContent.substring(CHARS_PER_LINE * line, CHARS_PER_LINE * (line + 1)),
                x + TEXT_OFFSET_X, y + TEXT_OFFSET_Y + (line + 1) * FONT_SIZE
            )
        }

        if (charCount < content.length * DOCUMENT_FADE_FACTOR) {
            val signingImageX = x + (BLOCK_SIZE - SIGNING_IMAGE_SIZE) / 2 + 3
            val signingImageY = BLOCK_CENTER_Y - (SIGNING_IMAGE_SIZE / 2) + SIGNING_IMAGE_OFFSET_Y * if (blockIdx % 2 == 0) -1 else 1

            context.drawImage(documentImage, signingImageX, signingImageY, SIGNING_IMAGE_SIZE, SIGNING_IMAGE_SIZE)

            if (charCount < content.length) {
                val transferOffsetY = x % FONT_SIZE
                val textX = x + (BLOCK_SIZE - CHAR_WIDTH) / 2
                for (line in 0 until min(content.length - charCount, TRANSFER_LINES)) {
                    val textY = BLOCK_CENTER_Y +
                            (BLOCK_SIZE / 2 + transferOffsetY + line * FONT_SIZE) * (if (blockIdx % 2 == 0) -1 else 1) +
                            if (blockIdx % 2 == 0) 0 else FONT_SIZE / 2
                    context.fillText(content[charCount + line].toString(), textX, textY)
                }
            } else {
                val opacity = ((charCount - content.length) * 255.0 / (content.length * (DOCUMENT_FADE_FACTOR - 1))).roundToInt()
                val opacityHex = opacity.toString(16).padStart(2, '0')

                context.save()
                context.fillStyle = "#ffffff$opacityHex"
                context.fillRect(signingImageX, signingImageY, SIGNING_IMAGE_SIZE, SIGNING_IMAGE_SIZE)
                context.restore()
            }
        }
    }
}

object StrongPrivacyProtectionAnimation : Animation("RabbitSign-index-strongPrivacyProtection-canvas") {
    private const val GROW_FRAMES = 40
    private const val MOVE_FRAMES = 100
    private const val PAUSE_FRAMES = 10
    private const val FADE_FRAMES = 40
    override val totalFrames = GROW_FRAMES + MOVE_FRAMES + GROW_FRAMES + PAUSE_FRAMES + FADE_FRAMES
    private const val MOVE_START = GROW_FRAMES
    private const val SHRINK_START = MOVE_START + MOVE_FRAMES
    private const val PAUSE_START = SHRINK_START + GROW_FRAMES
    private const val FADE_START = PAUSE_START + PAUSE_FRAMES

    private const val LOGO_IMAGE_SIZE = 250.0
    private const val LOGO_IMAGE_CENTER_X = 180.0
    private const val LOGO_IMAGE_CENTER_Y = 450.0
    private const val CIRCLE_SIZE = 150.0

    private const val LOCK_IMAGE_SIZE = 160.0

    private const val PERSON_IMAGE_SIZE = 180.0
    private const val PERSON_IMAGE_CENTER_X = CANVAS_WIDTH - LOGO_IMAGE_CENTER_X
    private const val PERSON_IMAGE_CENTER_Y = LOGO_IMAGE_CENTER_Y

    private const val DOCUMENT_BASE_SIZE = 20.0
    private const val DOCUMENT_START_Y = LOGO_IMAGE_CENTER_Y - DOCUMENT_BASE_SIZE / 2 - 60
    private const val DOCUMENT_MOVEMENT_Y = 160.0
    private const val DOCUMENT_MOVEMENT_SIZE = 150.0

    private val logoImage = createSquareImage((LOGO_IMAGE_SIZE * 2).roundToInt()).apply { src = "/assets/logo/logo-thumbnail-sm.png" }
    private val lockImage = createSquareImage((LOCK_IMAGE_SIZE * 2).roundToInt()).apply { src = "/assets/svg/shield-lock.svg" }
    private const val personSvg =
        "M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
    private const val personFillSvg = "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
    private val documentImage = createSquareImage((DOCUMENT_MOVEMENT_SIZE * 2).roundToInt()).apply { src = "/assets/svg/file-earmark.svg" }

    override fun draw(time: Int) {
        rescaleIfNeeded()
        context.lineWidth = 8.0
        context.fillStyle = "white"
        context.strokeStyle = "black"

        val currentFrame = (time / 20) % totalFrames

        context.clearRect(0.0, 0.0, CANVAS_WIDTH, CANVAS_HEIGHT)

        context.drawImage(
            logoImage, LOGO_IMAGE_CENTER_X - LOGO_IMAGE_SIZE / 2, LOGO_IMAGE_CENTER_Y - LOGO_IMAGE_SIZE / 2, LOGO_IMAGE_SIZE, LOGO_IMAGE_SIZE
        )

        context.beginPath()
        context.arc(LOGO_IMAGE_CENTER_X, LOGO_IMAGE_CENTER_Y, CIRCLE_SIZE, 0.0, PI * 2)
        context.stroke()
        context.beginPath()
        context.arc(PERSON_IMAGE_CENTER_X, PERSON_IMAGE_CENTER_Y, CIRCLE_SIZE, 0.0, PI * 2)
        context.stroke()

        context.drawPath(
            personSvg, PERSON_IMAGE_CENTER_X - PERSON_IMAGE_SIZE / 2, PERSON_IMAGE_CENTER_Y - PERSON_IMAGE_SIZE / 2,
            PERSON_IMAGE_SIZE, PERSON_IMAGE_SIZE, "black"
        )

        if (currentFrame >= PAUSE_START) {
            val opacity = if (currentFrame < FADE_START) 255 else ((totalFrames - currentFrame) * 255.0 / FADE_FRAMES).roundToInt()
            val opacityHex = opacity.toString(16).padStart(2, '0')
            context.drawPath(
                personFillSvg, PERSON_IMAGE_CENTER_X - PERSON_IMAGE_SIZE / 2, PERSON_IMAGE_CENTER_Y - PERSON_IMAGE_SIZE / 2,
                PERSON_IMAGE_SIZE, PERSON_IMAGE_SIZE, "#1dcd95$opacityHex"
            )
            context.save()
            context.strokeStyle = "#1dcd95$opacityHex"
            context.beginPath()
            context.arc(PERSON_IMAGE_CENTER_X, PERSON_IMAGE_CENTER_Y, CIRCLE_SIZE, 0.0, PI * 2)
            context.stroke()
            context.restore()
        }

        when {
            currentFrame < MOVE_START -> {
                val frameScale = currentFrame.toDouble() / GROW_FRAMES
                val distToCover = DOCUMENT_MOVEMENT_Y - DOCUMENT_START_Y
                val sizeToCover = DOCUMENT_MOVEMENT_SIZE - DOCUMENT_BASE_SIZE
                drawDocument(LOGO_IMAGE_CENTER_X, DOCUMENT_START_Y + distToCover * frameScale, DOCUMENT_BASE_SIZE + sizeToCover * frameScale)
            }
            currentFrame < SHRINK_START -> {
                val distToCover = PERSON_IMAGE_CENTER_X - LOGO_IMAGE_CENTER_X
                val currentMoveFrame = currentFrame - GROW_FRAMES
                drawDocument(LOGO_IMAGE_CENTER_X + distToCover * currentMoveFrame / MOVE_FRAMES, DOCUMENT_MOVEMENT_Y, DOCUMENT_MOVEMENT_SIZE)
            }
            currentFrame < PAUSE_START -> {
                val currentGrowFrame = PAUSE_START - currentFrame  // go backwards
                val frameScale = currentGrowFrame.toDouble() / GROW_FRAMES
                val distToCover = DOCUMENT_MOVEMENT_Y - DOCUMENT_START_Y
                val sizeToCover = DOCUMENT_MOVEMENT_SIZE - DOCUMENT_BASE_SIZE
                drawDocument(PERSON_IMAGE_CENTER_X, DOCUMENT_START_Y + distToCover * frameScale, DOCUMENT_BASE_SIZE + sizeToCover * frameScale)
            }
        }
    }

    private fun drawDocument(centerX: Double, centerY: Double, size: Double) {
        // add background behind the document
        context.fillRect(centerX - size * 0.36, centerY - size * 0.44, size * 0.53, size * 0.5)
        context.fillRect(centerX - size * 0.34, centerY - size * 0.28, size * 0.66, size * 0.76)

        context.drawImage(documentImage, centerX - size / 2, centerY - size / 2, size, size)

        val lockSize = size * 0.64
        context.drawImage(lockImage, centerX - lockSize / 2, centerY - 0.35 * lockSize, lockSize, lockSize)
    }
}

private fun CanvasRenderingContext2D.drawPath(svg: String, dx: Double, dy: Double, dw: Double, dh: Double, color: String, svgSize: Double = 16.0) {
    val path = Path2D()
    val transformMatrix = DOMMatrix().translateSelf(dx, dy).scale(dw / svgSize, dh / svgSize)
    path.addPath(Path2D(svg), transformMatrix)

    this.save()
    this.fillStyle = color
    this.fill(path)
    this.restore()
}
