package com.rabbitsign.web.filemanager

import com.rabbitsign.web.util.displayInfoMessage
import com.rabbitsign.web.util.getFileExtension
import kotlinx.browser.document
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLTableRowElement
import org.w3c.dom.asList
import org.w3c.files.FileList

class CreationPageFileManager(private val docWrappers: MutableMap<String, HTMLDivElement> = mutableMapOf()): FileManager("RabbitSign-creation") {
    private val updateDocWrappers: () -> Unit = {
        log.info("updateDocWrappers")
        val fileNames = fileList.map { it.name }
        docWrappers.entries.removeAll {
            if (it.key !in fileNames) {
                it.value.remove()  // delete the doc wrapper element from DOM before removing from map
                true
            } else false
        }
    }

    init {
        // add files from template into fileList; will be empty list for regular folder/template creation
        fileList = document.querySelectorAll("tr.file-row").asList().map {
            val rowElement = it as HTMLTableRowElement
            val url = rowElement.getAttribute("data-file-url")!!
            val name = rowElement.getAttribute("data-file-name")!!
            FileLink(url, name)
        }.toMutableList()
        updateFileListDisplay(updateDocWrappers)
    }

    override fun onFileSelected(files: FileList?) {
        log.info("onFileSelected with ${files?.length} file(s)")

        if (files == null || files.length == 0) {
            return
        }
        for (file in files.asList()) {
            if (file.name.getFileExtension() != "pdf") { // TODO find stronger file type check
                displayInfoMessage("Sorry, only PDF files are currently supported. " +
                        "Please try using an online converter like Google Docs to convert your file to a PDF. " +
                        "Also, make sure that your file ends with \".pdf\".")
                return
            }
        }

        val newFileList = fileList + files.asList().map { FileObject(it) }
        if (newFileList.size != newFileList.distinctBy { it.name }.size) {  // don't allow two files with the same name
            displayInfoMessage("Please ensure all your files have unique names.")
        } else {
            fileList = newFileList.toMutableList()
            updateFileListDisplay(updateDocWrappers)
        }
    }
}
