package com.rabbitsign.web.filemanager

import com.rabbitsign.web.util.displayInfoMessage
import org.w3c.dom.asList
import org.w3c.files.FileList
import org.w3c.files.get

class BatchCreationPageFileManager : FileManager("RabbitSign-creation") {
    override fun onFileSelected(files: FileList?) {
        log.info("onFileSelected with ${files?.length} file(s)")

        if (files == null || files.length == 0) {
            return
        }
        if (files.length > 1) {
            // Should never happen with the HTML input's multiple=false
            displayInfoMessage("Please select only 1 file.")
            return
        }
        val file = files[0]!!
        if (file.type !in listOf("text/plain", "text/csv")) {
            log.debug("File type: ${file.type}")
            displayInfoMessage("Please select a plain text or CSV file.")
            return
        }
        fileList = files.asList().map { FileObject(it) }.toMutableList()

        updateFileListDisplay(doNothing)
    }

    private val doNothing = {}
}
