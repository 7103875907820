package com.rabbitsign.common

import kotlinx.serialization.Serializable

@Serializable
data class SigningOptionToken(val signingOptionToken: String)

@Serializable
data class S3SigningOption(val signingOptionToken: String, val fieldType: FieldType, val imageUrl: String)

@Serializable
data class TextSigningOption(
    val signingOptionToken: String, val fieldType: FieldType,
    val textValue: String, val fontFamily: FontFamily
)

@Serializable
data class SigningOptionId(val signingOptionId: String)
