package com.rabbitsign.common.util

import com.rabbitsign.common.DateFormat

data class Date(val year: Int, val month: Int, val day: Int)

/**
 * This is our home-grown multi-platform Kotlin date formatter.
 * It has no dependency on Java.
 */
fun Date.format(dateFormat: DateFormat): String {
    val mm = month.pad()
    val dd = day.pad()
    val mmm = months[month - 1]
    return when (dateFormat) {
        DateFormat.MM_DD_YYYY_SLASH -> "$mm/$dd/$year"
        DateFormat.DD_MM_YYYY_SLASH -> "$dd/$mm/$year"
        DateFormat.DD_MM_YYYY_DASH -> "$dd-$mm-$year"
        DateFormat.DD_MM_YYYY_DOT -> "$dd.$mm.$year"
        DateFormat.YYYY_MM_DD_SLASH -> "$year/$mm/$dd"
        DateFormat.YYYY_MM_DD_DASH -> "$year-$mm-$dd"
        DateFormat.YYYY_MM_DD_DOT -> "$year.$mm.$dd"
        DateFormat.MMM_D_YYYY -> "$mmm $day, $year"
        DateFormat.D_MMM_YYYY -> "$day $mmm $year"
    }
}

private val months = listOf("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec")

private fun Int.pad() = this.toString().padStart(2, '0')

