package com.rabbitsign.common

enum class FontFamily(val fontName: String) {
    NOTO_SANS("NotoSans"),
    ALLURA("Allura"),
    CAVEAT("Caveat"),
    CLICKER_SCRIPT("Clicker Script"),
    COURGETTE("Courgette"),
    DANCING_SCRIPT("Dancing Script"),
    INDIE_FLOWER("Indie Flower"),
    KALAM("Kalam"),
    SATISFY("Satisfy"),
    COURIER("Courier"),
    BOOTSTRAP_ICONS("Bootstrap Icons");

    companion object {
        fun fromFontName(name: String): FontFamily {
            return values().find { it.fontName.equals(name, ignoreCase = true) } ?: throw IllegalStateException("No FontFamily found for name $name")
        }
    }
}

val SIGNING_FONT_FAMILIES = listOf(
    FontFamily.ALLURA, FontFamily.CAVEAT, FontFamily.CLICKER_SCRIPT, FontFamily.COURGETTE, FontFamily.DANCING_SCRIPT,
    FontFamily.INDIE_FLOWER, FontFamily.KALAM, FontFamily.SATISFY
)
val DEFAULT_SIGNING_FONT = FontFamily.SATISFY