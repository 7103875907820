package com.rabbitsign.web

import com.rabbitsign.web.util.displayErrorMessage
import com.rabbitsign.web.util.hide
import com.rabbitsign.web.util.show

class TemplateLinkPDFDisplay(renderers: List<PDFRenderer>, fieldsManager: StaticFieldsManager): PDFDisplay(renderers, fieldsManager) {
    init {
        log.info("init with ${renderers.size} renderers, fieldsManager")
    }

    override fun handleNextButton(oldFileIdx: Int) = true

    override fun handleSubmitButton() {
        displayErrorMessage(950, "This document is a preview and cannot be submitted.")
    }

    override fun handleCancelButton() {
        show("#navbarContainer")
        show("main.page-container")
        hide("#interfaceContainer")
    }
}
