package com.rabbitsign.web.util

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*


// assumes element has a parent
fun addValidityListeners(inputElement: HTMLInputElement) {
    console.log("invoked addValidityListeners with inputElement.id=${inputElement.id}")
    inputElement.addEventListener("blur", {
        if (!inputElement.checkValidity()) {
            inputElement.parentElement!!.addClass("was-validated")
        }
    })
    inputElement.addEventListener("input", {
        if (inputElement.checkValidity()) {
            inputElement.parentElement!!.removeClass("was-validated")
        }
    })
    // only needed for <input> elements since <textarea> elements can't have type=email
    if (inputElement.type == "email") inputElement.addEmailDomainCheck()
}

// assumes element has a parent
fun addValidityListeners(inputElement: HTMLTextAreaElement) {
    console.log("invoked addValidityListeners with inputElement.id=${inputElement.id}")
    inputElement.addEventListener("blur", {
        if (!inputElement.checkValidity()) {
            inputElement.parentElement!!.addClass("was-validated")
        }
    })
    inputElement.addEventListener("input", {
        if (inputElement.checkValidity()) {
            inputElement.parentElement!!.removeClass("was-validated")
        }
    })
}

// removes was-validated class from all children
// unvalidate != invalidate
fun unvalidateChildren(elem: HTMLElement) {
    console.log("invoked unvalidateChildren with elem=$elem")
    val wasValidated = elem.querySelectorAll(".was-validated").asList()
    // for some reason, getElementsByClassName causes the list to lose elements as classes are removed, so use querySelectorAll instead

    for (validatedElem in wasValidated) {
        (validatedElem as HTMLElement).removeClass("was-validated")
    }
}


fun checkInputValidity(): Boolean {
    console.log("invoked checkInputValidity")
    var allInputsValid = true
    val inputElems = document.querySelectorAll("main.page-container input.form-control")  // avoid checking inputs in #interfaceContainer
    for (inputElemNode in inputElems.asList()) {
        val inputElem = inputElemNode as HTMLInputElement
        if (!inputElem.checkValidity() && !inputElem.disabled) {
            inputElem.parentElement!!.addClass("was-validated")
            allInputsValid = false
        }
    }
    val textAreaElems = document.querySelectorAll("main.page-container textarea.form-control")
    for (textAreaElemNode in textAreaElems.asList()) {
        val textAreaElem = textAreaElemNode as HTMLTextAreaElement
        if (!textAreaElem.checkValidity()) {
            textAreaElem.parentElement!!.addClass("was-validated")
            allInputsValid = false
        }
    }
    return allInputsValid
}

fun HTMLInputElement.addEmailDomainCheck() {
    val domainMisspelledMessage = document.createDiv("hidden", "custom-feedback")
    domainMisspelledMessage.textContent = "Your email address may be misspelled."
    domainMisspelledMessage.style.color = "orange"

    this.addEventListener("input", {
        domainMisspelledMessage.addClass("hidden")
    })
    this.addAfterInputHandler({
        if (this.checkValidity() && domainSeemsMisspelled(this.value.lowercase())) {  // emails treated as case-insensitive
            domainMisspelledMessage.removeClass("hidden")
        }
    })
    this.after(domainMisspelledMessage)  // inserts message after input
}
