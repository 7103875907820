package com.rabbitsign.common

import kotlinx.serialization.Serializable

const val FIELD_HEIGHT = 27.0
const val NO_ASPECT_RATIO = -1

enum class FieldType(val initWidth: Double, val minWidth: Double, val aspectRatio: Int, val icon: String, val displayName: String) {
    SIGNATURE(FIELD_HEIGHT * 6, 66.0, 6, "bi-pencil", "a signature"),
    INITIALS(FIELD_HEIGHT * 2, 33.0, 2, "bi-rabbitsign-initials", "an initials"),
    LOCAL_DATE(143.0, 143.0, NO_ASPECT_RATIO, "bi-calendar-event", "a signing date"),
    TEXTBOX(154.0, 33.0, NO_ASPECT_RATIO, "bi-fonts", "a textbox"),
    CHECKBOX(33.0, 13.0, 1, "bi-check2", "a checkbox")
}

const val BACKEND_DEFAULT_FONT_SIZE = 15.0
const val FRONTEND_DEFAULT_FONT_SIZE = 16.0


@Serializable
data class FieldPosition(
    val docNumber: Int,  // zero-based
    val pageIndex: Int,  // zero-based
    val x: Double,
    val y: Double,  // This y position is relative to the page specified by pageIndex
    val width: Double,
    val height: Double,
    val fontSize: Double = BACKEND_DEFAULT_FONT_SIZE
)

@Serializable
data class FieldSpec(
    var id: Int,  // generated by server upon folder creation, unique across the docs in the folder
    val type: FieldType,
    var currentValue: String,
    val position: FieldPosition,
    val modifiable: Boolean = true,
    var name: String = "",  // assigned by the client at template creation (& unique across the folder), otherwise "" for regular folder
    var signingOptionId: String = ""  // If not empty, insert the image/text specified by this instead of currentValue
)

// calculates the font size for the checkmark based on the width of the checkbox field
fun checkmarkSizeFromFieldWidth(width: Int) = 1.25f * (width - 3)
fun checkmarkSizeFromFieldWidth(width: Double) = 1.25f * (width - 3)

fun fieldWidthFromCheckmarkSize(size: Double) = (size / 1.25) + 3

fun fieldHeightFromFontSize(fontSize: Double) = 1.5 * fontSize + 3

fun fontSizeFromFieldHeight(fieldHeight: Double) = (fieldHeight - 3) / 1.5
