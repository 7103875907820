package com.rabbitsign.web.util

import com.rabbitsign.common.*
import kotlinx.browser.document
import org.w3c.dom.*
import kotlin.math.roundToInt

fun HTMLImageElement.setWidth(width: Double) {
    this.width = width.roundToInt()
    this.setAttribute("data-width", width.toString())
}
fun HTMLImageElement.setHeight(height: Double) {
    this.height = height.roundToInt()
    this.setAttribute("data-height", height.toString())
}
fun HTMLImageElement.getWidth(): Double = this.getAttribute("data-width")?.toDouble() ?: this.width.toDouble()
fun HTMLImageElement.getHeight(): Double = this.getAttribute("data-height")?.toDouble() ?: this.height.toDouble()

fun HTMLCanvasElement.setWidth(width: Double) {
    this.width = width.roundToInt()
    this.setAttribute("data-width", width.toString())
}
fun HTMLCanvasElement.setHeight(height: Double) {
    this.height = height.roundToInt()
    this.setAttribute("data-height", height.toString())
}
fun HTMLCanvasElement.getWidth(): Double = this.getAttribute("data-width")?.toDouble() ?: this.width.toDouble()
fun HTMLCanvasElement.getHeight(): Double = this.getAttribute("data-height")?.toDouble() ?: this.height.toDouble()


fun getScale() = document.querySelector(".current-wrapper")!!.getAttribute("data-scale")!!.toDouble()

var HTMLElement.page: Int
    get() = this.getAttribute("data-page")!!.toInt()
    set(value) = this.setAttribute("data-page", value.toString())

// receives and returns unscaled values
var HTMLElement.fieldHeight: Double
    get() = this.getAttribute("data-height")?.toDouble() ?: (this.height / getScale())
    set(value) {
        this.setAttribute("data-height", value.toString())
        this.updateSize(getScale())
    }
var HTMLElement.fieldWidth: Double
    get() = this.getAttribute("data-width")?.toDouble() ?: (this.width / getScale())
    set(value) {
        this.setAttribute("data-width", value.toString())
        this.updateSize(getScale())
    }
val HTMLElement.minFieldWidth: Double get() =
    if (this.fieldType == FieldType.CHECKBOX) FieldType.CHECKBOX.minWidth
    else this.fieldType.minWidth * this.fieldFontSize / FRONTEND_DEFAULT_FONT_SIZE

var HTMLElement.fieldPosX: Double
    get() = this.getAttribute("data-pos-x")?.toDouble() ?: (this.getBoundingClientRect().left / getScale())
    set(value) {
        this.setAttribute("data-pos-x", value.toString())
        this.updatePosition(getScale())
    }
var HTMLElement.fieldPosY: Double
    get() = this.getAttribute("data-pos-y")!!.toDouble()
    set(value) = this.setAttribute("data-pos-y", value.toString())

var HTMLElement.fieldPosPageY: Double
    get() = this.getAttribute("data-pos-py")?.toDouble() ?: (this.getBoundingClientRect().top / getScale())
    set(value) {
        this.setAttribute("data-pos-py", value.toString())
        this.updatePosition(getScale())
    }

fun HTMLElement.updateSize(scale: Double) {
    this.style.height = "${(this.fieldHeight + 2) * scale}px"  // add 2 to account for border
    this.style.minWidth = "${(this.fieldWidth + 2) * scale}px"
    this.resizeContentInputIfExists(height = (this.fieldHeight + 2) * scale, width = (this.fieldWidth + 2) * scale)
}

fun HTMLElement.updatePosition(scale: Double) {
    this.style.left = "${this.fieldPosX * scale}px"
    this.style.top = "${this.fieldPosPageY * scale}px"
}

var HTMLElement.fieldType: FieldType
    get() = FieldType.valueOf(this.getAttribute("data-field-type")!!)
    set(value) = this.setAttribute("data-field-type", value.name)

var HTMLElement.fontFamily: FontFamily
    get() = FontFamily.fromFontName(this.getAttribute("data-font-family")!!)
    set(value) = this.setAttribute("data-font-family", value.fontName)

// use the backend default font size; the frontend default is for creating new fields, which will always have a data-font-size attr
var HTMLElement.fieldFontSize: Double
    get() = this.getAttribute("data-font-size")?.toDouble() ?: BACKEND_DEFAULT_FONT_SIZE
    set(value) {
        this.setAttribute("data-font-size", value.toString())
        this.updateFontSize(getScale())
    }

var HTMLElement.fieldName: String
    get() = this.getAttribute("data-field-name") ?: ""
    set(value) {
        this.setAttribute("data-field-name", value)
    }

fun HTMLElement.updateFontSize(scale: Double) {
    this.style.fontSize =
        if (this.fieldType == FieldType.CHECKBOX) "${checkmarkSizeFromFieldWidth(this.fieldWidth) * scale}px"
        else "${this.fieldFontSize * scale}px"
}

val HTMLElement.width: Double get() = this.getBoundingClientRect().width
val HTMLElement.height: Double get() = this.getBoundingClientRect().height
