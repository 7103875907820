package com.rabbitsign.web

import com.github.doyaaaaaken.kotlincsv.util.MalformedCSVException
import com.rabbitsign.common.*
import com.rabbitsign.web.filemanager.BatchCreationPageFileManager
import com.rabbitsign.web.filemanager.FileObject
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import org.w3c.files.FileReader
import kotlin.js.*

@OptIn(ExperimentalJsExport::class)
@JsExport
object CreateBatchPage : RabbitSignPage() {
    private var selectedTemplateRow: HTMLElement? = null
    private var selectedTemplateId: String? = null
    private val fileManager = BatchCreationPageFileManager()

    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        addValidityListeners(document.getElementById("RabbitSign-creation-batchName-input") as HTMLInputElement)
        initializeTableModal()
        initializeSubmitButton()
    }

    private fun initializeTableModal() {
        val tableId = "RabbitSign-creation-batch-templateSelectionModal-templateTable-table"
        val table = document.getElementById(tableId) as HTMLTableElement

        val selectButtonId = "RabbitSign-creation-batch-templateSelectionModal-select-button"
        val selectButton = document.getElementById(selectButtonId) as HTMLButtonElement
        selectButton.disabled = true

        val rows = table.querySelectorAll("tbody tr").asList()
        rows.forEach { rowNode ->
            val row = rowNode as HTMLElement
            row.onclick = {
                selectedTemplateRow?.removeClass("table-primary")
                selectedTemplateRow = row
                selectedTemplateRow?.addClass("table-primary")
                selectedTemplateId = row.getAttribute("data-template-id")

                selectButton.disabled = false

                Unit
            }

            val dateCell = row.querySelector(".date-cell") as HTMLElement
            dateCell.replaceWith(document.createTimeCell(dateCell.getAttribute("data-date")!!))
        }

        selectButton.onclick = {
            val templateInfoId = "RabbitSign-creation-selectTemplate-templateInfo"
            val templateInfoIdText = document.getElementById(templateInfoId) as HTMLElement
            templateInfoIdText.innerHTML = """<br>Template selected: $selectedTemplateId<br>
                |Tip: You can <button class="btn btn-outline-primary mr-1" onclick="downloadTemplateParamsCsvFile('$selectedTemplateId')">download</button> a sample CSV file for this template for the next step.
            """.trimMargin()
            templateInfoIdText.hidden = false
            Unit
        }
    }

    @JsName("downloadTemplateParamsCsvFile")
    fun downloadTemplateParamsCsvFile(templateId: String) {
        log.info("downloadTemplateParamsCsvFile")

        MainScope().launch {
            val templateParams = getApi().callGetTemplateParams(templateId)
            val text = generateCsvHeaders(templateParams).joinToString(",")
            val element = document.createElement("a") as HTMLElement
            element.setAttribute("href", "data:text/csv;charset=utf-8,$text")
            element.setAttribute("download", "Template$templateId.csv")
            element.style.display = "none"
            element.click()
        }
    }

    private fun initializeSubmitButton() {
        val submitButton = document.getElementById("RabbitSign-batch-creation-submit-button") as HTMLButtonElement
        submitButton.onclick = onclick@{
            log.info("run.batchSubmitButton.onclick")

            if (!checkInputValidity()) {
                return@onclick Unit
            }

            val templateId: String = selectedTemplateId ?: return@onclick Unit.also {
                displayWarningMessage(908, "Please select a template.")
            }

            if (fileManager.files.isEmpty()) {
                displayWarningMessage(909, "Please select a CSV file.")
                return@onclick Unit
            }

            // Parse the selected csv file & validate against the template
            val csvFile = fileManager.files[0] as FileObject  // BatchCreationPageFileManager uses FileObject only
            val reader = FileReader()

            reader.onload = {
                // Get the contents of the file
                val fileContents = reader.result as String

                try {
                    val valueList: List<DevApiTemplateInstantiation> = parseBatchCsv(fileContents)
                    if (valueList.isEmpty()) {
                        displayWarningMessage(910, "Please populate the CSV file.")
                    } else {
                        MainScope().launch {
                            log.debug("Sending batch")
                            val name = getInputValue("RabbitSign-creation-batchName-input")
                            val response =
                                getApi().callCreateBatch(templateId, TemplateInstantiationBatch(name, valueList))
                            displayInfoMessage("Batch ${response.batchId} sent. Track the status on Dashboard's Batches tab.")
                        }
                    }
                } catch (e: CsvParsingException) {
                    log.warn("Invalid value found in CSV. ${e.message}")
                    displayWarningMessage(911, "Invalid value found in the CSV file. ${e.message}")
                } catch (e: MalformedCSVException) {
                    log.warn("Malformed CSV. ${e.message}")
                    displayWarningMessage(912, "Malformed CSV file: ${e.message}")
                }
            }

            reader.readAsText(csvFile.file)
        }
    }
}