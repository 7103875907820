package com.rabbitsign.web.util

import kotlin.math.min

// Optimal string alignment distance
// https://en.wikipedia.org/wiki/Damerau%E2%80%93Levenshtein_distance#Optimal_string_alignment_distance
fun osaDistance(s: String, t: String): Int {
    val dp = Array(s.length + 1) { IntArray(t.length + 1) }
    // dp[i][j] stores the distance between s[0:i] and t[0:j] where "abc"[0:2] -> "ab"

    for (i in 0..s.length) {
        dp[i][0] = i  // from a string with i characters to an empty string
    }
    for (j in 0..t.length) {
        dp[0][j] = j  // from an empty string to a string with j characters
    }

    for (i in s.indices) {
        for (j in t.indices) {
            val subCost = if (s[i] == t[j]) 0 else 1
            dp[i + 1][j + 1] = minOf(
                dp[i][j] + subCost,  // substitution
                dp[i + 1][j] + 1,  // insertion
                dp[i][j + 1] + 1,  // deletion
            )
            if (i > 0 && j > 0 && s[i] == t[j - 1] && s[i - 1] == t[j]) {
                dp[i + 1][j + 1] = min(dp[i + 1][j + 1], dp[i - 1][j - 1] + 1)  // transposition
            }
        }
    }
    return dp[s.length][t.length]
}
