package com.rabbitsign.web

import com.rabbitsign.common.ISO_FORMAT
import com.rabbitsign.common.SignerFieldsWithDate
import com.rabbitsign.common.SigningOptionToken
import com.rabbitsign.web.util.addFullPageSpinnerAndLeaveDialog
import com.rabbitsign.web.util.displayInfoMessage
import com.rabbitsign.web.util.getFormattedDateString
import com.rabbitsign.web.util.openPage
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch

class ViewFolderPDFDisplay(
    renderers: List<PDFRenderer>, fieldsManager: FieldsManager, private val signingOptionToken: SigningOptionToken, private val endingPageUrl: String
): PDFDisplay(renderers, fieldsManager) {
    override fun handleNextButton(oldFileIdx: Int): Boolean {
        return if (document.querySelector(".current-wrapper .fillable:not(.filled)") != null) {
            displayInfoMessage("Not all fields on this document have been signed. Use the \"Find next unsigned field\" button at the top of your page to find the next unsigned field.")
            false
        } else true
    }

    override fun handleSubmitButton() {
        log.info("handleSubmitButton")
        val fields = ViewFolderPage.checkSignatures(useFieldName = false)?.toMutableList()
        if (fields != null) {
            openLegalTextModal {
                addFullPageSpinnerAndLeaveDialog()

                val apiInput = SignerFieldsWithDate(getFormattedDateString(ISO_FORMAT), fields)

                val url = window.location.pathname

                MainScope().launch {
                    uploadNewSignatureOptions(fields, signingOptionToken)
                    getApi().callSignFolder(url, apiInput)

                    delay(100)  // give DynamoDb some time to update
                    openPage(endingPageUrl)

                    // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                    Unit
                }
            }
        }
    }

    override fun handleCancelButton() = Unit
}
