package com.rabbitsign.web

import kotlinx.browser.window
import kotlin.math.roundToLong

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("IndexPage")
object IndexPage : RabbitSignPage() {
    override fun runPage() {
        log.info("run")

        initializeNavbar()

        // initialize objects
        UnlimitedFreeTemplateAnimation
        StrongPrivacyProtectionAnimation
        ProofOfExistenceAnimation

        var startTime = 0L
        fun handleAnimationFrame(timestamp: Long) {
            val elapsed = (timestamp - startTime).toInt()
            UnlimitedFreeTemplateAnimation.draw(elapsed)
            StrongPrivacyProtectionAnimation.draw(elapsed)
            ProofOfExistenceAnimation.draw(elapsed)
            window.requestAnimationFrame { handleAnimationFrame(it.roundToLong()) }
        }

        window.requestAnimationFrame {
            startTime = it.roundToLong()
            handleAnimationFrame(it.roundToLong())
        }
    }
}
