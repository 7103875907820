package com.rabbitsign.web

import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.*

/*
Each field element's behavior is determined by the CSS classes it has.
"signature-field", "initials-field", "local_date-field", "textbox-field", "checkbox-field" all denote field type
"draggable" means the field can be dragged/repositioned by the user
"dragging" means the field is currently being dragged by the user and will follow the cursor
"focused" means the field is currently highlighted on the page and can be moved with arrow keys
"resizable" means the field size can be changed by the user; doesn't include field expansion from text overflow
"resizing" means the field size is currently being changed by the user
"fillable" means the field can be filled/signed
"filled" means the field has already been filled/signed
"deletable" means the field can be removed/deleted
"disabled" means the field is hidden and should not be sent in the API request
    fields can be hidden (with the "hidden" or "d-none" classes) *without* being disabled
 */


open class StaticFieldsManager {
    companion object val log = Logger(this::class.simpleName)
    
    fun scalePositions(wrapper: HTMLElement, scale: Double) {
        log.info("FieldsManager.scalePositions with wrapper.data-doc-number=${wrapper.getAttribute("data-doc-number")}, scale=$scale")
        for (fieldNode in wrapper.querySelectorAll(".field").asList()) {
            val field = fieldNode as HTMLDivElement

            val page = wrapper.querySelector(".pdf-page[data-page=\"${field.page}\"]") as HTMLElement
            val offset = page.offsetTop / scale
            field.fieldPosPageY = offset + field.fieldPosY

            field.updatePosition(scale)
            field.updateSize(scale)
            field.updateFontSize(scale)
        }
    }

    open fun goToNextField(): HTMLDivElement? {
        log.info("FieldsManager.goToNextField")
        val unsignedFields = document.querySelectorAll(".current-wrapper div.field.fillable:not(.filled)").asList()
            .map { it as HTMLDivElement }
            .sortedBy { it.getBoundingClientRect().top + it.getBoundingClientRect().left / 20 }
        return if (unsignedFields.isNotEmpty()) {
            val nextField = unsignedFields[0]
            nextField.scrollIntoView(ScrollIntoViewOptions(behavior = ScrollBehavior.SMOOTH))
            nextField.also {
                MainScope().launch {
                    delay(150)  // try to give the scroll a little time
                    repeat(2) {
                        nextField.addClass("highlight")
                        delay(200)
                        nextField.removeClass("highlight")
                        delay(100)
                    }
                    nextField.focus()
                }
            }
        } else {
            displayInfoMessage("All your fields on this document have been signed.")
            null
        }
    }

    open fun updateFieldRemainingCount() = Unit
}


/**
 * Handles user interactions with fields for both CreationPage and ViewFolderPage, particularly filling fields and scaling positions/sizes
 */
open class FieldsManager(private val fieldFiller: FieldFiller) : StaticFieldsManager() {
    init {
        document.querySelectorAll("div.field.fillable").asList().forEach {
            fieldFiller.attachSigningHandlers(it as HTMLDivElement)
        }
    }

    override fun updateFieldRemainingCount() = fieldFiller.updateFieldRemainingCount()
}
