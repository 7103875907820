package com.rabbitsign.web

import kotlinx.dom.hasClass
import org.w3c.dom.HTMLElement
import kotlin.math.abs
import kotlin.random.Random


private val COLOR_LIST = listOf(
    "#ffaaaa",
    "#8c9eff",
    "#77da72",
    "#a585f3",
    "#f19f36",
    "#db8aff",
    "#ffe271"
)

private fun pickRandomColorInt(random: Random): Int {
    for (i in 0 until 1000) {
        val randVal = random.nextInt(0x1_000_000)
        val red = (randVal ushr 16) and 0xFF
        val green = (randVal ushr 8) and 0xFF
        val blue = randVal and 0xFF

        if (red > green && red > blue) continue  // avoid reddish colors; red is for fields that need to be signed now
        if (abs(red - green) + abs(red - blue) + abs(green - blue) <= 50) continue  // avoid grey-ish colors
        if (red + green < 100 || green + blue < 100 || red + blue < 100) continue  // avoid dark colors

        return randVal
    }
    console.warn("Unable to pick restricted random color, picking unrestricted random color instead")
    return random.nextInt(0x1_000_000)
}

private fun pickAssigneeColor(assigneeNum: Int): String {
    if (assigneeNum < COLOR_LIST.size) {
        return COLOR_LIST[assigneeNum]
    }
    // once we run out of hardcoded colors, just pick random (seeded) ones

    val randomColor = pickRandomColorInt(Random(assigneeNum))
    return "#${randomColor.toString(16).padStart(6, '0')}"
}

fun HTMLElement.setAssigneeBackgroundColor(assigneeNum: Int) {
    if (this.hasClass("filled")) this.style.removeProperty("background-color")
    else this.style.backgroundColor = pickAssigneeColor(assigneeNum)
}

fun HTMLElement.setAssigneeTextColor(assigneeNum: Int) {
    this.style.color = pickAssigneeColor(assigneeNum)
}
