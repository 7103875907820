package com.rabbitsign.common

import kotlinx.serialization.Serializable

@Serializable
data class SignerInfo(val name: String, val email: Email, val fields: SignerFields, val signingOrder: Int = SIGNING_ORDER_1)

//class MutableSignerInfo(val name: String, val email: Email) {
//    var fields = MutableSignerFields()
//    fun addField(field: FieldSpec) {
//        fields.add(field)
//    }
//}