package com.rabbitsign.web.util

import kotlinx.browser.document
import kotlinx.dom.addClass
import org.w3c.dom.HTMLElement


private const val SIGNED_ICON = "bi-check-circle-fill"
private const val CANCELED_ICON = "bi-dash-circle-fill"
private const val WAITING_ICON = "bi-circle-fill"
private const val OWNER_ICON = "bi-circle-fill"


private fun createIcon(elemId: String = "", otherClasses: Set<String> = emptySet()): HTMLElement {
    return (document.createElement("i") as HTMLElement).apply {
        if (elemId.isNotEmpty()) {
            id = elemId
        }
        addClass("bi", *otherClasses.toTypedArray())
    }
}

internal fun createSignedIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(SIGNED_ICON, "text-success", "mr-1")))

internal fun createCanceledIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(CANCELED_ICON, "text-secondary", "mr-1")))

internal fun createWaitingMeIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(WAITING_ICON, "text-danger", "mr-1")))

internal fun createWaitingOthersIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(WAITING_ICON, "text-warning", "mr-1")))

internal fun createOwnerMeIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(OWNER_ICON, "text-primary", "mr-1")))

internal fun createOwnerOthersIcon(elemId: String = "", otherClasses: Set<String> = emptySet()) =
    createIcon(elemId, otherClasses.union(setOf(OWNER_ICON, "text-info", "mr-1")))
