package com.rabbitsign.web

@OptIn(ExperimentalJsExport::class)
@JsExport
class Logger(private val className: String?) {
    // all logDebug calls should be removed before deploy
    fun debug(vararg o: Any?) {
        console.log("$className:", *o)
    }

    fun info(vararg o: Any?) {
        if (logDebugSet()) {
            console.info("$className:", *o)
        }
    }

    fun warn(vararg o: Any?) {
        console.warn("$className:", *o)
    }
}