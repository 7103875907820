package com.rabbitsign.web

import com.rabbitsign.web.util.displayInfoMessage
import com.rabbitsign.web.util.hide
import com.rabbitsign.web.util.removeLeaveConfirmDialog
import com.rabbitsign.web.util.show
import kotlinx.browser.document

internal class PrepDocumentPDFDisplay(
    renderers: List<PDFRenderer>, private val fieldSaver: FieldSaver, private val creationFieldsManager: CreationFieldsManager
) : PDFDisplay(renderers, creationFieldsManager) {
    init {
        log.info("init with ${renderers.size} renderers, fieldSaver, creationFieldsManager")
    }

    override fun handleNextButton(oldFileIdx: Int): Boolean {
        return if (document.querySelector(".current-wrapper .fillable:not(.filled)") != null) {
            displayInfoMessage("Not all fields on this document have been signed!")
            false
        } else true
    }

    override fun handleIndexChange(fileIdx: Int) {
        creationFieldsManager.prepareCurrentPage()
        super.handleIndexChange(fileIdx)
    }

    override fun handleSubmitButton() {
        if (document.querySelector(".current-wrapper .fillable:not(.filled)") != null) {
            displayInfoMessage("Not all fields on this document have been signed!")
        } else fieldSaver.saveFields(warned = false)
    }

    override fun handleCancelButton() {
        js("$('#RabbitSign-display-prepDoc-dateFormatToast-div').toast('hide');")
        show("#navbarContainer")
        show("#creationContainer")
        hide("#interfaceContainer")

        removeLeaveConfirmDialog()
    }
}
