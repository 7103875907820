package com.rabbitsign.common.util

import kotlinx.serialization.json.Json

// https://github.com/Kotlin/kotlinx.serialization/blob/master/docs/json.md#json-configuration

val lenientJson = Json { isLenient = true }

val unknownKeysSkippedJson = Json { ignoreUnknownKeys = true }

val defaultsEncodingJson = Json { encodeDefaults = true } // https://github.com/Kotlin/kotlinx.serialization/issues/1154

