package com.rabbitsign.web.filemanager

import com.rabbitsign.common.EMPTY_IMAGE
import com.rabbitsign.web.util.displayInfoMessage
import com.rabbitsign.web.util.displayWarningMessage
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.asList
import org.w3c.dom.url.URL
import org.w3c.files.FileList
import org.w3c.files.get

/**
 * updateSavedState: update whether the state has changed since it saved. false -> state has not changed
 */
class AccountPageFileManager(val updateChangeState: (Boolean) -> Unit): FileManager("RabbitSign-account-customBranding-logo") {
    private val logoPreview = document.getElementById("RabbitSign-account-customBranding-logo-logoPreview-img") as HTMLImageElement
    private var savedLogo: FileData<Any> = FileLink(logoPreview.src, "")  // fileName isn't used
    private var lastValidLogo: FileData<Any> = FileLink(logoPreview.src, "")

    private val noLogoDiv = document.getElementById("RabbitSign-account-customBranding-logo-noCustomLogo-div") as HTMLElement
    private val previewContainer = document.getElementById("RabbitSign-account-customBranding-logo-logoPreview-div") as HTMLElement
    private val deleteButton = document.getElementById("RabbitSign-account-customBranding-logo-delete-button") as HTMLButtonElement
    init {
        log.info("init with savedLogo=$savedLogo, lastValidLogo=$lastValidLogo")
        deleteButton.onclick = {
            fileList = mutableListOf()

            noLogoDiv.removeClass("hidden")
            previewContainer.addClass("hidden")
            deleteButton.addClass("hidden")

            lastValidLogo = FileLink(EMPTY_IMAGE, "")

            updateChangeState(lastValidLogo.file != savedLogo.file)

            Unit
        }
        logoPreview.onerror = { _, _, _, _, _ ->
            fileList = mutableListOf()
            if (logoPreview.src == lastValidLogo.toImgSrc()) {
                // this may happen if the browser needs to know the MIME type to render the image, since it's not currently provided
                // for example uploading an SVG would cause this problem. SVGs are currently not accepted as logos.
                lastValidLogo = FileLink(EMPTY_IMAGE, "")
            }

            logoPreview.src = lastValidLogo.toImgSrc()

            updateChangeState(lastValidLogo.file != savedLogo.file)

            displayWarningMessage(920, "Failed to render logo. Please check that your uploaded image is valid. If you believe this is an error, send an email to contact@rabbitsign.com")

            Unit
        }
        logoPreview.onload = {
            // files may be empty for the first onload call when the page loads. in this case lastValidLogo is already set to the correct value
            if (files.isNotEmpty()) {
                lastValidLogo = files[0]
            }

            if (logoPreview.src != EMPTY_IMAGE) {
                noLogoDiv.addClass("hidden")
                previewContainer.removeClass("hidden")
                deleteButton.removeClass("hidden")
            } else {
                noLogoDiv.removeClass("hidden")
                previewContainer.addClass("hidden")
                deleteButton.addClass("hidden")
            }

            updateChangeState(lastValidLogo.file != savedLogo.file)

            Unit
        }
    }
    override fun onFileSelected(files: FileList?) {
        log.info("onFileSelected with ${files?.length} file(s)")

        if (files == null || files.length == 0) {
            return
        }
        if (files.length > 1) {
            // Should never happen with the HTML input's multiple=false
            displayInfoMessage("Please select only 1 logo.")
            return
        }
        val file = files[0]!!
        if (file.type !in listOf("image/png", "image/jpeg", "image/gif")) {
            displayInfoMessage("Please select a PNG, JPEG, or GIF file.")
            return
        }
        logoPreview.src = URL.Companion.createObjectURL(file)
        fileList = files.asList().map { FileObject(it) }.toMutableList()

        // lastValidLogo will be updated in logoPreview.onload to ensure the uploaded logo is valid
    }

    private fun FileData<Any>.toImgSrc(): String {
        return when (this) {
            is FileLink -> this.file
            is FileObject -> URL.Companion.createObjectURL(this.file)
        }
    }

    fun handleSave(): FileData<Any> {
        savedLogo = lastValidLogo
        updateChangeState(false)
        return savedLogo
    }
}
