package com.rabbitsign.web.util

import com.rabbitsign.common.util.capitalizeWords
import kotlinx.browser.document
import kotlinx.dom.addClass
import org.w3c.dom.*


internal fun newFormControl(type: String, prefilled: String, smSize: Int, mdSize: Int = smSize, lgSize: Int = mdSize): HTMLDivElement {
    console.log("invoked newFormControl with type=$type, prefilled=$prefilled, smSize=$smSize, mdSize=$mdSize, lgSize=$lgSize")

    val containerDiv = document.createDiv("$type-container", "form-group", "col-sm-$smSize", "col-md-$mdSize", "col-lg-$lgSize")

    val label = document.createElement("label") as HTMLLabelElement
    label.addClass("w-100")
    label.textContent = type.replace('-', ' ').capitalizeWords()

    val input = document.createElement("input") as HTMLInputElement
    input.addClass("$type-input", "form-control", "mt-1")
    input.type = when (type) {
        "email" -> "email"
        "signing-order" -> "number".also { input.min = "1" }
        else -> "text"
    }
    input.value = prefilled
    input.required = true

    val invalidFeedback = document.createDiv("invalid-feedback")
    invalidFeedback.textContent = when (type) {
        "name" -> "Please enter a name."
        "email" -> "Please enter a valid email address."
        "role-name" -> "Please enter a role name."
        "signing-order" -> "Please enter a positive number."
        else -> {
            console.warn("input type $type is not name, email, role-name, or signing-order")
            ""
        }
    }

    containerDiv.appendChild(label)
    label.appendChild(input)
    label.appendChild(invalidFeedback)

    addValidityListeners(input)

    return containerDiv
}


internal fun deleteButtonHandler(button: HTMLButtonElement) {
    // this is ugly but at least it should be obvious if it breaks
    // we can't use IDs since this is used for both signers and roles
    val inputContainer = (button.parentElement as HTMLDivElement).parentElement as HTMLDivElement
    val formContainer = (inputContainer.parentElement) as HTMLFormElement
    if (formContainer.childElementCount == 1) {
        inputContainer.getElementsByTagName("input").asList().forEach {
            (it as HTMLInputElement).value = ""
        }
        unvalidateChildren(inputContainer)
        inputContainer.querySelectorAll(".custom-feedback").asList().forEach {
            (it as HTMLElement).addClass("hidden")
        }
    } else {
        inputContainer.remove()
    }
}

internal fun newDeleteButton(): HTMLDivElement {
    console.log("invoked newDeleteButton")

    val br = document.createElement("br")
    br.addClass("d-none", "d-sm-block")

    val deleteButton = document.createElement("button") as HTMLButtonElement
    deleteButton.addClass("btn", "btn-outline-secondary", "float-right", "mt-1")
    deleteButton.textContent = "Remove"
    deleteButton.type = "button"  // default type is "submit"
    deleteButton.onclick = { deleteButtonHandler(deleteButton) }

    val container = document.createDiv("form-group", "col-sm-2")
    container.appendChild(br)
    container.appendChild(deleteButton)

    return container
}
