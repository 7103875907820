package com.rabbitsign.web

import com.rabbitsign.common.Compliance
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLInputElement

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("CompliancePage")
object CompliancePage : RabbitSignPage() {
    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        for (compliance in listOf(Compliance.SOC2, Compliance.ISO27001)) {
            val termsCheckbox = document.getElementById(compliance.termsCheckboxId) as HTMLInputElement
            val downloadButton = document.getElementById(compliance.viewButtonId) as HTMLButtonElement
            downloadButton.disabled = !termsCheckbox.checked
            termsCheckbox.onchange = {
                downloadButton.disabled = !termsCheckbox.checked
                Unit
            }
            downloadButton.onclick = {
                MainScope().launch {
                    val complianceReportUrl = getApi().callGetComplianceReportUrl(compliance).url
                    log.info("opening compliance report for $compliance at $complianceReportUrl")
                    window.open(complianceReportUrl, target = "_blank")
                }
            }
        }
    }
}
