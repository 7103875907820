package com.rabbitsign.web

import com.rabbitsign.web.util.*
import kotlinx.browser.document
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.dom.addClass
import org.khronos.webgl.ArrayBuffer
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine
import kotlin.math.abs

const val MIN_WIDTH = 700.0

fun getDataUrl(file: File, action: (String) -> Unit) {
    console.log("invoked getDataUrl with file.name=${file.name}, action=$action")

    val reader = FileReader()
    reader.onload = { action(reader.result as String) }
    reader.onerror = { displayErrorMessage(902) }
    reader.readAsDataURL(file)
}

suspend fun File.toArrayBuffer(): ArrayBuffer {
    console.log("invoked File.toArrayBuffer with this.name=${this.name}")

    val reader = FileReader()
    reader.onerror = { displayErrorMessage(902) }

    return suspendCoroutine { continuation ->
        reader.onload = { continuation.resume(reader.result as ArrayBuffer) }
        reader.readAsArrayBuffer(this)
    }
}

// the fileData parameter should either have an empty ArrayBuffer and a url, or an ArrayBuffer and empty string
// note that the wrapper element may move around the DOM (or not even be in the DOM) after the PDFRenderer class is instantiated
class PDFRenderer(private val pages: List<PDFPageProxy>, private val wrapper: HTMLDivElement) {
    companion object val log = Logger(this::class.simpleName)

    val pdfScale = (MIN_WIDTH / pages.maxOf { it.getViewport(PageViewportParameters(scale = 1.0)).width}).coerceAtLeast(1.0)

    private val canvases = Array(pages.size) { page ->
        // retrieve current canvas or create new one
        wrapper.querySelector(".pdf-page[data-page='$page']") as HTMLCanvasElement?
            ?:
        (document.createElement("canvas") as HTMLCanvasElement).apply {
            addClass("pdf-page")
            this.page = page
            style.width = "${pages[page].getViewport(PageViewportParameters(scale = pdfScale)).width}px"
            style.height = "${pages[page].getViewport(PageViewportParameters(scale = pdfScale)).height}px"
        }.also { wrapper.appendChild(it) }
    }
    private val canvasLoaded = Array(pages.size) { false }

    // RenderTask source: https://github.com/mozilla/pdf.js/blob/07e233d08b0d5ab94007a6ba0bae9349711eff14/src/display/api.js#L3140
    private val renderTasks = MutableList<RenderTask?>(pages.size) { null }

    init {
        log.info("init with pages.size=${pages.size}, wrapper.id=${wrapper.id}")
        wrapper.style.setProperty("--scale", pdfScale.toString())
        wrapper.setAttribute("data-scale", pdfScale.toString())
    }

    fun cleanup() {
        for ((idx, canvas) in canvases.withIndex()) {
            canvasLoaded[idx] = false
            renderTasks[idx]?.cancel()
            (canvas.getContext("2d") as CanvasRenderingContext2D).clearCanvas()
            // set width and height to 0 to reduce memory usage
            // will be set back to correct values in renderPage
            canvas.width = 0
            canvas.height = 0
        }
    }

    fun renderPages(updateRender: Boolean) {
        val currentPageIdx = getCurrentPage()
        val renderRangeLow = (currentPageIdx - RENDER_RANGE_SIZE).coerceAtLeast(0)
        val renderRangeHigh = (currentPageIdx + RENDER_RANGE_SIZE).coerceAtMost(pages.size - 1)
        val renderRange = renderRangeLow..renderRangeHigh

//        log.info("renderPages with updateRender=$updateRender, renderRange=${renderRange}")

        for (pageIdx in renderRange) {
            retryUntilTrue { renderPage(pageIdx, updateRender) }
        }

        for ((idx, canvas) in canvases.withIndex()) {
            if (idx !in renderRange) {
                if (canvasLoaded[idx] || renderTasks[idx] != null) {
                    canvasLoaded[idx] = false
                    renderTasks[idx]?.cancel()
                    (canvas.getContext("2d") as CanvasRenderingContext2D).clearCanvas()
                    // set width and height to 0 to reduce memory usage
                    // will be set back to correct values in renderPage
                    canvas.width = 0
                    canvas.height = 0
                }
            }
        }
    }

    private fun renderPage(pageIdx: Int, updateRender: Boolean): Boolean {
        if (!canvasLoaded[pageIdx] || updateRender) {
            log.info("main part of renderPage with wrapper.data-doc-number=${wrapper.getAttribute("data-doc-number")}, pageIdx=$pageIdx, updateRender=$updateRender")
            val canvas = canvases[pageIdx]
            val ctx = canvas.getContext("2d") as CanvasRenderingContext2D
            val dpr = window.devicePixelRatio
            val viewport = pages[pageIdx].getViewport(PageViewportParameters(scale = pdfScale * dpr))
            if (renderTasks[pageIdx] == null) {
                canvas.setWidth(viewport.width)
                canvas.setHeight(viewport.height)
                createRenderTask(pageIdx, ctx, viewport)
            } else if (updateRender) {
                renderTasks[pageIdx]?.promise?.catch {
                    canvas.setWidth(viewport.width)
                    canvas.setHeight(viewport.height)
                    canvasLoaded[pageIdx] = false
                    createRenderTask(pageIdx, ctx, viewport)
                }
                renderTasks[pageIdx]?.cancel()
            }
        }
        return true
    }

    private fun createRenderTask(pageIdx: Int, ctx: CanvasRenderingContext2D, viewport: PageViewport) {
        renderTasks[pageIdx] = pages[pageIdx].render(RenderParameters(ctx, viewport))
        renderTasks[pageIdx]?.promise?.then {
            renderTasks[pageIdx] = null
            canvasLoaded[pageIdx] = true
            0
        }?.catch {  // catch RenderingCancelledException and set renderTasks[pageIdx] to false
            renderTasks[pageIdx] = null
            0
        }
    }

    private fun getCurrentPage(): Int {
//        log.info("getCurrentPage")
        val canvases = wrapper.querySelectorAll("canvas.pdf-page").asList().map { it as HTMLCanvasElement }
        var closest = Pair(0, Double.POSITIVE_INFINITY)
        for (canvas in canvases) {
            val canvasBottom = canvas.getBoundingClientRect().top + canvas.getBoundingClientRect().height
            if (abs(canvasBottom) < closest.second) {
                closest = Pair(canvas.page, abs(canvasBottom))
            }
        }
        return closest.first
    }
}

// TODO: merge the two versions of createPDFRenderer
suspend fun createPDFRenderer(fileData: ArrayBuffer, docWrapper: HTMLDivElement): PDFRenderer = suspendCoroutine { continuation ->
    console.log("invoked createPDFRenderer with fileData.byteLength=${fileData.byteLength}, docWrapper")

    // if this fails, make sure the pdfjs script is imported in the HTML
    val pdfjsLib = window["pdfjs-dist/build/pdf"]
    pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.10.111/legacy/build/pdf.worker.min.js"

    val pdfPromise = pdfjsLib.getDocument(fileData).promise
    pdfPromise.then({ pdf ->
        val pageCount = pdf.numPages as Int
        MainScope().launch {
            val pages = List(pageCount) { pageIdx ->
                suspendCoroutine { cont ->
                    pdf.getPage(pageIdx + 1).then { page ->
                        cont.resume(page)
                        0
                    }
                    Unit
                }
            }
            continuation.resume(PDFRenderer(pages, docWrapper))
        }
        0
    }, { reason ->
        console.error(reason)
        displayErrorMessage(
            908, "Sorry, something went wrong while trying to render this document. " +
                    "This might be because it is password-protected. " +
                    "If this is the case, you will need to remove the password protection."
        )
    })

    Unit
}

// fileData is usually a URL
suspend fun createPDFRenderer(fileData: String, docWrapper: HTMLDivElement): PDFRenderer = suspendCoroutine { continuation ->
    console.log("invoked createPDFRenderer with fileData[:30]=${fileData.substring(0 .. 30)}, fileData.len=${fileData.length}, docWrapper")

    val pdfjsLib = window["pdfjs-dist/build/pdf"]
    pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.10.111/legacy/build/pdf.worker.min.js"

    val pdfPromise = pdfjsLib.getDocument(fileData).promise
    pdfPromise.then({ pdf ->
        val pageCount = pdf.numPages as Int
        MainScope().launch {
            val pages = List(pageCount) { pageIdx ->
                suspendCoroutine { cont ->
                    pdf.getPage(pageIdx + 1).then { page ->
                        cont.resume(page)
                        0
                    }
                    Unit
                }
            }
            continuation.resume(PDFRenderer(pages, docWrapper))
        }
        0
    }, { reason ->
        console.error(reason)
        displayErrorMessage(
            909, "Sorry, something went wrong while trying to render this document. " +
                    "This might be because it is password-protected. " +
                    "If this is the case, you will need to remove the password protection."
        )
    })

    Unit
}
