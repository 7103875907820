package com.rabbitsign.web

import com.rabbitsign.common.DateFormat
import com.rabbitsign.common.FieldType
import com.rabbitsign.common.Name
import com.rabbitsign.common.SigningOptionToken
import com.rabbitsign.common.util.extractInitials
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement

const val IMAGE = "image"


class FieldFiller(name: Name, signingOptionToken: SigningOptionToken, private val getDateFormat: () -> DateFormat) {
    companion object val log = Logger(this::class.simpleName)

    private val signatureSigningOptionsHandler = SigningOptionsHandler(FieldType.SIGNATURE, signingOptionToken)
    private val initialsSigningOptionsHandler = SigningOptionsHandler(FieldType.INITIALS, signingOptionToken)
    init {
        log.info("init")
        initializeAllTooltips()

        signatureSigningOptionsHandler.initializeModal(name)
        initialsSigningOptionsHandler.initializeModal(name.extractInitials())
    }

    /**
     * Assigns onclick and onmousemove handlers. Causes signHandler to be invoked if the field is clicked without being dragged.
     */
    fun attachSigningHandlers(field: HTMLDivElement, signHandler: (HTMLDivElement) -> Unit = { fillField(it) }) {
        log.info("attachSigningHandlers with field.classList=${field.classList}, signHandler")
        field.onclick = {
            log.info("attachSigningHandlers.field.onclick")
            // check to see that the PDF has loaded and that field isn't being dragged or resized
            if (document.querySelector(".current-wrapper canvas.pdf-page") != null && !field.hasClass("moving")) {
                signHandler(field)
            }
            field.removeClass("moving")
        }
        field.onmousemove = {
            if (field.hasClass("dragging") || field.hasClass("resizing")) {
                field.addClass("moving")
            }
        }

        val questionButton = field.querySelector(".question-button") as HTMLElement?
        questionButton?.onclick = {
            it.stopPropagation()
        }
    }

    fun fillField(field: HTMLDivElement) {
        log.info("fillField with field.classList=${field.classList}")
        when {
            field.hasClass("fillable") && !field.hasClass("filled") -> {
                when (field.fieldType) {
                    FieldType.SIGNATURE -> transformIntoSigningOption(signatureSigningOptionsHandler, field)
                    FieldType.INITIALS -> transformIntoSigningOption(initialsSigningOptionsHandler, field)
                    FieldType.LOCAL_DATE -> transformIntoFilledText(
                        field,
                        "This is a signing date field. It is already filled out with the current date for you.",
                        getFormattedDateString(getDateFormat()), editable = false
                    )

                    FieldType.TEXTBOX -> transformIntoFilledText(
                        field,
                        "This is a textbox field. Click on the box to enter text.",
                        "", editable = true, "Text..."
                    )
                    FieldType.CHECKBOX -> transformIntoCheckbox(
                        field,
                        "This is a checkbox field. Click on the box to toggle the check mark."
                    )
                    else -> throw Error("FieldType not recognized, FieldType=${field.fieldType}")
                }
            }
            field.fieldType == FieldType.SIGNATURE -> signatureSigningOptionsHandler.openSigningOptionsModal(field) { updateFieldRemainingCount() }
            field.fieldType == FieldType.INITIALS -> initialsSigningOptionsHandler.openSigningOptionsModal(field) { updateFieldRemainingCount() }
        }
        updateFieldRemainingCount()
    }

    private fun transformIntoFilled(field: HTMLElement, newTooltip: String) {
        log.info("transformIntoFilled with field.classList=${field.classList}, newTooltip[:20]=${newTooltip.substring(0, 20)}")

        field.addClass("filled")

        // unfilled fields have background-color properties set individually, but filled fields use a CSS rule
        field.style.removeProperty("background-color")

        // update tooltip to show new text
        // questionButton only exists for viewFolder but not createFolder
        val questionButton = field.querySelector(".question-button") as HTMLElement?
        questionButton?.title = newTooltip
        // all tooltips need to be updated since only literal strings can be passed into the js() function
        initializeAllTooltips()
    }

    private fun transformIntoFilledText(field: HTMLElement, newTooltip: String, initValue: String, editable: Boolean, placeholder: String = "") {
        log.info("transformIntoFilledText with field.classList=${field.classList}, newTooltip[:20]=${newTooltip.substring(0, 20)}, initValue=$initValue, editable=$editable, placeholder=$placeholder")

        // remove the icon that was displayed
        field.querySelector(".content-container")!!.remove()

        transformIntoFilled(field, newTooltip)

        val content = document.createElement("input") as HTMLInputElement
        content.addClass("content")
        content.value = initValue

        if (editable) {
            content.placeholder = placeholder
            content.focus()
        } else {
            content.readOnly = true
        }
        content.setAttribute("spellcheck", "false")

        field.appendChild(content)  // input box must be part of the DOM before it can be auto-resized
        content.autoResize()
        field.resizeContentInputIfExists()
    }

    private fun transformIntoCheckbox(field: HTMLDivElement, newTooltip: String) {
        log.info("transformIntoCheckbox with field.classList=${field.classList}, newTooltip[:20]=${newTooltip.substring(0, 20)}")

        transformIntoFilled(field, newTooltip)

        field.setAttribute("data-checked", true.toString())
        attachSigningHandlers(field) {
            val checked = field.getAttribute("data-checked") == true.toString()
            field.setAttribute("data-checked", (!checked).toString())
        }
    }

    private fun transformIntoSigningOption(signingOptionsHandler: SigningOptionsHandler, field: HTMLElement) {
        log.info("transformIntoSigningOption with signingOptionsHandler, field=$field")

        val newTooltip = if (field.fieldType == FieldType.SIGNATURE) FILLED_SIGNATURE_TOOLTIP else FILLED_INITIALS_TOOLTIP

        when (signingOptionsHandler.default.first) {
            "" -> signingOptionsHandler.openSigningOptionsModal(field) {
                transformIntoFilled(field, newTooltip)
                updateFieldRemainingCount()
            }
            IMAGE -> {
                signingOptionsHandler.transformIntoSigningImage(field, signingOptionsHandler.default)
                transformIntoFilled(field, newTooltip)
            }
            else -> {
                signingOptionsHandler.transformIntoSigningText(field, signingOptionsHandler.default)
                transformIntoFilled(field, newTooltip)
            }
        }
    }

    fun updateFieldRemainingCount() {
        log.info("updateFieldRemainingCount")
        val remainingCount = document.querySelectorAll(".current-wrapper div.field.fillable:not(.filled)").length
        val remainingCountSpan = document.getElementById("RabbitSign-display-sign-remainingCount-span") ?: return
        remainingCountSpan.textContent = "$remainingCount field${if (remainingCount == 1) "" else "s"} left"

        val nextFieldButton = (document.getElementById("RabbitSign-display-sign-nextField-button") ?: return) as HTMLButtonElement
        nextFieldButton.disabled = remainingCount == 0
    }
}
