package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.*
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.*


data class TemplateLinkRole(val assigneeNum: Int, val roleName: Name, val signerName: String, val included: Boolean)

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("TemplateLinkPage")
object TemplateLinkPage : ViewingPage() {
    private val templateId = mainScriptElement.getAttribute("data-template-id")!!

    override fun runPage() {
        log.info("run with templateId=$templateId")

        initializeNavbar()

        addFormHandlers()

        val submitButton = document.getElementById("RabbitSign-templateLink-submit-button") as HTMLButtonElement
        submitButton.onclick = {
            log.info("run.submitButton.onclick")
            if (checkInputRoleValidity()) {
                val input = TemplateLinkInstantiation(createRoleInstantiation())

                addFullPageSpinnerAndLeaveDialog()

                MainScope().launch {
                    val folderId = getApi().callInstantiateTemplateLink(templateId, input)
                    log.info(folderId)

                    // no need to give Dynamo a head start, all info pulled is from pre-existing template
                    openPage("/template-link/instantiated/${templateId}")

                    // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                    Unit
                }
            }
        }

        val previewDocumentButton = document.getElementById("RabbitSign-templateLink-preview-button") as HTMLButtonElement
        previewDocumentButton.onclick = {
            MainScope().launch {
                val renderers = document.querySelectorAll(".doc-wrapper[data-file]").asList().map {
                    val elem = it as HTMLDivElement
                    async {
                        createPDFRenderer(elem.getAttribute("data-file")!!, elem)
                    }
                }
                TemplateLinkPDFDisplay(renderers.awaitAll(), fieldsManager).initialize()
            }

            val roleMap = getCurrentTemplateLinkRoles()
            document.querySelectorAll(".field").asList().map { it as HTMLDivElement }.forEach { field ->
                field.setAttribute("data-toggle", "tooltip")

                val roleName = field.getAttribute("data-assignee-id")
                val role = roleMap[roleName]!!
                val roleDisplayName = if (role.signerName.isNotEmpty()) "$roleName (${role.signerName})" else roleName
                field.setAssigneeBackgroundColor(role.assigneeNum)
                field.title = if (role.included) {
                    field.removeClass("disabled")
                    if (roleMap.size == 1) "This is ${field.fieldType.displayName} field."
                    else "This is ${field.fieldType.displayName} field, which will be assigned to $roleDisplayName."
                } else {
                    field.addClass("disabled")
                    if (roleMap.size == 1) "This is ${field.fieldType.displayName} field."
                    else "This is ${field.fieldType.displayName} field, which will <b>not</b> appear in the document because it was assigned to $roleDisplayName, which is an excluded role."
                }
            }

            initializeAllTooltips()

            hide("#navbarContainer")
            hide("main.page-container")
            show("#interfaceContainer")
        }
    }

    private fun getCurrentTemplateLinkRoles(): Map<String, TemplateLinkRole> {
        val roleContainers = document.querySelectorAll(".role-container").asList().map { it as HTMLElement }
        return roleContainers.mapIndexed { idx, it ->
            val roleName = it.getAttribute("data-assignee-id")!!
            val name = (it.querySelector(".name-input") as HTMLInputElement).value
            // index 0 is for fields that need to be signed now, so skip it
            roleName to TemplateLinkRole(idx + 1, roleName, name, it.hasClass("included-container"))
        }.toMap()
    }

    private fun addFormHandlers() {
        log.info("invoked addFormHandlers")
        val inputElements = document.querySelectorAll("input.form-control").asList()
        for (inputNode in inputElements) {
            addValidityListeners(inputNode as HTMLInputElement)
        }
        val textAreaElements = document.querySelectorAll("textArea.form-control").asList()
        for (textAreaNode in textAreaElements) {
            addValidityListeners(textAreaNode as HTMLTextAreaElement)
        }

        addIncludeButtonHandlers()
        addExcludeButtonHandlers()
    }

    private fun checkInputRoleValidity(): Boolean {
        log.info("checkInputRoleValidity")
        if (!checkInputValidity()) {
            return false
        }
        if (document.querySelector(".name-input:enabled") == null) {
            displayInfoMessage("You must include at least one role in the template.")
            return false
        }
        return true
    }

    private fun createRoleInstantiation(): RoleInstantiationMap {
        log.info("createRoleInstantiation")
        val roleMap = mutableMapOf<RoleName, RoleInstantiation>()
        val roleContainers = document.querySelectorAll(".role-container.included-container").asList()
        for (roleContainerNode in roleContainers) {
            val roleContainer = roleContainerNode as HTMLElement
            val roleName = roleContainer.getAttribute("data-assignee-id")!!

            val name = (roleContainer.querySelector(".name-input") as HTMLInputElement).value
            val email = (roleContainer.querySelector(".email-input") as HTMLInputElement).value.lowercase()
            // all emails treated as case-insensitive
            roleMap[roleName] = RoleInstantiation(email, name)
        }
        return roleMap
    }
}
