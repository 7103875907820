package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.common.DocumentType
import com.rabbitsign.web.filemanager.AccountPageFileManager
import com.rabbitsign.web.filemanager.FileLink
import com.rabbitsign.web.filemanager.FileObject
import com.rabbitsign.web.util.displayInfoMessage
import com.rabbitsign.web.util.initializeAllTooltips
import com.rabbitsign.web.util.removeLeaveConfirmDialog
import com.rabbitsign.web.util.setLeaveConfirmDialog
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import kotlin.time.Duration.Companion.seconds

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("AccountPage")
object AccountPage : RabbitSignPage() {
    private var otherSettingsChanged = false
        set(value) {
            if (value || logoFileChanged) setLeaveConfirmDialog()
            else removeLeaveConfirmDialog()
            field = value
        }
    private var logoFileChanged = false
        set(value) {
            if (otherSettingsChanged || value) setLeaveConfirmDialog()
            else removeLeaveConfirmDialog()
            field = value
        }

    private val fileManager = AccountPageFileManager { logoFileChanged = it }

    private var keyStokes = ""  // Captures characters typed on this page (to activate the developer mode)

    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        initializeAllTooltips()

        // initialize the date format and signer notif lang selects using the chosen library
        js("""$("#RabbitSign-account-dateFormat-select").chosen({disable_search_threshold: 12, width: "15em"})""")
        js("""$("#RabbitSign-account-signerNotifLang-select").chosen({disable_search_threshold: 12, width: "6em"})""")

        // set the confirm dialog once the dateFormatSelect is changed by the user
        // the dialog will be removed when the user saves
        val dateFormatSelect = document.getElementById("RabbitSign-account-dateFormat-select") as HTMLSelectElement
        val notifyOnViewCheckbox = document.getElementById("RabbitSign-account-notifyOnViewCheckbox-input") as HTMLInputElement
        val notifyOnSignCheckbox = document.getElementById("RabbitSign-account-notifyOnSignCheckbox-input") as HTMLInputElement
        val signerNotifLangSelect = document.getElementById("RabbitSign-account-signerNotifLang-select") as HTMLSelectElement
        listOf(dateFormatSelect, notifyOnViewCheckbox, notifyOnSignCheckbox, signerNotifLangSelect).forEach {
            it.onchange = { otherSettingsChanged = true; Unit }
        }

        val developerDiv = document.getElementById("RabbitSign-account-developer-div") as HTMLElement
        if (developerDiv.hasClass("hidden"))
            document.onkeypress = {
                keyStokes += it.key
                if (keyStokes.lowercase().contains("opensesame")) {
                    MainScope().launch {
                        getApi().callEnableDevMode()
                        developerDiv.removeClass("hidden")
                        developerDiv.scrollIntoView(ScrollIntoViewOptions(behavior = ScrollBehavior.SMOOTH))
                        delay(1.seconds)
                        displayInfoMessage("RabbitSign Developer Console enabled!") // Delay this to finish scrolling
                    }
                }
            }

        val saveButton = document.getElementById("RabbitSign-account-save-button") as HTMLElement
        saveButton.onclick = {
            log.info("run.saveButton.onclick")
            val dateFormat = DateFormat.fromPattern(dateFormatSelect.value)
            val notifyOnView = notifyOnViewCheckbox.checked
            val notifyOnSign = notifyOnSignCheckbox.checked
            val signerNotifLang = IsoLangCode.valueOf(signerNotifLangSelect.value)
            val isDeveloper = ! developerDiv.hasClass("hidden")

            val logoFileData = fileManager.handleSave()

            MainScope().launch {
                val logoUrl = when (logoFileData) {
                    is FileLink -> if (logoFileData.file == EMPTY_IMAGE) "" else logoFileData.file
                    is FileObject -> getApi().callUploadFile(logoFileData.file, DocumentType.USER_LOGO).uploadUrl
                }

                val userSettings = UserSettings(
                    dateFormat = dateFormat,
                    notifyUponDocSigning = notifyOnSign, notifyUponDocViewing = notifyOnView,
                    shareLink = "DEPRECATED", shareLinkReferer = "DEPRECATED",
                    showAds = false,
                    signerNotifLang = signerNotifLang,
                    logoUrl = logoUrl,
                    isDeveloper = isDeveloper
                )
                getApi().callSaveUserSettings(userSettings)
                js("$('#savedToast').toast('show')")

                otherSettingsChanged = false
                logoFileChanged = false
            }
        }
    }
}
