package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.web.util.initializeAllTooltips
import com.rabbitsign.web.util.show
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import kotlin.time.Duration
import kotlin.time.Duration.Companion.seconds

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("DeveloperPage")
object DeveloperPage : RabbitSignPage() {
    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        initializeAllTooltips()

        initializeKeysSection()
        initializeWebhookSection()
    }

    private fun initializeKeysSection() {
        val keySecretToggles = document.getElementsByClassName("dev-key-secret-toggle")
        for (t in keySecretToggles.asList()) {
            val toggle = t as HTMLElement
            val idx = toggle.getAttribute("data-rs-toggle-idx")
            val keySecretText = document.getElementById("RabbitSign-dev-key-secret-text-$idx") as HTMLElement
            val keySecretMask = document.getElementById("RabbitSign-dev-key-secret-mask-$idx") as HTMLElement
            toggle.onclick = {
                log.info("run.toggleKeySecretVisibility.onclick")
                if (keySecretText.hasClass("hidden")) {
                    keySecretText.removeClass("hidden")
                    keySecretMask.addClass("hidden")
                } else {
                    keySecretText.addClass("hidden")
                    keySecretMask.removeClass("hidden")
                }
            }

            val trashButton = document.getElementById("RabbitSign-dev-key-delete-$idx") as HTMLElement
            val keyId = trashButton.getAttribute("data-rs-trash-key-id")
            trashButton.onclick = {
                log.info("run.trashButton.onclick")

                if (window.confirm("Are you sure you want to delete this key?")) {
                    MainScope().launch {
                        getApi().callDeleteDevKey(keyId!!)
                        spin(3.seconds)
                        window.location.reload()  // Refresh the page
                    }
                }
            }
        }

        val newKeyButton = document.getElementById("RabbitSign-dev-key-new-button") as HTMLElement
        if (keySecretToggles.length < 3)
            newKeyButton.onclick = {
                log.info("run.newKeyButton.onclick")

                val keyName = window.prompt("Please name your key")
                log.debug("keyName: $keyName")
                if (keyName == null)
                    log.debug("Canceled")
                else
                    MainScope().launch {
                        getApi().callCreateDevKey(ApiKeyName(keyName))
                        spin(3.seconds)
                        window.location.reload()  // Display the new key by refreshing the page
                    }
            }
        else
            newKeyButton.addClass("disabled")
    }

    private fun initializeWebhookSection() {
        val webhookSaveButton = document.getElementById("RabbitSign-dev-console-webhook-url-save-button") as HTMLElement
        webhookSaveButton.onclick = {
            log.info("run.webhookSaveButton.onclick")
            val webhookUrlInput =
                document.getElementById("RabbitSign-dev-console-webhook-url-input") as HTMLInputElement

            MainScope().launch {
                log.debug("Webhook: ${webhookUrlInput.value}")
                getApi().callSaveWebhookUrl(Webhook(webhookUrlInput.value))
                js("$('#savedToast').toast('show')")
            }
        }
    }
}

private suspend fun spin(duration: Duration) {
    show("#spinnerOverlay")
    show("#fullPageSpinner")
    delay(duration)
}