package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.w3c.dom.*
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import kotlin.math.max

/*
Each field element's behavior is determined by the CSS classes it has.
"signature-field", "initials-field", "local_date-field", "textbox-field", "checkbox-field" all denote field type
"draggable" means the field can be dragged/repositioned by the user
"dragging" means the field is currently being dragged by the user and will follow the cursor
"focused" means the field is currently highlighted on the page and can be moved with arrow keys
"resizable" means the field size can be changed by the user; doesn't include field expansion from text overflow
"resizing" means the field size is currently being changed by the user
"fillable" means the field can be filled/signed
"filled" means the field has already been filled/signed
"deletable" means the field can be removed/deleted
"disabled" means the field is hidden and should not be sent in the API request
 */

/**
 * Handles user interactions with fields that are specific to CreationPage (essentially everything except filling the fields)
 */
class CreationFieldsManager(private val fieldFiller: FieldFiller) : FieldsManager(fieldFiller) {
    private var draggingField: HTMLDivElement? = null
        set(value) {
            draggingField?.removeClass("dragging")
            value?.addClass("dragging")
            if (value != null) focusedField = value
            field = value
        }
    private var focusedField: HTMLDivElement? = null
        set(value) {
            focusedField?.removeClass("focused")
            value?.addClass("focused")
            val updateRequired = field != value
            field = value

            if (updateRequired) updateFieldInfoDisplay()
        }
    private var moveOffsetX: Double? = 0.0
    private var moveOffsetY: Double? = 0.0

    private val defaultFieldHeight = FieldType.values().associateWith { if (it == FieldType.CHECKBOX) it.initWidth else FIELD_HEIGHT }.toMutableMap()
    private val defaultFieldWidth = FieldType.values().associateWith { it.initWidth }.toMutableMap()
    private val defaultFieldFontSize = FieldType.values().associateWith {
        if (it == FieldType.CHECKBOX) checkmarkSizeFromFieldWidth(it.initWidth) else FRONTEND_DEFAULT_FONT_SIZE
    }.toMutableMap()

    init {
        document.addEventListener("mousemove", {
            if (interfaceHidden()) return@addEventListener
            val event = it as MouseEvent
            draggingField?.moveFieldTo(shiftX(event.pageX), shiftY(event.pageY), false)
        })
        document.addEventListener("keydown", {
            if (interfaceHidden()) return@addEventListener
            val event = it as KeyboardEvent? ?: return@addEventListener  // using browser autofill triggers keydown with regular Event, not KeyboardEvent
            handleKeyDown(event)
        })

        initializeResizing()
        initializeFieldInfoDisplay()
        document.querySelectorAll("div.field").asList().forEach {
            addHandlersAndButtonsByClass(it as HTMLDivElement)
        }
    }
    fun prepareCurrentPage() {
        log.info("prepareCurrentPage")
        updateFieldInfoDisplay()
        draggingField?.remove()
        draggingField = null
        focusedField = null

        // update data-assignee-id values; assignee list may have changed if the user hit cancel and made edits to the list
        updateDataAssigneeId()
        updateFieldRemainingCount()
    }

    private fun initializeResizing() {
        // handle dragging for resizing
        document.addEventListener("mouseup", {
            if (interfaceHidden()) return@addEventListener
            log.info("initializeResizing.document.mouseup")
            for (resizingElem in document.querySelectorAll(".resizing").asList()) {
                (resizingElem as HTMLElement).removeClass("resizing")
            }
        })
        document.addEventListener("mousemove", {
            if (interfaceHidden()) return@addEventListener
            val wrapper = document.querySelector(".current-wrapper")?: return@addEventListener
            val event = it as MouseEvent
            if (event.clientX < wrapper.getBoundingClientRect().right) {
                for (fieldNode in document.getElementsByClassName("resizing").asList()) {
                    (fieldNode as HTMLDivElement).resize(event.clientX, event.clientY)
                }
            }
        })
    }

    private fun HTMLDivElement.getMaxWidth(): Double {
        val viewportRect = getViewportRect()
        val distanceToWrapperBorder = viewportRect.width - (this.getBoundingClientRect().left - viewportRect.left)
        return distanceToWrapperBorder / getScale() - 2  // subtract border width
    }

    private fun HTMLDivElement.resize(clientX: Int, clientY: Int) {
        val maxWidth = this.getMaxWidth()

        val unscaledTargetWidth = clientX - this.getBoundingClientRect().left
        if (this.fieldType.aspectRatio != NO_ASPECT_RATIO) {
            val unscaledTargetHeight = clientY - this.getBoundingClientRect().top
            val widthInput = max(unscaledTargetWidth, unscaledTargetHeight * this.fieldType.aspectRatio) / getScale()

            // don't allow resizing past right page border
            val newWidth = widthInput.coerceIn(this.minFieldWidth, maxWidth)
            // resizing past bottom page border is still possible but more difficult

            this.fieldHeight = newWidth / this.fieldType.aspectRatio
            this.fieldWidth = newWidth

            this.fieldFontSize =
                if (this.fieldType == FieldType.CHECKBOX) checkmarkSizeFromFieldWidth(this.fieldWidth)
                else fontSizeFromFieldHeight(this.fieldHeight)

            getFontSizeInput().valueAsNumber = this.fieldFontSize.round(2)
        } else {
            val widthInput = unscaledTargetWidth / getScale()
            this.fieldWidth = widthInput.coerceIn(this.minFieldWidth, maxWidth)
        }
        getFieldWidthInput().valueAsNumber = this.fieldWidth.round(2)

        this.coerceIntoViewport()
        this.updateFieldInputValues()
    }

    private fun initializeFieldInfoDisplay() {
        val fontSizeInput = getFontSizeInput()
        val fieldWidthInput = getFieldWidthInput()
        val fieldNameInput = getFieldNameInput()

        fontSizeInput.oninput = {
            if (fontSizeInput.value.isNotEmpty() && fontSizeInput.checkValidity()) {
                fontSizeInput.removeClass("is-invalid")

                focusedField!!.changeFontSize(fontSizeInput.valueAsNumber)
                focusedField!!.coerceIntoViewport()
                focusedField!!.updateFieldInputValues()
            } else {
                fontSizeInput.addClass("is-invalid")
            }
            Unit
        }
        fieldWidthInput.oninput = {
            if (fieldWidthInput.value.isNotEmpty() && fieldWidthInput.checkValidity()) {
                fieldWidthInput.removeClass("is-invalid")

                focusedField!!.changeWidth(fieldWidthInput.valueAsNumber)
                focusedField!!.coerceIntoViewport()
                focusedField!!.updateFieldInputValues()
            } else {
                fieldWidthInput.addClass("is-invalid")
            }
            Unit
        }

        fieldNameInput.oninput = { // Don't use onblur since it's not triggered upon dragging in a new field
            checkNameUniqueness(fieldNameInput, 0)

            val value = fieldNameInput.value
            focusedField!!.setAttribute("data-field-name", value)  // uniqueness enforced upon submitting in saveFields
            focusedField!!.fieldName = value
            Unit
        }
    }

    private fun checkNameUniqueness(fieldNameInput: HTMLInputElement, expectedCount: Int) {
        val value = fieldNameInput.value
        val list = document.querySelectorAll("""[data-field-name="$value"]""").asList()
        console.info("Number of elements with field-name $value: ${list.size}")
        if (list.size == expectedCount)
            fieldNameInput.removeClass("is-invalid")
        else
            fieldNameInput.addClass("is-invalid")
    }

    // does not check if font size is too big
    private fun HTMLDivElement.changeFontSize(newFontSize: Double) {
        this.fieldFontSize = newFontSize

        if (this.fieldType.aspectRatio != NO_ASPECT_RATIO) {
            this.fieldHeight =
                if (this.fieldType == FieldType.CHECKBOX) fieldWidthFromCheckmarkSize(newFontSize)
                else fieldHeightFromFontSize(newFontSize)
            val newWidth = this.fieldHeight * this.fieldType.aspectRatio

            this.fieldWidth = newWidth
        } else {
            this.fieldHeight = fieldHeightFromFontSize(newFontSize)
            this.fieldWidth = this.fieldWidth.coerceAtLeast(this.minFieldWidth)

            val roundedMinWidth = this.minFieldWidth.round(2)
            val minWidthSpan = document.getElementById("minFieldWidthSpan") as HTMLElement
            minWidthSpan.textContent = roundedMinWidth.toString()
        }
    }

    // does not check if field width is too big
    private fun HTMLDivElement.changeWidth(newWidth: Double) {
        this.fieldWidth = newWidth

        if (this.fieldType.aspectRatio != NO_ASPECT_RATIO) {
            val newHeight = newWidth / this.fieldType.aspectRatio
            val newFontSize =
                if (this.fieldType == FieldType.CHECKBOX) checkmarkSizeFromFieldWidth(newWidth)
                else fontSizeFromFieldHeight(newHeight)
            this.fieldFontSize = newFontSize
            this.fieldHeight = newHeight
        }
    }

    private fun addHandlersAndButtonsByClass(field: HTMLDivElement) {
        if (field.hasClass("fillable")) fieldFiller.attachSigningHandlers(field)
        if (field.hasClass("draggable")) field.onmousedown = { reactivate(it) }
        if (field.hasClass("deletable")) {
            val deleteFollowerButton = createDeleteButton()
            deleteFollowerButton.addClass("red-delete-button", "top-right-button")
            deleteFollowerButton.onclick = {
                log.info("addHandlersAndButtonsByClass.deleteFollowerButton.onclick")
                it.stopPropagation()

                field.remove()
                if (field == draggingField) draggingField = null
                if (field == focusedField) focusedField = null
            }
            field.appendChild(deleteFollowerButton)
        }
        if (field.hasClass("resizable")) {
            val resizeButton = document.createDiv(
                "resize-button",
                if (field.fieldType.aspectRatio != NO_ASPECT_RATIO) "diagonal-resize" else "horizontal-resize"
            )
            resizeButton.onmousedown = {
                log.info("addHandlersAndButtonsByClass.resizeButton.onmousedown")
                it.stopPropagation()
                field.addClass("resizing")
            }
            resizeButton.onclick = {
                log.info("addHandlersAndButtonsByClass.resizeButton.onclick")
                it.stopPropagation()
            }
            field.appendChild(resizeButton)
        }
    }

    private fun updateFieldInfoDisplay() {
        log.info("updateFieldInfoDisplay with focusedField.classList=${focusedField?.classList}")
        val detailsElem = document.getElementById("RabbitSign-display-prepDoc-fieldDetailsContainer-div") as HTMLElement
        if (focusedField == null) {
            detailsElem.addClass("d-none")
            return
        }

        detailsElem.removeClass("d-none")
        val dateFormatContainer = document.getElementById("dateFormatContainer") as HTMLElement
        if (focusedField!!.fieldType == FieldType.LOCAL_DATE) {
            dateFormatContainer.removeClass("d-none")
        } else {
            dateFormatContainer.addClass("d-none")
        }

        focusedField!!.updateFieldInputValues()
    }

    private fun HTMLDivElement.updateFieldInputValues() {
        val fontSizeInput = getFontSizeInput()
        val fieldWidthInput = getFieldWidthInput()
        val fieldNameInput = getFieldNameInput()

        // remove invalid feedback classes if present
        fontSizeInput.removeClass("is-invalid")
        fieldWidthInput.removeClass("is-invalid")

        val minFontSizeSpan = document.getElementById("minFontSizeSpan") as HTMLElement
        if (this.fieldType == FieldType.CHECKBOX) {
            fontSizeInput.min = checkmarkSizeFromFieldWidth(FieldType.CHECKBOX.minWidth).round(2).toString()
            minFontSizeSpan.textContent = checkmarkSizeFromFieldWidth(FieldType.CHECKBOX.minWidth).round(2).toString()
        } else {
            fontSizeInput.min = "8"
            minFontSizeSpan.textContent = "8"
        }
        val currentFontSize = this.fieldFontSize
        fontSizeInput.valueAsNumber = currentFontSize.round(2)

        val roundedMinWidth = this.minFieldWidth.round(2)
        val minWidthSpan = document.getElementById("minFieldWidthSpan") as HTMLElement
        minWidthSpan.textContent = roundedMinWidth.toString()
        fieldWidthInput.valueAsNumber = this.fieldWidth.round(2)
        fieldWidthInput.min = roundedMinWidth.toString()

        defaultFieldWidth[this.fieldType] = this.fieldWidth
        defaultFieldHeight[this.fieldType] = this.fieldHeight
        defaultFieldFontSize[this.fieldType] = this.fieldFontSize

        fieldNameInput.value = this.fieldName
        checkNameUniqueness(fieldNameInput, 1)
    }

    private fun updateDataAssigneeId() {
        log.info("updateDataAssigneeId")
        val assigneeSelect = document.getElementById("RabbitSign-display-prepDoc-assignee-select") as HTMLSelectElement
        val assigneeIds = assigneeSelect.options.asList().map { it.getAttribute("data-assignee-id")!! }
        document.querySelectorAll("div.current-wrapper div.field").asList().forEach {
            val field = it as HTMLDivElement
            field.removeClass("disabled")  // some fields may have been disabled earlier

            val assigneeTmpId = field.getAttribute("data-assignee-id")!!
            val assigneeNum = assigneeIds.indexOf(assigneeTmpId)
            if (assigneeNum == -1) field.addClass("disabled")  // assignee was excluded, so disable their field
            else field.setAssigneeBackgroundColor(assigneeNum)
        }
    }

    fun createFieldFromEvent(fieldType: FieldType, fillable: Boolean, event: MouseEvent) =
        newFieldFollower(fieldType, fillable, shiftX(event.pageX), shiftY(event.pageY))

    private fun handleKeyDown(e: KeyboardEvent) {
        log.info("handleKeyDown with key=${e.key}, shiftKey=${e.shiftKey}")
        if ((e.target as HTMLElement).tagName.equals("INPUT", ignoreCase = true)) {  // user is trying to type into an input box
            return
        }

        when (e.key) {
            "Backspace", "Delete" -> {
                if (focusedField?.hasClass("deletable") == true) {
                    focusedField?.remove()
                    focusedField = null
                }
            }
            "Escape" -> {
                if (draggingField?.hasClass("deletable") == true) {
                    draggingField?.remove()
                    draggingField = null
                }
                else focusedField = null
            }
            "ArrowUp", "ArrowLeft", "ArrowDown", "ArrowRight" -> {
                val mult = if (e.shiftKey) 1 else 5
                val (changeX, changeY) = when (e.key) {
                    "ArrowUp" -> Pair(0.0, -1.0 * mult)
                    "ArrowLeft" -> Pair(-1.0 * mult, 0.0)
                    "ArrowDown" -> Pair(0.0, 1.0 * mult)
                    "ArrowRight" -> Pair(1.0 * mult, 0.0)
                    else -> Pair(0.0, 0.0)
                }
                if (focusedField != null) {
                    val newX = (focusedField!!.fieldPosX + changeX) * getScale()
                    val newY = (focusedField!!.fieldPosPageY + changeY) * getScale()

                    moveOffsetX = 0.0
                    moveOffsetY = 0.0
                    focusedField?.moveFieldTo(newX, newY, false)
                    e.preventDefault()
                }
            }
        }
        updateFieldInfoDisplay()
    }

    private fun getFirstPage() = document.querySelector("div.current-wrapper canvas.pdf-page[data-page=\"0\"]")

    private fun shiftX(x: Double): Double {
        val firstCanvas = getFirstPage() as HTMLCanvasElement
        val offsetX = firstCanvas.getBoundingClientRect().x
        return x - offsetX
    }

    private fun shiftY(y: Double): Double {
        val firstCanvas = getFirstPage() as HTMLCanvasElement
        val offsetY = firstCanvas.getBoundingClientRect().y
        return y - offsetY
    }

    private fun newFieldFollower(fieldType: FieldType, fillable: Boolean, initX: Double, initY: Double): HTMLDivElement {
        log.info("newFieldFollower with fieldType=$fieldType, fillable=$fillable, x=$initX, y=$initY")

        draggingField?.remove()
        draggingField = null

        val field = document.createDiv("field", "${fieldType.toString().lowercase()}-field", "draggable", "deletable", "resizable")
        if (fillable) field.addClass("fillable")
        field.fieldType = fieldType
        field.fieldWidth = defaultFieldWidth[fieldType]!!
        field.fieldHeight = defaultFieldHeight[fieldType]!!
        field.fieldFontSize = defaultFieldFontSize[fieldType]!!
        field.fieldName = generateUniqueFieldName()

        val assigneeSelect = document.getElementById("RabbitSign-display-prepDoc-assignee-select") as HTMLSelectElement
        field.setAssigneeBackgroundColor(assigneeSelect.selectedIndex)
        field.setAttribute("data-assignee-id", assigneeSelect[assigneeSelect.selectedIndex]!!.getAttribute("data-assignee-id")!!)

        val contentContainer = document.createDiv("content-container", "icon-container")

        val typeIcon = document.createElement("i") as HTMLElement
        typeIcon.addClass("bi", fieldType.icon)
        contentContainer.appendChild(typeIcon)

        field.appendChild(contentContainer)

        if (fieldType == FieldType.LOCAL_DATE) {
            val dateFormatSelect = document.getElementById("RabbitSign-display-prepDoc-dateFormat-select") as HTMLSelectElement
            val description = document.createElement("span") as HTMLSpanElement
            description.addClass("date-format")
            description.textContent = dateFormatSelect.value
            contentContainer.appendChild(description)
        }

        document.querySelector("div.current-wrapper")!!.appendChild(field)

        addHandlersAndButtonsByClass(field)
        if (fillable) {
            // besides signing when field is clicked, also sign when field is first dropped
            field.onmouseup = {
                if (!field.hasClass("filled")) fieldFiller.fillField(field)
                field.onmouseup = {}  // overwrite this handler once field is dropped
                Unit
            }
        }

        field.moveFieldTo(initX, initY, true)
        draggingField = field

        return field
    }

    private fun generateUniqueFieldName(): String {
        for (i in 1..10000) {
            if (document.querySelectorAll("[data-field-name='field-$i']").length == 0) return "field-$i"
        }

        // This shouldn't happen
        displayErrorMessage(930, "Unable to generate field name automatically. Please rename the field manually.")
        return "field"
    }

    private fun getViewportRect(): DOMRect {
        val currentCanvases = document.querySelectorAll(".current-wrapper canvas.pdf-page").asList().map { it as HTMLElement }
        val left = currentCanvases.minOf { it.getBoundingClientRect().left }
        val right = currentCanvases.maxOf { it.getBoundingClientRect().right }
        val top = currentCanvases.minOf { it.getBoundingClientRect().top }
        val bottom = currentCanvases.maxOf { it.getBoundingClientRect().bottom }
        val width = right - left
        val height = bottom - top
        return DOMRect(left, top, width, height)
    }

    private fun HTMLDivElement.coerceIntoViewport() {
        val viewportRect = getViewportRect()
        if (viewportRect.width < this.width) {
            this.changeWidth(this.getMaxWidth())
            if (this.fieldType.aspectRatio != NO_ASPECT_RATIO) {
                this.fieldHeight = this.fieldWidth / this.fieldType.aspectRatio
                this.fieldFontSize =
                    if (this.fieldType == FieldType.CHECKBOX) checkmarkSizeFromFieldWidth(this.fieldWidth)
                    else fontSizeFromFieldHeight(this.fieldHeight)
            } else {
                while (this.minFieldWidth > this.fieldWidth) this.fieldFontSize--
                this.fieldHeight = fieldHeightFromFontSize(this.fieldFontSize)
            }
            this.changeFontSize(this.fieldFontSize)
        }
    }

    private fun HTMLDivElement.moveFieldTo(pageX: Double, pageY: Double, resetOffsets: Boolean) {
        if (resetOffsets) {
            moveOffsetX = null
            moveOffsetY = null
        }

//    log.info("moveFieldTo with pageX=$pageX, pageY=$pageY, resetOffsets=$resetOffsets, moveOffsetX=$moveOffsetX, moveOffsetY=$moveOffsetY")
        val boundingRect = this.getBoundingClientRect()
        val targetX = pageX - (moveOffsetX ?: (boundingRect.width / 2)) - window.scrollX
        val targetY = pageY - (moveOffsetY ?: (boundingRect.height / 2)) - window.scrollY

        val viewportRect = getViewportRect()
        val newX = targetX.coerceIn(0.0, viewportRect.width - this.width)
        val newY = targetY.coerceIn(0.0, viewportRect.height - this.height)

        this.coerceIntoViewport()

        this.fieldPosX = newX / getScale()
        this.fieldPosPageY = newY / getScale()

        this.page = getPageFromClientPosition(boundingRect.x, boundingRect.y)
        if (this.page != -1) {
            val firstPdfPageY = (document.querySelector("div.current-wrapper canvas.pdf-page[data-page=\"0\"]") as HTMLElement).getBoundingClientRect().y
            val currentPdfPageY =
                (document.querySelector("div.current-wrapper canvas.pdf-page[data-page=\"${this.page}\"]") as HTMLElement).getBoundingClientRect().y
            val pageOffset = (currentPdfPageY - firstPdfPageY) / getScale()
            this.fieldPosY = this.fieldPosPageY - pageOffset
        }

        this.style.left = newX.toString() + "px"
        this.style.top = newY.toString() + "px"
    }

    fun deactivate(event: MouseEvent) {
        log.info("deactivate with event.clientX=${event.clientX}, event.clientY=${event.clientY}")

        val pageIdx = getPageFromClientPosition(event.clientX.toDouble(), event.clientY.toDouble())
        if (pageIdx == -1) return

        if (draggingField == null) focusedField = null
        draggingField?.page = pageIdx
        draggingField = null
    }

    private fun reactivate(event: MouseEvent) {
        val field = event.currentTarget as HTMLDivElement
        val target = event.target as HTMLElement
        log.info("reactivate with field.classList=${field.classList}, target.classList=${target.classList}")

        if (field != draggingField && !target.isCloseButton()) {
            moveOffsetX = event.offsetX
            moveOffsetY = event.offsetY
            draggingField = field
        }
    }

    private fun interfaceHidden() = document.getElementById("interfaceContainer")?.hasClass("hidden") ?: true

    private fun getPageFromClientPosition(x: Double, y: Double): Int {
        for (canvasNode in document.querySelectorAll("canvas.pdf-page").asList()) {
            val canvas = canvasNode as HTMLCanvasElement
            val canvasPos = canvas.getBoundingClientRect()
            val relativeX = x - canvasPos.x
            val relativeY = y - canvasPos.y
            if (0 <= relativeX && relativeX < canvasPos.width && 0 <= relativeY && relativeY < canvasPos.height) {
                return canvas.page
            }
        }

        return -1
    }

    override fun goToNextField(): HTMLDivElement? {
        log.info("goToNextField")
        val newField = super.goToNextField()
        if (newField != null) focusedField = newField
        return newField
    }

    // The element IDs below for Font Size, Field Width and Field Name are defined in
    // createRightSigningSidebar in ViewerPageCreator.kt in RabbitSignServer
    private fun getFontSizeInput() = document.getElementById("RabbitSign-display-prepDoc-fontSize-input") as HTMLInputElement
    private fun getFieldWidthInput() = document.getElementById("RabbitSign-display-prepDoc-fieldWidth-input") as HTMLInputElement

    private fun getFieldNameInput() = document.getElementById("RabbitSign-display-prepDoc-fieldName-input") as HTMLInputElement
}
