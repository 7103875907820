package com.rabbitsign.common

import kotlinx.serialization.Serializable

typealias RoleName = String
typealias RoleMap = Map<RoleName, SignerFields>
typealias RoleInfoMap = Map<RoleName, RoleInfo>
typealias SenderFieldList = List<FieldSpec>
typealias RoleInstantiationMap = Map<RoleName, RoleInstantiation>

@Serializable
data class Template(
    val title: String, val summary: String, val docInfo: List<DocInfo>,
    val signerInfo: RoleMap, val roleInfo: RoleInfoMap = emptyMap(),
    val senderFields: SenderFieldList,  // signingOrder does NOT apply to senderFields
    val dateFormat: DateFormat = DateFormat.MM_DD_YYYY_SLASH,
    val ccList: List<Email> = emptyList()
)

@Serializable
data class RoleInfo(val signingOrder: Int)

@Serializable
data class RoleInstantiation(
    val email: Email, val name: Name
)

@Serializable
data class TemplateInstantiation(
    val roles: RoleInstantiationMap,
    val senderFieldValues: SenderFieldList,
    val title: String, val summary: String,  // Allow changing title/summary optionally
    val date: String,  // Used to instantiate sender's local date fields (if any)
    val ccList: List<Email> = emptyList()
)

@Serializable
data class DevApiFieldValue(
    val name: String,  // Must match the name in FieldSpec in the template's senderFieldValues
    val currentValue: String,
    val signingOptionId: String = ""  // If not empty, insert the image/text specified by this instead of currentValue
)

@Serializable
// Similar to TemplateInstantiation, but
// the senderFieldValues have only the name-value pairs, not the full FieldSpec
data class DevApiTemplateInstantiation(
    val roles: RoleInstantiationMap,
    val senderFieldValues: List<DevApiFieldValue>,
    val title: String, val summary: String,  // Allow changing title/summary optionally
    val date: String,  // Used to instantiate sender's local date fields (if any)
    val ccList: List<Email> = emptyList()
)

// For bulk/batch send
@Serializable
data class TemplateInstantiationBatch(
    val name: String,  // name of the batch provided by user
    val instantiationList : List<DevApiTemplateInstantiation>
)

@Serializable
data class TemplateLinkInstantiation(
    val roles: RoleInstantiationMap
)

const val NOT_APPLICABLE = "N/A"

@Serializable
data class TemplateId(val templateId: String)

@Serializable
data class TemplateParams(val senderFieldNames: List<String>, val roleNames: List<RoleName>)

//fun main() {
//    val roles: RoleInstantiationMap = mapOf("Role1" to RoleInstantiation("luckypython1@gmail.com", "Lucky Python"))
//    val ti0 = DevApiTemplateInstantiation(roles, emptyList(), "", "", "2024-04-20")
//    val list = listOf(ti0)
//    val instantiation = TemplateInstantiationBatch(list)
//    val json = defaultsEncodingJson.encodeToString(instantiation)
//    println(json)
//}