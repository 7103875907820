package com.rabbitsign.web.util

import com.github.doyaaaaaken.kotlincsv.dsl.csvReader
import com.rabbitsign.common.*
import com.rabbitsign.common.util.looksLikeEmail
import com.rabbitsign.web.Logger

val log = Logger("csv")

class CsvParsingException(message: String) : IllegalArgumentException(message)

private const val EmailOf = "Email of "
private const val NameOf = "Name of "
private const val ValueOf = "Value of "
private const val TITLE = "Title"
private const val SUMMARY = "Summary"
private const val CC_EMAILS = "ccEmails"

data class CsvRow(
    val roleMap: RoleInstantiationMap,
    val senderFieldMap: Map<RoleName, String>,
    val title: String,
    val summary: String,
    val ccEmails: List<Email>
)

internal fun generateCsvHeaders(templateParams: TemplateParams): List<String> {
    val headers = templateParams.senderFieldNames.map { "$ValueOf$it" }.toMutableList()
    templateParams.roleNames.forEach {
        headers.add("$EmailOf$it")
        headers.add("$NameOf$it")
    }
    headers.add(TITLE)
    headers.add(SUMMARY)
    headers.add(CC_EMAILS)
    return headers
}

internal fun parseBatchCsv(fileContents: String): List<DevApiTemplateInstantiation> {
    val today = getFormattedDateString(DateFormat.YYYY_MM_DD_DASH)

    val rows: List<Map<String, String>> = csvReader().readAllWithHeader(fileContents)
    return rows.mapIndexed { index, row ->
        log.debug("$index: $row")
        val (roleMap, senderFiledMap, title, summary, ccList) = parseBatchCsvRow(row)
        val senderFields = senderFiledMap.map { DevApiFieldValue(it.key, it.value) }
        DevApiTemplateInstantiation(roleMap, senderFields, title, summary, today, ccList)
    }
}

internal fun parseBatchCsvRow(row: Map<String, String>): CsvRow {
    val emailMap = mutableMapOf<RoleName, Email>()
    val nameMap = mutableMapOf<RoleName, Email>()
    val senderFieldMap = mutableMapOf<RoleName, Email>()
    var title = ""
    var summary = ""
    var ccEmails = emptyList<Email>()

    row.entries.forEach {
        when {
            it.key.startsWith(EmailOf) ->
                emailMap[it.key.removePrefix(EmailOf)] =
                    if (it.value.looksLikeEmail()) it.value else throw CsvParsingException("Invalid email: ${it.value}")

            it.key.startsWith(NameOf) ->
                nameMap[it.key.removePrefix(NameOf)] =
                    it.value.ifBlank { throw CsvParsingException("Blank name") }

            it.key.startsWith(ValueOf) ->
                senderFieldMap[it.key.removePrefix(ValueOf)] = it.value

            it.key == TITLE -> title = it.value

            it.key == SUMMARY -> summary = it.value

            it.key == CC_EMAILS -> ccEmails = it.value.parseEmailList()
        }
    }
    val roleMap = emailMap.filter { nameMap[it.key] != null }.mapValues {
        RoleInstantiation(it.value, nameMap[it.key]!!)
    }
    return CsvRow(roleMap, senderFieldMap, title, summary, ccEmails)
}

internal fun String.parseEmailList(): List<Email> =
    this.split("[,;\\s]+".toRegex()).filter {
        it.isNotEmpty() // Filter out empty items because "".split() return a list with one item, an empty string
    }.filter {
        if (it.looksLikeEmail()) true else throw CsvParsingException("Invalid email: $it")
    }

