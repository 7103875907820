package com.rabbitsign.web

import com.rabbitsign.common.Email
import com.rabbitsign.common.FolderInfo
import com.rabbitsign.common.FolderState
import com.rabbitsign.web.DashboardPage.displayCancelFolderModal
import com.rabbitsign.web.DashboardPage.displayRemindModal
import com.rabbitsign.web.DashboardPage.displayStatusModal
import com.rabbitsign.web.DashboardPage.displayStatusModalIfFolderInfoCellClicked
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.appendText
import org.w3c.dom.*

private const val DEFAULT_PAGE_SIZE = 50

class DashboardRowCreator(private val userEmail: Email) {
    companion object val log = Logger(this::class.simpleName)

    private var oldestDisplayedFolderTime = "3000-01-01T01:00:00.000000Z"  // hardcode a future date

    suspend fun displayMoreFolders(): Boolean {
        val tableBody = document.querySelector("#RabbitSign-dashboard-documents-table > tbody") as HTMLTableSectionElement
        val (foldersList, hasMore) = getApi().callGetFolderList(oldestDisplayedFolderTime, DEFAULT_PAGE_SIZE)
        foldersList.forEach { tableBody.appendChild(createFolderRow(it)) }
        if (foldersList.isNotEmpty()) oldestDisplayedFolderTime = foldersList.minOf { it.creationTimeUtc }  // get earliest timestamp
        return hasMore
    }

    private fun createFolderRow(folderInfo: FolderInfo): HTMLTableRowElement {
        val folderRow = (document.createElement("tr") as HTMLTableRowElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-tr"
            addClass("folder-row")
            onclick = { displayStatusModalIfFolderInfoCellClicked(it, folderInfo.folderId, folderInfo.folderStatus == FolderState.CANCELED) }
            setAttribute("data-creation-time", folderInfo.creationTimeUtc)
            setAttribute("data-creator-email", folderInfo.creatorEmail)
            setAttribute("data-title", folderInfo.title)
            setAttribute("data-message", folderInfo.summary)

            setAttribute("data-owner", if (userEmail == folderInfo.creatorEmail) "OwnerMe" else "OwnerOthers")
            setAttribute("data-state", computeFolderStateString(folderInfo))

            setAttribute("data-cc-list", computeCcListString(folderInfo.ccList))
        }

        folderRow.appendChild(createFolderStatusCell(folderInfo))
        folderRow.appendChild(createTitleCell(folderInfo.title))
        folderRow.appendChild(document.createTimeCell(folderInfo.creationTimeUtc))
        folderRow.appendChild(createAssigneesCell(folderInfo.signerNames))
        folderRow.appendChild(createSignViewCell(folderInfo))
        folderRow.appendChild(createFolderActionsCell(folderInfo))

        return folderRow
    }

    private fun computeFolderStateString(folderInfo: FolderInfo): String =
        folderInfo.folderStatus.name.lowercase().capitalize() + "__" +
                if (folderInfo.thisUserNeedsToSign && folderInfo.folderStatus == FolderState.CREATED) {
                    "WaitingMe"
                } else {
                    ""
                } + "__" +
                if (folderInfo.othersNeedToSign && folderInfo.folderStatus == FolderState.CREATED) {
                    "WaitingOthers"
                } else {
                    ""
                }

    private fun computeCcListString(ccList: List<Email>): String =
        if (ccList.size < 3) ccList.joinToString(" and ")
        else "${ccList.dropLast(1).joinToString()}, and ${ccList.last()}"

    private fun createFolderStatusCell(folderInfo: FolderInfo): HTMLTableCellElement {
        val folderStatusCell = document.createTableCell("folder-status-cell", "info-cell")

        val folderStatusContainer = document.createDiv("small")
        when (folderInfo.folderStatus) {
            FolderState.SIGNED -> {
                folderStatusCell.appendChild(document.createDiv("small").apply {
                    appendChild(createSignedIcon())
                    appendChild(createFolderStatusText("Signed"))
                })
            }
            FolderState.CANCELED -> {
                folderStatusCell.appendChild(document.createDiv("small").apply {
                    appendChild(createCanceledIcon())
                    appendChild(createFolderStatusText("Canceled"))
                })
            }
            else -> {
                if (folderInfo.thisUserNeedsToSign) {
                    folderStatusCell.appendChild(document.createDiv("small").apply {
                        appendChild(createWaitingMeIcon())
                        appendChild(createFolderStatusText("Waiting for Me"))
                    })
                }
                if (folderInfo.othersNeedToSign) {
                    folderStatusCell.appendChild(document.createDiv("small").apply {
                        appendChild(createWaitingOthersIcon())
                        appendChild(createFolderStatusText("Waiting for Others"))
                    })
                }

                // intermediate state where folder is being processed or something went wrong
                if (!folderInfo.thisUserNeedsToSign && !folderInfo.othersNeedToSign) {
                    folderStatusCell.appendChild(document.createDiv("small").apply {
                        appendChild(createFolderStatusText("Processing..."))
                    })
                }
            }
        }

        folderStatusCell.appendChild(folderStatusContainer)
        return folderStatusCell
    }

    private fun createFolderStatusText(content: String): HTMLSpanElement {
        return (document.createElement("span") as HTMLSpanElement).apply {
            appendText(content)
        }
    }

    private fun createTitleCell(folderTitle: String): HTMLTableCellElement =
        document.createTableCell("title-cell", "info-cell", text=folderTitle)

    private fun createAssigneesCell(assigneesList: List<String>): HTMLTableCellElement =
        document.createTableCell("assignees-cell", "info-cell", text=assigneesList.joinToString())

    private fun createSignViewCell(folderInfo: FolderInfo): HTMLTableCellElement {
        val signViewCell = document.createTableCell("actions-cell", "folder-sign-view")

        if (folderInfo.folderStatus != FolderState.CANCELED) {
            val link = (document.createElement("a") as HTMLAnchorElement).apply {
                id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-view-a"
                href = "/folder/${folderInfo.folderId}"
                if (folderInfo.thisUserNeedsToSign) {
                    addClass("font-weight-bold")
                    appendText("Sign")
                } else {
                    appendText("View")
                }
            }
            signViewCell.appendChild(link)
        }

        return signViewCell
    }

    private fun createFolderActionsCell(folderInfo: FolderInfo): HTMLTableCellElement {
        val folderActionsCell = document.createTableCell(
            "actions-cell", "dropdown",
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-folderActionsDropdown-td"
        )

        val actionsLink = (document.createElement("a") as HTMLAnchorElement).apply {
            addClass("dropdown-toggle", "text-reset")
            setAttribute("data-toggle", "dropdown")
            setAttribute("aria-haspopup", "true")
            setAttribute("aria-expanded", "false")
            appendText("Actions")
        }

        folderActionsCell.appendChild(actionsLink)
        folderActionsCell.appendChild(createFolderActionsDropdownMenu(folderInfo))

        return folderActionsCell
    }

    private fun createFolderActionsDropdownMenu(folderInfo: FolderInfo): HTMLDivElement {
        val dropdownMenu = document.createDiv("dropdown-menu", "dropdown-menu-right")
        dropdownMenu.setAttribute("aria-labelledby", "RabbitSign-dashboard-documents-${folderInfo.folderId}-folderActionsDropdown-td")

        if (folderInfo.folderStatus != FolderState.CANCELED) {
            dropdownMenu.appendChild(createDropdownOpener(folderInfo))
        }

        dropdownMenu.appendChild(createDropdownNotificationsLink(folderInfo))
        dropdownMenu.appendChild(createDropdownStatusButton(folderInfo))

        if (isFolderOwner(folderInfo, userEmail)) {
            dropdownMenu.appendChild(createDropdownRemindButton(folderInfo))
            dropdownMenu.appendChild(createDropdownCancelButton(folderInfo))
        }

        // only signed documents should have a download link
        // so the download and remind/cancel buttons should never appear at the same time
        if (folderInfo.downloadUrl.isNotEmpty()) {
            dropdownMenu.appendChild(createDropdownDownloadLink(folderInfo))
        }

        return dropdownMenu
    }

    private fun createDropdownOpener(folderInfo: FolderInfo): HTMLAnchorElement {
        return (document.createElement("a") as HTMLAnchorElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-view-a"
            addClass("text-decoration-none")
            href = "/folder/${folderInfo.folderId}"
            appendChild((document.createElement("button") as HTMLButtonElement).apply {
                type = "button"
                addClass("dropdown-item", "dropdown-sign-view-item")
                appendText(if (folderInfo.thisUserNeedsToSign) "Sign" else "View")
            })
        }
    }

    private fun createDropdownNotificationsLink(folderInfo: FolderInfo): HTMLAnchorElement {
        return (document.createElement("a") as HTMLAnchorElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-notifications-a"
            addClass("text-decoration-none")
            href = "/notifications/${folderInfo.folderId}"
            appendChild((document.createElement("button") as HTMLButtonElement).apply {
                type = "button"
                addClass("dropdown-item")
                appendText("See Notifications")
            })
        }
    }

    private fun createDropdownStatusButton(folderInfo: FolderInfo): HTMLButtonElement {
        return (document.createElement("button") as HTMLButtonElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-status-button"
            addClass("dropdown-item")
            onclick = { displayStatusModal(folderInfo.folderId, folderInfo.folderStatus == FolderState.CANCELED) }
            appendText("Status")
        }
    }

    private fun createDropdownRemindButton(folderInfo: FolderInfo): HTMLButtonElement {
        return (document.createElement("button") as HTMLButtonElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-remind-button"
            addClass("dropdown-item")
            onclick = { displayRemindModal(folderInfo.folderId) }
            appendText("Remind")
        }
    }

    private fun createDropdownCancelButton(folderInfo: FolderInfo): HTMLButtonElement {
        return (document.createElement("button") as HTMLButtonElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-cancel-button"
            addClass("dropdown-item")
            setAttribute("data-title", folderInfo.title)
            onclick = { displayCancelFolderModal(folderInfo.folderId, folderInfo.title) }
            appendText("Cancel")
        }
    }

    private fun createDropdownDownloadLink(folderInfo: FolderInfo): HTMLAnchorElement {
        return (document.createElement("a") as HTMLAnchorElement).apply {
            id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-download-a"
            addClass("text-decoration-none")
            href = folderInfo.downloadUrl
            target = "_blank"
            rel = "noopener"
            appendChild((document.createElement("button") as HTMLButtonElement).apply {
                id = "RabbitSign-dashboard-documents-${folderInfo.folderId}-dropdown-download-button"
                addClass("dropdown-item")
                appendText("Download")
            })
        }
    }

    private fun isFolderOwner(folderInfo: FolderInfo, userEmail: Email) = folderInfo.creatorEmail == userEmail && folderInfo.folderStatus == FolderState.CREATED
}
