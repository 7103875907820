package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.common.DocumentType
import com.rabbitsign.web.filemanager.CreationPageFileManager
import com.rabbitsign.web.filemanager.FileLink
import com.rabbitsign.web.filemanager.FileObject
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.launch
import kotlinx.dom.*
import org.w3c.dom.*
import kotlin.math.sign

/*
The PDF display is made up of a viewport <div> with a .doc-wrapper <div> for each element.
The wrapper <div>s must all have the data-doc-number and data-doc-name attributes.
(Wrapper <div>s also all have a data-scale attribute, which is used for rendering the PDF. Some also have a data-file attribute.)

The data-doc-number attribute might be temporarily out of sync if a document is removed because an index will be missing.
Since this attribute is not used until the fields need to be saved, we can simply update the values whenever the user opens the document preparation interface.

Under the assumption that at any moment, all uploaded documents have unique names (which is enforced whenever the user uploads a document):
Wrapper <div>s are identified by their document's name. If a document is removed from the folder, its corresponding wrapper is deleted.
This can happen when instantiating a template: if the user removes a document that was originally in the template,
    that document would already have a wrapper with the template's fields in it and that wrapper would need to be deleted.
By doing this, we can save a user's progress on a per-document basis, which allows them to edit an instantiated template or go back and add another document to an almost-complete folder.
Wrappers are not added/created for new documents until the user opens the document preparation interface.

Some historical notes, in case there's any code I didn't update:
Wrappers originally had document numbers listed as part of their id attribute, in the format wrapper-$docIdx.
This was changed to a data-* attribute since changing an element's id attribute during its lifetime could be confusing.
Fields also had a data-doc-number attribute, although it should have been removed.
This should not be used now; the wrapper's data-doc-number should take precedence since the field's doc number may have changed since it was created.
 */

abstract class CreationPage(private val documentType: DocumentType) : SigningPage() {
    protected val sourceTemplateId = mainScriptElement.getAttribute("data-source-template-id") ?: ""  // will be empty if not from template

    private val assigneeContainer = document.getElementById("assigneeContainer") as HTMLDivElement
    protected val assigneesForm = document.getElementById("assigneesForm") as HTMLFormElement

    protected val addAssigneeButton = document.getElementById("RabbitSign-creation-addAssignee-button") as HTMLButtonElement
    private val signingOrderCheckbox = document.getElementById("RabbitSign-creation-signingOrderCheckbox-input") as HTMLInputElement

    private val docWrappers: MutableMap<String, HTMLDivElement> = document.querySelectorAll(".doc-wrapper").asList()
        .map { it as HTMLDivElement }
        .associateBy({ it.getAttribute("data-doc-name")!! }, { it }).toMutableMap()
    private val assigneeSelect = document.getElementById("RabbitSign-display-prepDoc-assignee-select") as HTMLSelectElement

    protected var dateFormat = defaultDateFormat

    override val fieldsManager = CreationFieldsManager(fieldFiller)
    protected val fileManager = CreationPageFileManager(docWrappers)
    protected val ccEmailsCollector = CCEmailsCollector(if (documentType == DocumentType.FOLDER) 4 else 5)

    protected var idCounter = 0

    protected abstract fun getSignerInfo(): Map<String, SignerInfo>?

    override fun getDateFormat() = dateFormat

    fun initializeCreationPage() {
        log.info("CreationPage.initializeCreationPage")
        initializeNavbar()
        initializeInactivity()

        initializeAllTooltips()
        initializeDateFormatSelect()  // invoke this as soon as page loads because its state is needed to create folder and the user may Send Immediately

        addValidityListeners(document.getElementById("RabbitSign-creation-title-input") as HTMLInputElement)
        addValidityListeners(document.getElementById("RabbitSign-creation-message-textarea") as HTMLTextAreaElement)

        signingOrderCheckbox.onchange = {
            if (signingOrderCheckbox.checked) assigneeContainer.addClass("signing-order-displayed")
            else assigneeContainer.removeClass("signing-order-displayed")
        }
        if (signingOrderCheckbox.checked) assigneeContainer.addClass("signing-order-displayed")
        else assigneeContainer.removeClass("signing-order-displayed")

        val openPrepDoc = document.getElementById("RabbitSign-creation-openPrepDoc-button") as HTMLButtonElement
        openPrepDoc.onclick = {
            val assignees = validateInputAndRetrieveAssignees()
            if (assignees != null) {
                val withMeNow = mapOf(ME_NOW to SignerInfo(ME_NOW, ME_NOW, mutableListOf(), SIGNING_ORDER_1)) + assignees
                // guarantees ME_NOW will be at the start and the rest of the order will stay intact
                // https://kotlinlang.org/api/latest/jvm/stdlib/kotlin.collections/plus.html (at the end)

                hide("#navbarContainer")
                hide("#creationContainer")
                show("#interfaceContainer")

                initializePrepDoc(withMeNow)
            }
        }
    }

    protected open fun validateInputAndRetrieveAssignees(): Map<String, SignerInfo>? {
        if (!signingOrderCheckbox.checked) {
            // disable the signing order inputs so that they don't get checked for validity
            assigneeContainer.querySelectorAll(".signing-order-input").asList().forEach {
                (it as HTMLInputElement).disabled = true
            }
        }

        return (if (fileManager.files.isNotEmpty()) {
            if (checkInputValidity()) getSignerInfo()
            else null
        } else {
            displayWarningMessage(903, "Please select a document to sign.")
            null
        }).also {
            // enable the signing order inputs in case they need to be edited
            assigneeContainer.querySelectorAll(".signing-order-input").asList().forEach {
                (it as HTMLInputElement).disabled = false
            }
        }
    }

    protected fun newSigningOrderInput(): HTMLDivElement {
        log.info("CreationPage.newSigningOrderInput")
        return newFormControl("signing-order", "", 2).also {
            it.addClass("text-nowrap")
        }
    }

    protected fun isSigningOrderValid(signingOrder: List<Int>): String? {
        if (signingOrder.isEmpty()) return null

        val minVal = signingOrder.minOrNull()!!
        val orderValues = signingOrder.sorted()
        return when {
            minVal.sign <= 0 -> {
                "Please remove any negative numbers from the Signing Order."
            }
            orderValues.first() > 1 -> {
                "Please start the Signing Order with 1."
            }
            else -> {
                var current = orderValues.first()
                for (orderValue in orderValues) {
                    if (current + 1 == orderValue) {
                        current++
                    }
                    if (current != orderValue) {
                        return "There is no signer with Signing Order ${current + 1}. Please make sure there are no gaps in the Signing Order."
                    }
                }
                null
            }
        }
    }

    private fun initializePrepDoc(signers: Map<String, SignerInfo>) {
        log.info("CreationPage.initializePrepDoc with ${signers.size} signers")

        initializePDFDisplay(signers)
        initializeAssigneeSelect(signers)

        val addSignature = document.getElementById("RabbitSign-display-prepDoc-addSignature-button") as HTMLButtonElement
        addSignature.onmousedown = { fieldsManager.createFieldFromEvent(FieldType.SIGNATURE, assigneeSelect.value == ME_NOW, it) }

        val addInitials = document.getElementById("RabbitSign-display-prepDoc-addInitials-button") as HTMLButtonElement
        addInitials.onmousedown = { fieldsManager.createFieldFromEvent(FieldType.INITIALS, assigneeSelect.value == ME_NOW, it) }

        val addDate = document.getElementById("RabbitSign-display-prepDoc-addDate-button") as HTMLButtonElement
        addDate.onmousedown = { fieldsManager.createFieldFromEvent(FieldType.LOCAL_DATE, assigneeSelect.value == ME_NOW, it) }

        val addTextInput = document.getElementById("RabbitSign-display-prepDoc-addTextInput-button") as HTMLButtonElement
        addTextInput.onmousedown = { fieldsManager.createFieldFromEvent(FieldType.TEXTBOX, assigneeSelect.value == ME_NOW, it) }

        val addCheckbox = document.getElementById("RabbitSign-display-prepDoc-addCheckbox-button") as HTMLButtonElement
        addCheckbox.onmousedown = { fieldsManager.createFieldFromEvent(FieldType.CHECKBOX, assigneeSelect.value == ME_NOW, it) }
    }

    private fun initializePDFDisplay(signers: Map<String, SignerInfo>) {
        log.info("CreationPage.initializePDFDisplay with signers=$signers")
        val viewport = document.getElementById("RabbitSign-display-documentViewport-div") as HTMLDivElement
        viewport.onmouseup = { fieldsManager.deactivate(it) }

        val title = getInputValue("RabbitSign-creation-title-input")
        val message = (document.getElementById("RabbitSign-creation-message-textarea") as HTMLTextAreaElement).value

        val ccList = ccEmailsCollector.collectEmails()
        val fieldSaver = FieldSaver(fileManager.files, signers, title, message, documentType, ccList, signingOptionToken, sourceTemplateId)

        MainScope().launch {
            val renderers = fileManager.files.mapIndexed { idx, file ->
                val wrapper = docWrappers.getOrPut(file.name) {
                    document.createDiv("doc-wrapper")
                    // new wrappers will be added to the viewport below
                }
                wrapper.setAttribute("data-doc-name", file.name)
                wrapper.setAttribute("data-doc-number", idx.toString())

                async {
                    when (file) {
                        is FileObject -> createPDFRenderer(file.file.toArrayBuffer(), wrapper)
                        is FileLink -> createPDFRenderer(file.file, wrapper)
                    }
                }
            }

            // add wrappers to viewport in sorted order
            docWrappers.values
                .sortedBy { it.getAttribute("data-doc-number")!!.toInt() }
                .forEach { viewport.appendChild(it) }  // if the wrapper is already in the DOM, it is moved to its new location

            PrepDocumentPDFDisplay(renderers.awaitAll(), fieldSaver, fieldsManager).initialize()
        }
    }

    private fun initializeAssigneeSelect(signers: Map<String, SignerInfo>) {
        log.info("CreationPage.initializeAssigneeSelect with signers=$signers")

        fun changeAddButtonColor(assigneeNum: Int) {
            for (addButton in document.querySelectorAll("button.btn-add-field").asList()) {
                (addButton as HTMLElement).setAssigneeBackgroundColor(assigneeNum)
            }
        }

        assigneeSelect.clear()
        signers.entries.forEachIndexed { idx, it ->
            val opt = document.createElement("option") as HTMLOptionElement
            opt.value = it.value.email
            opt.setAttribute("data-assignee-id", it.key)

            val icon = document.createElement("i") as HTMLElement
            icon.addClass("bi", "bi-circle-fill", "mr-1")
            icon.setAssigneeTextColor(idx)

            opt.appendChild(icon)
            opt.appendText(it.value.name)
            assigneeSelect.appendChild(opt)
        }
        val showMyFieldsOnly = (document.getElementById("RabbitSign-display-showMeOnly-input") as HTMLInputElement).checked
        assigneeSelect.selectedIndex = if (assigneeSelect.options.length == 1 || showMyFieldsOnly) 0 else 1

        assigneeSelect.onchange = { changeAddButtonColor(assigneeSelect.selectedIndex) }
        changeAddButtonColor(assigneeSelect.selectedIndex)

        if (documentType == DocumentType.TEMPLATE) {
            assigneeSelect[0]!!.addClass("d-none")  // hide Me (Now) option for templates; backend doesn't support
            // we can't simply remove the option from the list since some pieces of code assume the first option is always Me (Now)
        }

        js("$('#RabbitSign-display-prepDoc-assignee-select').chosen({disable_search_threshold: 12})")
        js("$('#RabbitSign-display-prepDoc-assignee-select').trigger('chosen:updated')")
    }

    private fun initializeDateFormatSelect() {
        log.info("CreationPage.initializeDateFormatSelect with dateFormat=$dateFormat")
        val dateFormatSelect = document.getElementById("RabbitSign-display-prepDoc-dateFormat-select") as HTMLSelectElement
        dateFormatSelect.clear()
        for (formatOption in DateFormat.values()) {
            val opt = document.createElement("option") as HTMLOptionElement
            opt.value = formatOption.pattern
            opt.innerHTML = "${formatOption.pattern}<br>(${formatOption.example})"
            dateFormatSelect.appendChild(opt)
        }
        dateFormatSelect.selectedIndex = dateFormat.ordinal
        dateFormatSelect.onchange = {
            log.info("CreationPage.dateFormatSelect.onchange")
            for (dateFieldNode in document.querySelectorAll(".field.local_date-field").asList()) {
                val dateField = dateFieldNode as HTMLDivElement
                if (dateField.hasClass("filled")) {
                    val currentDateFormat = DateFormat.fromPattern(dateFormatSelect.value)
                    val resizerSpan = dateField.querySelector("span.input-sizer") as HTMLSpanElement
                    resizerSpan.textContent = getFormattedDateString(currentDateFormat)
                    val content = dateField.querySelector("input.content") as HTMLInputElement
                    content.value = getFormattedDateString(currentDateFormat)
                } else {
                    dateField.querySelector(".date-format")!!.textContent = dateFormatSelect.value
                }
            }
            js("$('#RabbitSign-display-prepDoc-dateFormatToast-div').toast('show')")

            dateFormat = DateFormat.fromPattern(dateFormatSelect.value)

            Unit
        }
        js("$('#RabbitSign-display-prepDoc-dateFormatToast-div').toast()")
        js("$('#RabbitSign-display-prepDoc-dateFormat-select').chosen({width: '90%', disable_search_threshold: 12})")
    }

}
