package com.rabbitsign.web

import com.rabbitsign.web.util.displayErrorMessage
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.asList


@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("ViewFolderPage")
object ViewFolderPage : SigningPage() {
    override fun runPage() {
        log.info("run with defaultDateFormat=$defaultDateFormat, userName=$userName, signingOptionToken=$signingOptionToken")

        val endingPagePath = mainScriptElement.getAttribute("data-ending-page-path") ?: "".also { displayErrorMessage(990) }
        val folderByTokenSignedRegex = Regex("/folder-by-token/signed/[a-zA-Z0-9]{44}")
        val folderSignedFolderIdRegex = Regex("/folder/signed/[a-zA-Z0-9]{22}")
        if (!folderByTokenSignedRegex.matches(endingPagePath) && !folderSignedFolderIdRegex.matches(endingPagePath)) {  // must match one of them exactly
            displayErrorMessage(991)
        }

        MainScope().launch {
            val renderers = document.querySelectorAll(".doc-wrapper[data-file]").asList().map {
                val elem = it as HTMLDivElement
                async {
                    createPDFRenderer(elem.getAttribute("data-file")!!, elem)
                }
            }
            ViewFolderPDFDisplay(renderers.awaitAll(), fieldsManager, signingOptionToken, endingPagePath).initialize()
        }

        addNextFieldButtonHandler()
    }
}
