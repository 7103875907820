package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import org.w3c.dom.*


fun openLegalTextModal(callback: () -> Unit) {
    console.log("invoked openLegalTextModal with callback")
    js("$('#RabbitSign-display-legalTextModal-div').modal('show')")

    val agreeButton = document.getElementById("RabbitSign-display-legalTextModal-agree-button") as HTMLElement
    agreeButton.onclick = {
        console.log("invoked openLegalTextModal.agreeButton.onclick")
        callback()
    }
}


abstract class SigningPage : ViewingPage() {
    protected val userName = mainScriptElement.getAttribute("data-name")!!
    protected val defaultDateFormat = DateFormat.valueOfOrDefault(mainScriptElement.getAttribute("data-date-format"))
    protected val signingOptionToken = SigningOptionToken(mainScriptElement.getAttribute("data-signing-option-token")!!)

    protected val fieldFiller = FieldFiller(userName, signingOptionToken) { getDateFormat() }
    override val fieldsManager = FieldsManager(fieldFiller)

    open fun getDateFormat() = defaultDateFormat

    fun checkSignatures(useFieldName: Boolean): SenderFieldList? {
        log.info("SigningPage.checkSignatures with useFieldName=$useFieldName")
        if (document.querySelectorAll(".fillable:not(.filled)").length > 0) {
            displayInfoMessage("Not all fields have been signed. Use the \"Find next unsigned field\" button at the top of your page to find the next unsigned field.")
            return null
        }

        val wrappers = document.querySelectorAll(".doc-wrapper").asList()
        return wrappers.map {
            val wrapper = it as HTMLDivElement
            val docNumber = wrapper.getAttribute("data-doc-number")!!.toInt()

            val fieldNodes = document.querySelectorAll(".field.filled").asList()
            fieldNodes.map { fieldNode ->
                val field = fieldNode as HTMLDivElement

                val fieldId = field.getAttribute("data-field-id")!!.toInt()

                val value = when (field.fieldType) {
                    // signature and initials fields will have an empty value; value is determined by signingOptionId instead
                    FieldType.SIGNATURE, FieldType.INITIALS -> ""
                    FieldType.LOCAL_DATE, FieldType.TEXTBOX -> (field.querySelector("input") as HTMLInputElement).value
                    FieldType.CHECKBOX -> field.getAttribute("data-checked")!!
                }

                val fieldName = if (useFieldName) field.getAttribute("data-field-name")!! else ""

                val signingOptionId = if (field.fieldType in setOf(FieldType.SIGNATURE, FieldType.INITIALS)) {
                    field.getAttribute("data-signing-option-id")!!
                } else ""

                FieldSpec(fieldId, field.fieldType, value, fieldPosition(field, docNumber), name = fieldName, signingOptionId = signingOptionId)
            }
        }.flatten()
    }

    fun addNextFieldButtonHandler() {
        log.info("SigningPage.addNextFieldButtonHandler")
        val nextFieldButton = (document.getElementById("RabbitSign-display-sign-nextField-button") ?: return) as HTMLButtonElement
        nextFieldButton.onclick = {
            fieldsManager.goToNextField()
        }
    }
}
