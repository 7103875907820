package com.rabbitsign.web

import com.rabbitsign.web.developerApi.computeSignature
import com.rabbitsign.web.util.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.removeClass
import org.w3c.dom.*
import kotlin.js.Date

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("DeveloperFaqPage")
object DeveloperFaqPage : RabbitSignPage() {
    private var timer: Int? = null

    override fun runPage() {
        log.info("run")

        initializeNavbar()

        initializeAllTooltips()

        initializeButtons()
    }

    private fun initializeButtons() {
        val payloadButton = document.getElementById("generateApiPayloadButton") as HTMLButtonElement
        payloadButton.onclick = {
            generateHttpPayload()
        }
        val commandsButton = document.getElementById("generateApiCommandsButton") as HTMLButtonElement
        commandsButton.onclick = {
            generateHttpCommands()
        }
    }

    private fun generateHttpPayload() {
        val emailInput = document.getElementById("demoEmailInput") as HTMLInputElement
        val generatedJson = document.getElementById("demoGeneratedJson") as HTMLElement
        val httpRequestBody = document.getElementById("demoHttpRequestBody") as HTMLTextAreaElement

        val email = emailInput.value
        if (email.isEmpty()) {
            displayWarningMessage(400, "Please provide your email")
            return
        }

        val now = Date()
        val formattedLocalDate = formatLocalDate(now)

        generatedJson.removeClass("hidden")
        httpRequestBody.value = """{
            |  "title": "Demo rental lease via RabbitSign API",
            |  "summary": "If RabbitSign can't lower your e-signing API cost by 90%, we'll refund you to make it so.",
            |  "date":"$formattedLocalDate",
            |  "senderFieldValues": [
            |    {
            |      "name":"today",
            |      "currentValue":"Jan 1, 1980"
            |    },
            |    {
            |      "name":"landlord name",
            |      "currentValue":"Tony Stark"
            |    },
            |    {
            |      "name":"tenant name",
            |      "currentValue":"Peter Parker"
            |    },
            |    {
            |      "name":"address",
            |      "currentValue":"10880 Malibu Point"
            |    },
            |    {
            |      "name":"start date",
            |      "currentValue":"Jan 1, 2048"
            |    },
            |    {
            |      "name":"rent",
            |      "currentValue":"950"
            |    },
            |    {
            |      "name":"landlord signature",
            |      "currentValue":"Tony Stark"
            |    },
            |    {
            |      "name":"landlord date",
            |      "currentValue":"$formattedLocalDate"
            |    }
            |  ],
            |  "roles": {
            |    "Tenant 1": {
            |      "name": "Peter Parker",
            |      "email": "$email"
            |    }
            |  },
            |  "ccList": []
            |}
        """.trimMargin()

        val copyHttpRequestBody = document.getElementById("demoCopyHttpRequestBody") as HTMLButtonElement
        copyHttpRequestBody.onclick = {
            window.navigator.clipboard.writeText(httpRequestBody.value)
            js("$('#copyTextToast').toast('show');")
        }
    }

    private fun generateHttpCommands() {
        val generatedCommands = document.getElementById("demoGeneratedCommands") as HTMLElement
        generatedCommands.removeClass("hidden")
        val curlCommand = document.getElementById("demoCurlCommand") as HTMLInputElement
        val httpieCommand = document.getElementById("demoHttpieCommand") as HTMLInputElement

        val copyCurlCommand = document.getElementById("demoCopyCurlCommand") as HTMLButtonElement
        val copyHttpieCommand = document.getElementById("demoCopyHttpieCommand") as HTMLButtonElement
        copyCurlCommand.onclick = {
            window.navigator.clipboard.writeText(curlCommand.value)
            js("$('#copyTextToast').toast('show');")
        }
        copyHttpieCommand.onclick = {
            window.navigator.clipboard.writeText(httpieCommand.value)
            js("$('#copyTextToast').toast('show');")
        }

        timer?.let { window.clearTimeout(timer!!) }
        timer = window.setTimeout(
            {
                val expired = "Expired. Please re-generate the curl and httpie commands."
                curlCommand.value = expired
                httpieCommand.value = expired
            },
            50_000
        )

        val now = Date()
        val formattedUtc = formatUtc(now)

        MainScope().launch {
            val templateId = "1TjsGyA6DM5nerrLD3YNLr"
            val path = "/api/v1/folderFromTemplate/$templateId"

            val apiKeyId = "ufOHNT2sXOr3DcGwN48aPO"
            val apiKeySecret = "eVBZvJTyDVPTDUARurhPvzJbUFv1O2dupldrSk76m1Gc"
            val apiSignature = computeSignature("POST $path $formattedUtc $apiKeySecret")
            val url = "https://www.rabbitsign.com$path"

            curlCommand.value =
                """curl --verbose -X POST 
                    |-H "Content-Type: application/json" 
                    |-H "x-rabbitsign-api-key-id:$apiKeyId" 
                    |-H "x-rabbitsign-api-time-utc:$formattedUtc" 
                    |-H "x-rabbitsign-api-signature:$apiSignature" 
                    |--data @body.json $url""".trimMargin()
            httpieCommand.value =
                """http --verbose POST $url 
                    |Content-Type:application/json 
                    |x-rabbitsign-api-key-id:$apiKeyId 
                    |x-rabbitsign-api-time-utc:$formattedUtc 
                    |x-rabbitsign-api-signature:$apiSignature 
                    |< body.json""".trimMargin()
        }
    }
}

private fun Int.pad() = this.toString().padStart(2, '0')

private fun formatUtc(date: Date): String {
    val year = date.getUTCFullYear()
    val month = (date.getUTCMonth() + 1).pad()
    val day = date.getUTCDate().pad()
    val hours = date.getUTCHours().pad()
    val minutes = date.getUTCMinutes().pad()
    val seconds = date.getUTCSeconds().pad()

    return "${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z"
}

private fun formatLocalDate(date: Date): String {
    val year = date.getFullYear()
    val month = (date.getMonth() + 1).pad()
    val day = date.getDate().pad()

    return "${year}-${month}-${day}"
}