package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.common.DocumentType
import com.rabbitsign.web.util.hide
import com.rabbitsign.web.util.show
import com.rabbitsign.web.util.toLocalTime
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.dom.*
import org.w3c.dom.*
import org.w3c.dom.events.MouseEvent

@OptIn(ExperimentalJsExport::class)
@JsExport
object DashboardPage : RabbitSignPage() {
    private val userEmail = userInfo.email.lowercase()
    private val dashboardRowCreator = DashboardRowCreator(userEmail)  // backend always sends lowercase value
    private var hasMoreFoldersToLoad = true

    override fun runPage() {
        log.info("run")

        initializeNavbar()
        initializeInactivity()

        val folderSearchbar = document.getElementById("RabbitSign-dashboard-documents-folderSearchbar-input") as HTMLInputElement
        folderSearchbar.oninput = { filterDocs(folderSearchbar.value, DocumentType.FOLDER) }

        val templateSearchbar = document.getElementById("RabbitSign-dashboard-templates-templateSearchbar-input") as HTMLInputElement
        templateSearchbar.oninput = { filterDocs(templateSearchbar.value, DocumentType.TEMPLATE) }

        val batchSearchbar = document.getElementById("RabbitSign-dashboard-batches-batchSearchbar-input") as HTMLInputElement
        batchSearchbar.oninput = { filterDocs(batchSearchbar.value, DocumentType.BATCH) }

        // clear out old filters from going back a page
        for (checkboxNode in document.querySelectorAll("#filterMenu input[type=\"checkbox\"]").asList()) {
            val checkbox = checkboxNode as HTMLInputElement
            checkbox.checked = false
        }

        changeToLocalTime()  // this is still required for templates and batches

        hide("#folderLoadingSpinner")

        loadFoldersWhileBottomVisible()
        // if the user has no folders at all, the server DSL will already have generated a prompt to create a folder, so the frontend does nothing

        window.addEventListener("scroll", {
            loadFoldersWhileBottomVisible()
        })
    }

    private var loadingFolders = false
    private fun loadFoldersWhileBottomVisible() {
        if (loadingFolders) return

        val foldersContainer = document.getElementById("folders") as HTMLDivElement
        MainScope().launch {
            loadingFolders = true
            show("#folderLoadingSpinner")
            while (hasMoreFoldersToLoad && foldersContainer.getBoundingClientRect().bottom <= window.innerHeight) {
                hasMoreFoldersToLoad = dashboardRowCreator.displayMoreFolders()
                filterByStatus()
                val folderSearchbar = document.getElementById("RabbitSign-dashboard-documents-folderSearchbar-input") as HTMLInputElement
                if (folderSearchbar.value.isNotEmpty()) filterDocs(folderSearchbar.value, DocumentType.FOLDER)
            }
            hide("#folderLoadingSpinner")
            loadingFolders = false
        }
    }

    private fun isChecked(idPrefix: String) =
        (document.getElementById("RabbitSign-dashboard-documents-filter-folder${idPrefix}Checkbox-input") as HTMLInputElement).checked

    @JsName("filterByStatus")
    fun filterByStatus() {
        log.info("filterByStatus")

        val statusOptions = listOf("Signed", "Canceled", "WaitingMe", "WaitingOthers")
        val ownerOptions = listOf("OwnerMe", "OwnerOthers")

        for (opt in statusOptions + ownerOptions) {
            val filterButtonIcon = document.getElementById("folder${opt}Icon") as HTMLElement
            if (isChecked(opt)) {
                filterButtonIcon.removeClass("d-none")
            } else {
                filterButtonIcon.addClass("d-none")
            }
        }

        val statusOptionsSelected = statusOptions.filter { isChecked(it) }
        val ownerOptionsSelected = ownerOptions.filter { isChecked(it) }

        val documents = document.querySelectorAll("tr.folder-row")
        val showAll = statusOptionsSelected.isEmpty() && ownerOptionsSelected.isEmpty()

        val statusOptionsFilter = statusOptionsSelected.ifEmpty { statusOptions }
        val ownerOptionsFilter = ownerOptionsSelected.ifEmpty { ownerOptions }

        for (docNode in documents.asList()) {
            val docElem = docNode as HTMLElement

            val stateData = docElem.getAttribute("data-state")!!.lowercase()
            val ownerData = docElem.getAttribute("data-owner")!!.lowercase()

            val stateMatches = statusOptionsFilter.any { stateData.contains(it.lowercase()) }
            val ownerMatches = ownerOptionsFilter.any { ownerData.contains(it.lowercase()) }

            if ((stateMatches && ownerMatches) || showAll) {
                docElem.removeClass("hidden-filter")
            } else {
                docElem.addClass("hidden-filter")
            }
        }
        loadFoldersWhileBottomVisible()
    }

    private fun filterDocs(search: String, docType: DocumentType) {
        log.info("filterDocs with search=$search, docType=$docType")
        val docTypeString = docType.name.lowercase()
        val documents = document.querySelectorAll("tr.$docTypeString-row")
        for (docNode in documents.asList()) {
            val docElem = docNode as HTMLElement
            val title = docElem.querySelector("td.title-cell")!!.textContent!!
            val date = docElem.querySelector("td.date-cell")!!.textContent!!
            val signersRoles = docElem.querySelector("td.assignees-cell")!!.textContent!!

            if (title.contains(search, ignoreCase = true) || date.contains(search, ignoreCase = true) || signersRoles.contains(search, ignoreCase = true)) {
                docElem.removeClass("hidden-search")
            } else {
                docElem.addClass("hidden-search")
            }
        }
        loadFoldersWhileBottomVisible()
    }

    private fun changeToLocalTime() {
        log.info("changeToLocalTime")
        val dateCells = document.querySelectorAll("[data-date]").asList()
        for (dateNode in dateCells) {
            val dateElem = dateNode as HTMLElement
            val (dateString, timeString) = toLocalTime(dateElem.getAttribute("data-date")!!)

            dateElem.clear()
            dateElem.appendText(dateString)
            dateElem.appendChild(document.createElement("br"))
            dateElem.appendText(timeString)
        }
    }

    private fun fillStatusTable(folderSignerStatus: List<SignerSummary>, sortHeader: String) {
        log.info("fillStatusTable with folderSignerStatus.size=${folderSignerStatus.size}, sortHeader=$sortHeader")

        val table = document.getElementById("RabbitSign-dashboard-documents-statusModal-statusTable-table") as HTMLElement
        val reverse = if (table.getAttribute("data-sort-key") == sortHeader) {
            !table.getAttribute("data-sort-reverse").toBoolean()
        } else {
            false
        }
        table.setAttribute("data-sort-key", sortHeader)
        table.setAttribute("data-sort-reverse", reverse.toString())

        // add css classes for the little pointers on the sorted column header
        val previousSortHeader = table.querySelector("th.current-sort")
        previousSortHeader?.removeClass("current-sort")
        // don't worry about removing the reverse-sort class since it only matters if the current-sort class also applies

        val currentSortHeader = document.getElementById("${sortHeader}Header") as HTMLElement
        currentSortHeader.addClass("current-sort")
        if (reverse) {
            currentSortHeader.addClass("reverse-sort")
        } else {
            currentSortHeader.removeClass("reverse-sort")
        }

        val hideSigningOrder = folderSignerStatus.all { it.signingOrder == SIGNING_ORDER_1 }
        if (hideSigningOrder) {
            table.querySelector("#signingOrderHeader")!!.addClass("hidden")
        } else {
            table.querySelector("#signingOrderHeader")!!.removeClass("hidden")
        }

        // remove old table body, if it exists
        table.querySelector("tbody")?.remove()

        val tableBody = document.createElement("tbody") as HTMLElement

        val sortedStatuses = if (reverse) {
            folderSignerStatus.reversed()
        } else {
            folderSignerStatus
        }
        for (signer in sortedStatuses) {
            val row = document.createElement("tr").apply {
                appendChild(document.createElement("td").apply { textContent = signer.name })
                appendChild(document.createElement("td").apply { textContent = signer.email })
                appendChild(document.createElement("td").apply { textContent = if (signer.status == SignerStatus.CREATED) {
                    "Waiting for signing order"
                } else {
                    signer.status.toString().lowercase().capitalize()
                } })
                appendChild(document.createElement("td").apply {
                    textContent = signer.signingOrder.toString()
                    if (hideSigningOrder) classList.add("hidden")
                })
            }
            if (signer.email == userEmail || signer.email == "$userEmail (Sender)") row.addClass("font-weight-bold")
            tableBody.appendChild(row)
        }
        table.appendChild(tableBody)
    }

    @JsName("displayStatusModalIfFolderInfoCellClicked")
    fun displayStatusModalIfFolderInfoCellClicked(event: MouseEvent, folderId: String, isCanceled: Boolean) {
        log.info("displayStatusModalIfFolderInfoCellClicked with event, folderId=$folderId, isCanceled=$isCanceled")
        val target = event.target as HTMLElement
        val fromInfoCell = document.querySelectorAll("#folders td.info-cell").asList().any { it.contains(target) }
        if (fromInfoCell) {
            displayStatusModal(folderId, isCanceled)
        }
    }

    private fun displayStatusTable(folderId: String) {
        log.info("displayStatusTable with folderId=$folderId")
        val table = document.getElementById("RabbitSign-dashboard-documents-statusModal-statusTable-table") as HTMLElement
        table.setAttribute("data-sort-key", "default")
        table.setAttribute("data-sort-reverse", false.toString())
        table.querySelector("tbody")?.remove()  // clear out the table, show the modal while API is getting called

        val nameHeader = document.getElementById("nameHeader") as HTMLElement
        val emailHeader = document.getElementById("emailHeader") as HTMLElement
        val statusHeader = document.getElementById("statusHeader") as HTMLElement
        val signingOrderHeader = document.getElementById("signingOrderHeader") as HTMLElement

        var modalOpened = false

        MainScope().launch {
            val signerSummary = getApi().callGetFolderStatus(folderId).signers

            nameHeader.onclick = { fillStatusTable(signerSummary.sortedBy { it.name }, "name") }
            emailHeader.onclick = { fillStatusTable(signerSummary.sortedBy { it.email }, "email") }
            statusHeader.onclick = { fillStatusTable(signerSummary.sortedBy { it.status.toString() }, "status") }
            signingOrderHeader.onclick = { fillStatusTable(signerSummary.sortedBy { it.signingOrder }, "signingOrder") }

            fillStatusTable(signerSummary.sortedBy{ it.name }, "name")

            if (!modalOpened) {  // show modal after API finishes, or after 500ms
                modalOpened = true
                js("$('#RabbitSign-dashboard-documents-statusModal-div').modal('show');")
            }
            Unit
        }
        MainScope().launch {
            delay(500)
            if (!modalOpened) {  // show modal after API finishes, or after 500ms
                modalOpened = true
                js("$('#RabbitSign-dashboard-documents-statusModal-div').modal('show');")
            }
            Unit
        }
    }

    @JsName("displayStatusModal")
    fun displayStatusModal(folderId: String, isCanceled: Boolean) {
        log.info("displayStatusModal with folderId=$folderId, isCanceled=$isCanceled")

        val folderRow = document.getElementById("RabbitSign-dashboard-documents-$folderId-tr") as HTMLElement

        val title = folderRow.getAttribute("data-title")!!
        val titleSpan = document.getElementById("RabbitSign-dashboard-documents-statusModal-title-span") as HTMLElement
        titleSpan.textContent = title

        val (dateString, timeString) = toLocalTime(folderRow.getAttribute("data-creation-time")!!)
        val creationTimeSpan = document.getElementById("RabbitSign-dashboard-documents-statusModal-creationTime-span") as HTMLElement
        creationTimeSpan.textContent = "$dateString $timeString"

        val message = folderRow.getAttribute("data-message")!!
        val messageSpan = document.getElementById("RabbitSign-dashboard-documents-statusModal-message-span") as HTMLElement
        messageSpan.textContent = message

        val creatorEmail = folderRow.getAttribute("data-creator-email")!!
        val creatorEmailSpan = document.getElementById("RabbitSign-dashboard-documents-statusModal-creatorEmail-span") as HTMLElement
        creatorEmailSpan.textContent = creatorEmail

        val canceledMessageP = document.getElementById("RabbitSign-dashboard-documents-statusModal-canceledMessage-p") as HTMLElement
        if (isCanceled) canceledMessageP.removeClass("hidden")
        else canceledMessageP.addClass("hidden")

        val ccListMessageP = document.getElementById("RabbitSign-dashboard-documents-statusModal-ccListMessage-p") as HTMLElement
        val ccList = folderRow.getAttribute("data-cc-list") ?: ""
        if (ccList.isNotBlank()) {
            ccListMessageP.removeClass("hidden")
            (document.getElementById("RabbitSign-dashboard-documents-statusModal-ccList-span") as HTMLElement).textContent = ccList
        } else {
            ccListMessageP.addClass("hidden")
        }

        displayStatusTable(folderId)

        val viewNotificationMessagesLink = document.getElementById("RabbitSign-dashboard-documents-statusModal-viewNotificationMessages-a") as HTMLAnchorElement
        viewNotificationMessagesLink.href = "/notifications/$folderId"

        val state = folderRow.getAttribute("data-state")!!
        val owner = folderRow.getAttribute("data-owner")!!

        val cancelDocumentButton = document.getElementById("RabbitSign-dashboard-documents-statusModal-cancel-button") as HTMLElement
        cancelDocumentButton.onclick = {
            displayCancelFolderModal(folderId, title)
        }
        if (state.contains(FolderState.CREATED.toString(), ignoreCase = true) && owner.equals("OwnerMe", ignoreCase = true)) {
            cancelDocumentButton.removeClass("hidden")
        } else {
            cancelDocumentButton.addClass("hidden")
        }

        val sendReminderButton = document.getElementById("RabbitSign-dashboard-documents-statusModal-remind-button") as HTMLElement
        sendReminderButton.onclick = { displayRemindModal(folderId) }
        if (state.contains(FolderState.CREATED.toString(), ignoreCase = true) && owner.equals("OwnerMe", ignoreCase = true)) {
            sendReminderButton.removeClass("hidden")
        } else {
            sendReminderButton.addClass("hidden")
        }

        val viewSignDocumentLink = document.getElementById("RabbitSign-dashboard-documents-statusModal-viewSign-a") as HTMLAnchorElement
        viewSignDocumentLink.href = "/folder/$folderId"

        val viewSignDocumentButton = document.getElementById("RabbitSign-dashboard-documents-statusModal-viewSign-button") as HTMLElement
        if (state.contains(FolderState.CANCELED.toString(), ignoreCase = true)) {
            viewSignDocumentButton.addClass("hidden")
        } else {
            viewSignDocumentButton.removeClass("hidden")
            viewSignDocumentButton.textContent = if (state.contains("waitingMe", ignoreCase = true)) "Sign" else "View"
        }
    }

    @JsName("displayRemindModal")
    fun displayRemindModal(folderId: String) {
        log.info("displayRemindModal with folderId=$folderId")

        val remindModal = document.getElementById("RabbitSign-dashboard-documents-remindModal-div") as HTMLElement
        remindModal.setAttribute("data-current-folder-id", folderId)

        val unsignedList = document.getElementById("unsignedList") as HTMLUListElement
        // remove any list items from previous modals
        unsignedList.clear()

        MainScope().launch {
            val signerSummary = getApi().callGetFolderStatus(folderId).signers

            val unsignedSigners = signerSummary.filter { it.status != SignerStatus.SIGNED }
            val lowestUnsignedSigningOrder = unsignedSigners.minOf { it.signingOrder }
            val needToSign = unsignedSigners.filter { it.signingOrder == lowestUnsignedSigningOrder }
            for (signer in needToSign) {
                val signerListItem = document.createElement("li")
                signerListItem.textContent = "${signer.name} (${signer.email})"
                unsignedList.appendChild(signerListItem)
            }
            js("$('#RabbitSign-dashboard-documents-remindModal-div').modal('show');")

            Unit
        }
    }

    private const val ReminderModalDivId = "RabbitSign-dashboard-documents-remindModalForBatch-div"

    @JsName("displayBatchRemindModal")
    fun displayBatchRemindModal(batchId: String, folders: List<FolderInfo>) {
        log.info("displayBatchRemindModal with batchId=$batchId")

        val remindModal = document.getElementById(ReminderModalDivId) as HTMLElement
        remindModal.setAttribute("data-current-batch-id", batchId)

        val unsignedList = document.getElementById("unsignedListForBatch") as HTMLUListElement
        // remove any list items from previous modals
        unsignedList.clear()

        val waitingFolders = folders.filter { it.folderStatus == FolderState.CREATED }
        waitingFolders.forEach {
            val folderItem = document.createElement("li")
            folderItem.textContent = it.signerNames.joinToString()
            unsignedList.appendChild(folderItem)
        }

        js("$('#$ReminderModalDivId').modal('show');")
    }

    @JsName("displayCancelFolderModal")
    fun displayCancelFolderModal(folderId: String, folderTitle: String) {
        log.info("displayCancelFolderModal with folderId=$folderId, folderTitle=$folderTitle")
        document.getElementById("RabbitSign-dashboard-documents-cancelFolderModal-folderTitle-span")!!.textContent = folderTitle
        val cancelFolderButton = document.getElementById("RabbitSign-dashboard-documents-cancelFolderModal-cancelFolder-button") as HTMLElement
        cancelFolderButton.onclick = {
            MainScope().launch {
                getApi().callCancelFolder(folderId)
                delay(500) // give the cancellation request a head start, just in case
                window.location.reload()

                // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                Unit
            }
        }
        js("$('#RabbitSign-dashboard-documents-cancelFolderModal-div').modal('show');")
    }

    @JsName("displayTemplateInfoModalIfTemplateInfoCellClicked")
    fun displayTemplateInfoModalIfTemplateInfoCellClicked(event: MouseEvent, templateId: String) {
        log.info("displayTemplateInfoModalIfTemplateInfoCellClicked with event, templateId=$templateId")

        val target = event.target as HTMLElement
        val fromInfoCell = document.querySelectorAll("#templates td.info-cell").asList().any { it.contains(target) }
        if (fromInfoCell) {
            displayTemplateInfoModal(templateId)
        }
    }

    @JsName("displayTemplateInfoModal")
    fun displayTemplateInfoModal(templateId: String) {
        log.info("displayTemplateInfoModal with templateId=$templateId")

        val templateRow = document.getElementById("RabbitSign-dashboard-templates-$templateId-tr") as HTMLElement

        val title = templateRow.getAttribute("data-title")!!
        val titleSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-title-span") as HTMLElement
        titleSpan.textContent = title

        val (dateString, timeString) = toLocalTime(templateRow.getAttribute("data-last-modified")!!)
        val creationTimeSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-lastModified-span") as HTMLElement
        creationTimeSpan.textContent = "$dateString $timeString"

        val message = templateRow.getAttribute("data-message")!!
        val messageSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-message-span") as HTMLElement
        messageSpan.textContent = message

        val roles = templateRow.getAttribute("data-roles")!!
        val rolesSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-roles-span") as HTMLElement
        rolesSpan.textContent = roles

        val shareLink = templateRow.getAttribute("data-share-link")!!
        val noLinkSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-noLink-span") as HTMLElement
        val shareLinkAnchor = document.getElementById("RabbitSign-dashboard-templates-infoModal-shareLink-a") as HTMLAnchorElement
        if (shareLink == NOT_APPLICABLE) {
            noLinkSpan.removeClass("hidden")
            shareLinkAnchor.addClass("hidden")
        } else {
            noLinkSpan.addClass("hidden")
            shareLinkAnchor.removeClass("hidden")
            shareLinkAnchor.href = shareLink
            shareLinkAnchor.textContent = shareLink
        }

        val templateIdSpan = document.getElementById("RabbitSign-dashboard-templates-infoModal-templateId-span") as HTMLElement
        templateIdSpan.textContent = templateId

        val deleteButton = document.getElementById("RabbitSign-dashboard-templates-infoModal-delete-button") as HTMLElement
        deleteButton.onclick = { displayDeleteTemplateModal(templateId) }

        val copyLinkButton = document.getElementById("RabbitSign-dashboard-templates-infoModal-copyLink-button") as HTMLElement
        if (shareLink == NOT_APPLICABLE) {
            copyLinkButton.addClass("hidden")
        } else {
            copyLinkButton.removeClass("hidden")
            copyLinkButton.onclick = {
                copyToClipboard(shareLink)
            }
        }

        val sendAnchor = document.getElementById("RabbitSign-dashboard-templates-infoModal-send-a") as HTMLAnchorElement
        sendAnchor.href = "/template/roles/$templateId"

        js("$('#RabbitSign-dashboard-templates-infoModal-div').modal('show');")
    }

    private const val BatchInfoModalDiv = "RabbitSign-dashboard-batches-infoModal-div"

    @JsName("displayBatchInfoModal")
    fun displayBatchInfoModal(event: MouseEvent, batchId: String) {
        log.info("displayBatchInfoModal with batchId=$batchId")

        val titleSpan = document.getElementById("RabbitSign-dashboard-batches-infoModal-title-span") as HTMLElement
        titleSpan.textContent = "Documents in batch $batchId"

        val folderTable = document.getElementById("RabbitSign-dashboard-batches-infoModal-folderTable-table") as HTMLElement

        // remove old table body, if it exists
        folderTable.querySelector("tbody")?.remove()

        val tableBody = document.createElement("tbody") as HTMLElement

        MainScope().launch {
            val folders: List<FolderInfo> = getApi().callGetFoldersOfBatch(batchId)

            for (folder in folders) {
                val row = document.createElement("tr").apply {
                    appendChild(document.createElement("td").apply { textContent = folder.title })
                    appendChild(document.createElement("td").apply { textContent = folder.signerNames.joinToString() })
                    appendChild(document.createElement("td").apply { textContent = folder.folderStatus.toString() })
                }
                tableBody.appendChild(row)
            }
            folderTable.appendChild(tableBody)

            js("$('#$BatchInfoModalDiv').modal('show');")

            // TODO Wait for server code to generate the Remind button
//            val remindButton = document.getElementById("RabbitSign-dashboard-batches-infoModal-remind-a") as HTMLElement
//            remindButton.onclick = {
//                displayBatchRemindModal(batchId, folders)
//                js("$('#$BatchInfoModalDiv').modal('hide');")
//            }
            Unit
        }
    }

    @JsName("copyTemplateLink")
    fun copyTemplateLink() {
        log.info("copyTemplateLink")
        val linkElem = document.querySelector("#linkText") as HTMLAnchorElement
        copyToClipboard(linkElem.href)
    }

    private fun copyToClipboard(link: String) {
        val clipboardPromise = window.navigator.clipboard.writeText(link)
        clipboardPromise.then {
            js("$('#copyLinkToast').toast('show');")
        }
        clipboardPromise.catch {
            js("$('#copyErrorToast').toast('show');")
        }
    }

    @JsName("displayNoLinkModal")
    fun displayNoLinkModal() {
        log.info("displayNoLinkModal")
        js("$('#RabbitSign-dashboard-templates-noLinkModal-div').modal('show');")
    }

    @JsName("displayLinkModal")
    fun displayLinkModal(link: String) {
        log.info("displayLinkModal with link=$link")
        val linkElem = document.querySelector("#linkText") as HTMLAnchorElement
        linkElem.href = link
        linkElem.text = link

        js("$('#RabbitSign-dashboard-templates-linkModal-div').modal('show');")
    }

    @JsName("displayDeleteTemplateModal")
    fun displayDeleteTemplateModal(templateId: String) {
        log.info("displayDeleteTemplateModal with templateId=$templateId")

        val templateRow = document.getElementById("RabbitSign-dashboard-templates-$templateId-tr") as HTMLTableRowElement
        val templateTitle = templateRow.getAttribute("data-title")
        val hasTemplateLink = templateRow.getAttribute("data-share-link")?.startsWith("https") ?: false

        val templateTitleSpan = document.getElementById("RabbitSign-dashboard-templates-deleteTemplateModal-templateTitle-span") as HTMLSpanElement
        templateTitleSpan.textContent = templateTitle

        val templateLinkWarningSpan = document.getElementById("RabbitSign-dashboard-templates-deleteTemplateModal-templateLinkWarning-span") as HTMLSpanElement
        if (hasTemplateLink) templateLinkWarningSpan.removeClass("hidden")
        else templateLinkWarningSpan.addClass("hidden")

        val deleteTemplateButton = document.getElementById("RabbitSign-dashboard-templates-deleteTemplateModal-deleteTemplate-button") as HTMLElement
        deleteTemplateButton.onclick = {
            MainScope().launch {
                getApi().callDeleteTemplate(templateId)
                delay(500)  // give the delete request a head start, just in case
                window.location.reload()

                // return Unit to avoid CoroutinesInternalError: Fatal exception in coroutines machinery for DispatchedContinuation[WindowDispatcher@1, [object Object]]. Please read KDoc to 'handleFatalException' method and report this incident to maintainers
                Unit
            }
        }

        js("$('#RabbitSign-dashboard-templates-deleteTemplateModal-div').modal('show')")
    }

    @JsName("callReminderEmailApi")
    fun callReminderEmailApi(folderId: String) {
        log.info("callReminderEmailApi with folderId=$folderId")
        MainScope().launch {
            getApi().callNotifyFolder(folderId)
            js("$('#remindToast').toast('show')")
            Unit
        }
    }
}
