package com.rabbitsign.web.util

import com.rabbitsign.common.UserInfo
import com.rabbitsign.web.Logger
import com.rabbitsign.web.getApi
import com.rabbitsign.web.getCookie
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.appendText
import kotlinx.dom.clear
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLPreElement
import kotlin.math.max

fun displayApiErrorMessage(apiId: Int, statusCode: Short, diagnosticInfo: String) {
    val errorCode = apiId * 1000 + statusCode
    displayErrorMessage(errorCode, diagnosticInfo = diagnosticInfo)
}

fun displayErrorMessage(diagnosticCode: Int, message: String = "Sorry, something went wrong.", diagnosticInfo: String = "") {
    console.error("$diagnosticCode: $message")
    InfoMessageModal.title = "ERROR"
    InfoMessageModal.message = "$message Please reload the page and try again. If the problem persists, please email the diagnostic info below to contact@rabbitsign.com for investigation."
    InfoMessageModal.diagnostics = "Code: $diagnosticCode." + if (diagnosticInfo.isNotEmpty()) " Extra info: $diagnosticInfo" else ""
    InfoMessageModal.activate {  }
}

fun displayWarningMessage(diagnosticCode: Int, message: String) {
    console.warn("$diagnosticCode: $message")
    InfoMessageModal.title = "Warning!"
    InfoMessageModal.message = message
    InfoMessageModal.diagnostics = "Code: $diagnosticCode."
    InfoMessageModal.activate {  }
}

fun displayInfoMessage(message: String) {
    InfoMessageModal.title = "Attention!"
    InfoMessageModal.message = message
    InfoMessageModal.diagnostics = ""
    InfoMessageModal.activate {  }
}

fun displayIdleMessage(idleSeconds: Long, secondsRemaining: Long) {
    val startTime = currentTimeInSeconds()
    SessionInfoModal.title = "Inactivity Warning!"
    SessionInfoModal.message = "You have been idle for ${formatSeconds(idleSeconds)} and will be logged out in ${formatSeconds(max(0, secondsRemaining))}."
    SessionInfoModal.activate {
        val timeDiff = currentTimeInSeconds() - startTime
        val currentIdle = idleSeconds + timeDiff
        val currentRemaining = secondsRemaining - timeDiff
        SessionInfoModal.message = "You have been idle for ${formatSeconds(currentIdle)} and will be logged out in ${formatSeconds(max(0, currentRemaining))}."

        if (currentRemaining < 0) window.location.assign("/logout")  // in case the user becomes active but doesn't close the modal, log them out anyway
    }
}

fun displayExpiringSessionMessage(secondsRemaining: Long) {
    SessionInfoModal.title = "Session Expiring!"
    SessionInfoModal.message = "Your session will expire in ${formatSeconds(max(0, secondsRemaining))}."
    SessionInfoModal.activate {}
}


const val INFO_MESSAGE_MODAL_ID = "RabbitSign-page-infoMessageModal-div"  // the js() function requires const strings
const val SESSION_INFO_MODAL_ID = "RabbitSign-page-sessionInfoModal-div"

abstract class MessageModal(protected val idPrefix: String) {
    companion object val log = Logger(this::class.simpleName)
    
    private val MODAL_ID = "$idPrefix-div"
    private val MODAL_TITLE_ID = "$idPrefix-title-h5"
    private val MESSAGE_ID = "$idPrefix-message-div"

    protected val messageElem = document.createDiv().apply { id = MESSAGE_ID }
    protected val body = document.createDiv("modal-body")
    protected val footer = document.createDiv("modal-footer")

    private val titleElem = document.createElement("h5").apply {
        id = MODAL_TITLE_ID
        addClass("modal-title")
    }
    var title
        get() = titleElem.textContent ?: ""
        set(value) {
            titleElem.clear()
            titleElem.textContent = value
        }


    var message
        get() = messageElem.textContent ?: ""
        set(value) {
            messageElem.clear()
            messageElem.textContent = value
        }

    init {
        log.info("initializing MessageModal")

        val modal = (document.getElementById(MODAL_ID) as HTMLDivElement?)?.apply { clear() } ?: document.createDiv().apply {
            id = MODAL_ID
            addClass("modal", "fade", "high-overlay")
            tabIndex = -1
            setAttribute("data-backdrop", "static")
            setAttribute("aria-labelledby", MODAL_TITLE_ID)
            setAttribute("aria-hidden", "true")
        }

        val header = document.createDiv("modal-header").apply {
            appendChild(titleElem)
        }

        val content = document.createDiv("modal-content").apply {
            appendChild(header)
            appendChild(body)
            appendChild(footer)
        }

        val dialog = document.createDiv("modal-dialog", "modal-dialog-centered").apply { appendChild(content) }

        modal.appendChild(dialog)
        document.body!!.appendChild(modal)
    }

    abstract fun activate(handler: () -> Unit)
}

object InfoMessageModal : MessageModal("RabbitSign-page-infoMessageModal") {
    private val DIAGNOSTICS_CONTAINER_ID = "$idPrefix-diagnostics-div"
    private val DIAGNOSTICS_DISPLAY_ID = "$idPrefix-diagnostics-code"

    private val diagnosticsDisplay = (document.createElement("pre") as HTMLPreElement).apply {
        id = DIAGNOSTICS_DISPLAY_ID
        addClass("white-space-pre-wrap")
        style.background = "#eee"
        style.padding = "0.5rem"
        style.margin = "0"
    }

    private val diagnosticsContainer = document.createDiv().apply {
        id = DIAGNOSTICS_CONTAINER_ID
        appendChild(document.createElement("br"))
        appendText("Diagnostic Information:")
        appendChild(diagnosticsDisplay)
    }

    private var intervalId = 0
    private val closeButton = (document.createElement("button") as HTMLButtonElement).apply {
        type = "button"
        addClass("btn", "btn-outline-danger")
        setAttribute("data-dismiss", "modal")
        appendText("Close")
        onclick = {
            if (intervalId != 0) window.clearInterval(intervalId)
            intervalId = 0
            Unit
        }
    }

    var diagnostics
        get() = diagnosticsDisplay.textContent ?: ""
        set(value) {
            diagnosticsDisplay.clear()
            diagnosticsDisplay.textContent = value
        }

    init {
        body.apply {
            appendChild(messageElem)
            appendChild(diagnosticsContainer)
        }
        footer.appendChild(closeButton)
    }
    override fun activate(handler: () -> Unit) {
        if (diagnostics.isNotEmpty()) diagnosticsContainer.removeClass("d-none")
        else diagnosticsContainer.addClass("d-none")

        intervalId = window.setInterval({ handler() }, 1000)

        js("$('#$INFO_MESSAGE_MODAL_ID').modal()")
    }
}

object SessionInfoModal : MessageModal("RabbitSign-page-sessionInfoModal") {
    private val originalDocumentTitle = document.title

    private val logOutButton = (document.createElement("button") as HTMLButtonElement).apply {
        type = "button"
        addClass("btn", "btn-danger")
        appendText("Log out")
    }
    private val logOutLink = (document.createElement("a") as HTMLAnchorElement).apply {
        href = "/logout"
        appendChild(logOutButton)
    }
    private val userInfo get() = UserInfo.newInstance(window.atob(getCookie("user_info")))

    var visible = false
    var expirationTime = userInfo.exp - 5  // 5-second grace period before hard expiry

    private var intervalId = 0
    private val extendSessionButton = (document.createElement("button") as HTMLButtonElement).apply {
        type = "button"
        addClass("btn", "btn-outline-success")
        appendText("Stay logged in")
        onclick = {
            MainScope().launch {
                if (intervalId != 0) window.clearInterval(intervalId)
                intervalId = 0

                expirationTime = currentTimeInSeconds() + 60 * 60

                getApi().callRetrieveUserSettings()
                js("$('#$SESSION_INFO_MODAL_ID').modal('hide')")

                document.title = originalDocumentTitle
                visible = false
                Unit
            }
        }
    }

    init {
        body.apply {
            appendChild(messageElem)
            appendText("Please click the \"Stay logged in\" button to extend your session.")
        }
        footer.apply {
            appendChild(logOutLink)
            appendChild(extendSessionButton)
        }
    }

    override fun activate(handler: () -> Unit) {
        if (intervalId != 0) window.clearInterval(intervalId)
        intervalId = window.setInterval({ handler() }, 1000)

        js("$('#$SESSION_INFO_MODAL_ID').modal()")

        document.title = "${this.title} | RabbitSign"
        visible = true
    }
}
