package com.rabbitsign.common

import kotlinx.serialization.Serializable

@Serializable
enum class Compliance(val id: String, val filename: String) {
    SOC2("soc2", "RabbitSign-SOC2-September2023.pdf"),
    ISO27001("iso27001", "RabbitSign-ISO27001-initialCertification-August2023.pdf"),
    HIPAA("hipaa", "RabbitSign_HIPAA_BAA_2022-08-21.pdf");

    val modalId = "RabbitSign-compliance-$id-modal-div"
    val termsCheckboxId = "RabbitSign-compliance-$id-terms-input"
    val viewButtonId = "RabbitSign-compliance-$id-view-button"
}

@Serializable
data class ComplianceReportType(val compliance: Compliance)
