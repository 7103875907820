package com.rabbitsign.web

import com.rabbitsign.web.util.height
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

abstract class ViewingPage : RabbitSignPage() {
    protected val mainScriptElement = document.getElementById("mainScript") as HTMLElement
    open val fieldsManager = StaticFieldsManager()

    protected val documentViewport = document.getElementById("RabbitSign-display-documentViewport-div") as HTMLElement

    init {
        try {
            val observer = ResizeObserver { _, _ -> resizeViewport() }
            observer.observe(documentViewport)
        } catch (e: Throwable) {  // Kotlin Throwable maps to JS Error
            log.info("ResizeObserver likely doesn't exist")
            window.setInterval({
                resizeViewport()
            }, 500)
        }
        window.onresize = { resizeViewport() }
    }

    private fun resizeViewport() {
        val currentPageHeight = document.body?.scrollHeight ?: return
        val currentViewportHeight = documentViewport.height
        val targetPageHeight = try {
            jQuery(window).height() as Int
        } catch (e: Throwable) {
            window.innerHeight - 20  // subtract scrollbar as fallback
        }

        val targetViewportHeight = currentViewportHeight - (currentPageHeight - targetPageHeight)

        documentViewport.style.height = "clamp(28rem, ${targetViewportHeight - 1}px, calc(793px * var(--scale) + 8px))"
    }
}
