package com.rabbitsign.web

import com.rabbitsign.common.*
import com.rabbitsign.common.util.defaultsEncodingJson
import com.rabbitsign.common.util.unknownKeysSkippedJson
import com.rabbitsign.web.util.displayApiErrorMessage
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.coroutines.CancellableContinuation
import kotlinx.coroutines.delay
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.files.File
import org.w3c.xhr.XMLHttpRequest
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlin.js.Date
import kotlin.math.roundToLong

val CONTENT_TYPE_BINARY_OCTET_STREAM = mapOf("content-type" to "binary/octet-stream")

fun loginDebugSet(): Boolean = localStorage.getItem("rabbitsign-login-debug") == "true"
fun pageDebugSet():  Boolean = localStorage.getItem("rabbitsign-page-debug") == "true"
fun apiDebugSet():   Boolean = localStorage.getItem("rabbitsign-api-debug") == "true"
fun logDebugSet():   Boolean = localStorage.getItem("rabbitsign-log-debug") == "true"

fun getCookie(cookieName: String): String {
    if (loginDebugSet() && cookieName == "user_info") {
        return "eyJnaXZlbl9uYW1lIjogIlJhYmJpdFNpZ24iLCAiZmFtaWx5X25hbWUiOiAiRGVtbyIsICJlbWFpbCI6ICJkZW1vQHJhYmJpdHNpZ24uY29tIiwgImV4cCI6IDk2NjU4NzI0OTl9"
        // informational token with name, email, expiration time
    }
    val name = "$cookieName="
    val cookies = document.cookie.split(';')
    for (cookieStr in cookies) {
        val cookie = cookieStr.trim()
        if (cookie.startsWith(name)) {
            return cookie.substring(name.length)
        }
    }
    return ""
}

interface Api {
    // API-001
    suspend fun callCreateFolder(input: FolderInstantiation): FolderId

    // API-003
    suspend fun callCancelFolder(folderId: String)

    // API-011, API-012, API-013
    suspend fun callSignFolder(url: String, input: SignerFieldsWithDate)

    // API-021
    suspend fun callGetSigningOptionUploadUrl(signingOptionToken: SigningOptionToken): UploadUrl

    // API-022
    suspend fun callCreateS3SigningOption(signingOption: S3SigningOption): SigningOptionId

    // API-023
    suspend fun callCreateTextSigningOption(signingOption: TextSigningOption): SigningOptionId

    // API-025
    suspend fun callDeleteSigningOption(signingOptionToken: SigningOptionToken, signingOptionId: String)

    // API-035
    suspend fun callGetFolderStatus(folderId: String): FolderStatus

    // API-039
    suspend fun callGetFolderList(timeUtc: String, pageSize: Int): FolderPage

    // API-040
    suspend fun callNotifyFolder(folderId: String)


    // API-101
    suspend fun callCreateTemplate(input: Template): TemplateId

    // API-102
    suspend fun callDeleteTemplate(templateId: String)

    // API-110
    suspend fun callInstantiateTemplate(templateId: String, input: TemplateInstantiation): FolderId

    // API-111
    suspend fun callInstantiateTemplateLink(templateId: String, roles: TemplateLinkInstantiation): FolderId

    // API 120
    suspend fun callCreateBatch(templateId: String, instantiationBatch: TemplateInstantiationBatch): BatchId

    // API 122
    suspend fun callGetFoldersOfBatch(batchId: String): List<FolderInfo>

    // API 124
    suspend fun callGetTemplateParams(templateId: String): TemplateParams

    // API-200 and API-201
    suspend fun callUploadFile(file: File, type: DocumentType): UploadUrl

    // API 300
    suspend fun callRetrieveUserSettings(): UserSettings?

    // API 301
    suspend fun callSaveUserSettings(settings: UserSettings)

    // API 311
    suspend fun callEnableHipaa(disableHipaaOverride: DisableHipaa?)

    // API 351
    suspend fun callEnableDevMode()

    // API 352
    suspend fun callCreateDevKey(apiKeyName: ApiKeyName)

    // API 353
    suspend fun callDeleteDevKey(keyId: String)

    // API 355
    suspend fun callSaveWebhookUrl(webhook: Webhook)

    // API 401
    suspend fun callGetComplianceReportUrl(compliance: Compliance): ComplianceReportUrl
}

object RealApi : Api {
    val log = Logger(this::class.simpleName)

    override suspend fun callCreateFolder(input: FolderInstantiation): FolderId {
        log.info("callCreateFolder with input=$input")
        val inputString = defaultsEncodingJson.encodeToString(input)
        return defaultsEncodingJson.decodeFromString(callApi("POST", "/folder", inputString, apiId = 1)!!)
    }

    override suspend fun callCancelFolder(folderId: String) {
        log.info("callCancelFolder with folderId=$folderId")
        callApi("PUT", "/folder-cancel/$folderId", null, apiId = 3)
    }

    override suspend fun callSignFolder(url: String, input: SignerFieldsWithDate) {
        log.info("callSignFolder with url=$url, input=$input")
        val inputString = defaultsEncodingJson.encodeToString(input)
        callApi("PUT", url, inputString, apiId = 11)
    }

    override suspend fun callGetSigningOptionUploadUrl(signingOptionToken: SigningOptionToken): UploadUrl {
        log.info("callGetSigningOptionUploadUrl with signingOptionToken=$signingOptionToken")
        val output = callApi("POST", "/signing-option/upload-url", defaultsEncodingJson.encodeToString(signingOptionToken), apiId = 21)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callCreateS3SigningOption(signingOption: S3SigningOption): SigningOptionId {
        log.info("callCreateS3SigningOption with signingOption=$signingOption")
        val inputString = defaultsEncodingJson.encodeToString(signingOption)
        val output = callApi("POST", "/signing-option/image", inputString, apiId = 22)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callCreateTextSigningOption(signingOption: TextSigningOption): SigningOptionId {
        log.info("callCreateTextSigningOption with signingOption=$signingOption")
        val inputString = defaultsEncodingJson.encodeToString(signingOption)
        val output = callApi("POST", "/signing-option/text", inputString, apiId = 23)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callDeleteSigningOption(signingOptionToken: SigningOptionToken, signingOptionId: String) {
        log.info("callDeleteSigningOption with signingOptionToken=$signingOptionToken, signingOptionId=$signingOptionId")
        val inputString = defaultsEncodingJson.encodeToString(signingOptionToken)
        callApi("DELETE", "/signing-option/$signingOptionId", inputString, apiId = 25)
    }

    override suspend fun callGetFolderStatus(folderId: String): FolderStatus {
        log.info("callGetFolderStatus with folderId=$folderId")
        val output = callApi("GET", "/folder-status/$folderId", null, apiId = 35)
        return unknownKeysSkippedJson.decodeFromString(output!!)
    }

    override suspend fun callGetFolderList(timeUtc: String, pageSize: Int): FolderPage {
        log.info("callGetFolderList with timeUtc=$timeUtc, pageSize=$pageSize")
        val output = callApi("GET", "/folder-list/$timeUtc/$pageSize", null, apiId = 39)
        return unknownKeysSkippedJson.decodeFromString(output!!)
    }

    override suspend fun callNotifyFolder(folderId: String) {
        log.info("callNotifyFolder with folderId=$folderId")
        callApi("POST", "/folder-notify/$folderId", null, apiId = 40)
    }

    override suspend fun callCreateTemplate(input: Template): TemplateId {
        log.info("callCreateTemplate with input=$input")
        val inputString = defaultsEncodingJson.encodeToString(input)
        val output = callApi("POST", "/template", inputString, apiId = 101)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callDeleteTemplate(templateId: String) {
        log.info("callDeleteTemplate with templateId=$templateId")
        callApi("DELETE", "/template/$templateId", null, apiId = 102)
    }

    override suspend fun callInstantiateTemplate(templateId: String, input: TemplateInstantiation): FolderId {
        log.info("callInstantiateTemplate with templateId=$templateId, input=$input")
        val inputString = defaultsEncodingJson.encodeToString(input)
        val output = callApi("POST", "/template/instantiate/$templateId", inputString, apiId = 110)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callInstantiateTemplateLink(templateId: String, roles: TemplateLinkInstantiation): FolderId {
        log.info("callInstantiateTemplateLink with templateId=$templateId, roles=$roles")
        val inputString = defaultsEncodingJson.encodeToString(roles)
        val output = callApi("POST", "/template/link/$templateId", inputString, apiId = 111)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callCreateBatch(templateId: String, instantiationBatch: TemplateInstantiationBatch): BatchId {
        log.info("callCreateBatch with templateId=$templateId and ${instantiationBatch.instantiationList.size} instantiations")
        val inputString = defaultsEncodingJson.encodeToString(instantiationBatch)
        val output = callApi("POST", "/template/instantiate/batch/$templateId", inputString, apiId = 120)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callGetFoldersOfBatch(batchId: String): List<FolderInfo> {
        log.info("callGetFoldersOfBatch with batchId=$batchId")
        val output = callApi("GET", "/folders-of-batch/$batchId", null, apiId = 122)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callGetTemplateParams(templateId: String): TemplateParams {
        log.info("callGetTemplateParams with templateId=$templateId")
        val output = callApi("GET", "/template/params/$templateId", null, apiId = 124)
        return defaultsEncodingJson.decodeFromString(output!!)
    }

    override suspend fun callUploadFile(file: File, type: DocumentType): UploadUrl {
        log.info("callUploadFile with file=${file.name}, type=$type")

        val payload = when (type) {
            DocumentType.FOLDER -> null
            DocumentType.TEMPLATE -> Json.encodeToString(mapOf("type" to "template"))
            DocumentType.BATCH -> throw IllegalStateException("Batch has no file upload")
            DocumentType.USER_LOGO -> Json.encodeToString(mapOf("type" to "user-logo"))
        }
        val uploadUrlJson = callApi("POST", "/doc/upload-url", payload, apiId = 200)
        val uploadUrl: UploadUrl = unknownKeysSkippedJson.decodeFromString(uploadUrlJson!!)
        log.info("uploadUrl=$uploadUrl")

        callApiRaw("PUT", uploadUrl.uploadUrl, file, headers = CONTENT_TYPE_BINARY_OCTET_STREAM, apiId = 201)!!
        return uploadUrl
    }

    override suspend fun callRetrieveUserSettings(): UserSettings? {
        log.info("callRetrieveUserSettings")
        val output = callApi("GET", "/user/settings", null, apiId = 300, customHandler = { statusCode, continuation ->
            if (statusCode.toInt() == 401) {
                continuation.resume(null)
                true
            } else false
        })

        return if (output == null) null else unknownKeysSkippedJson.decodeFromString(output)
    }

    override suspend fun callSaveUserSettings(settings: UserSettings) {
        log.info("callSaveUserSettings with settings=$settings")
        val inputString = defaultsEncodingJson.encodeToString(settings)
        callApi("PUT", "/user/settings", inputString, apiId = 301)
    }

    override suspend fun callEnableHipaa(disableHipaaOverride: DisableHipaa?) {
        log.info("callEnableHipaa with disableHipaaOverride=$disableHipaaOverride")
        val input = if (disableHipaaOverride != null) defaultsEncodingJson.encodeToString(disableHipaaOverride)
        else null
        callApi("PUT", "/user/hipaa", input, apiId = 311)
    }

    override suspend fun callEnableDevMode() {
        log.info("callEnableDevMode")
        callApi("PUT", "/user/developer", payload = null, apiId = 351)
    }

    override suspend fun callCreateDevKey(apiKeyName: ApiKeyName) {
        log.info("callCreateDevKey")
        val input = defaultsEncodingJson.encodeToString(apiKeyName)
        callApi("POST", "/user/developer/key", payload = input, apiId = 352)
    }

    override suspend fun callDeleteDevKey(keyId: String) {
        log.info("callDeleteDevKey")
        callApi("DELETE", "/user/developer/key/$keyId", payload = null, apiId = 353)
    }

    override suspend fun callSaveWebhookUrl(webhook: Webhook) {
        log.info("callSaveWebhookUrl")
        val input = defaultsEncodingJson.encodeToString(webhook)
        callApi("PUT", "/user/developer/webhook", payload = input, apiId = 355)
    }

    override suspend fun callGetComplianceReportUrl(compliance: Compliance): ComplianceReportUrl {
        log.info("callGetComplianceReportUrl with compliance=$compliance")
        val input = defaultsEncodingJson.encodeToString(ComplianceReportType(compliance))
        val apiOutput = callApi("POST", "/compliance/reports", input, apiId = 401)!!
        return unknownKeysSkippedJson.decodeFromString<ComplianceReportUrl>(apiOutput)
    }
}

object FakeApi : Api {
    val log = Logger(this::class.simpleName)

    override suspend fun callUploadFile(file: File, type: DocumentType): UploadUrl {
        log.info("dummy callUploadFile with file=${file.name}, type=$type")
        return UploadUrl("fake uploadFile URL")
    }

    override suspend fun callCreateFolder(input: FolderInstantiation): FolderId {
        log.info("dummy callCreateFolder with input=$input")
        return FolderId("1234567890abcdefABCDEF")
    }

    override suspend fun callSignFolder(url: String, input: SignerFieldsWithDate) {
        log.info("dummy callSignFolder with url=$url, input=$input")
    }

    override suspend fun callGetFolderStatus(folderId: String): FolderStatus {
        log.info("dummy callGetFolderStatus with folderId=$folderId")
        return FolderStatus(
            "mock folder ID",
            listOf(
                SignerSummary("mock email 1", "mock name 1", SignerStatus.SIGNED, 1),
                SignerSummary("mock email 2", "mock name 2", SignerStatus.SIGNED, 1),
                SignerSummary("mock email 3", "amock name 3", SignerStatus.SIGNED, 2),
                SignerSummary("mock email 4", "bmock name 4", SignerStatus.VIEWED, 3),
                SignerSummary("mock email 5", "cmock name 5", SignerStatus.NOTIFIED, 3),
                SignerSummary("mock email 6", "dmock name 6", SignerStatus.SIGNED, 2)
            )
        )
    }

    override suspend fun callGetFolderList(timeUtc: String, pageSize: Int): FolderPage {
        val pauseTime = Date().getTime().roundToLong() % 5000 + 2000
        log.info("dummy callGetFolderList with timeUtc=$timeUtc, pageSize=$pageSize. Pausing for $pauseTime ms")
        delay(pauseTime)
        return FolderPage(
            listOf(
                FolderInfo("id0", "stanleyzhong8@gmail.com", "title0", "summary with\nmultiple lines", FolderState.CREATED, listOf("name1"), thisUserNeedsToSign = true, othersNeedToSign = false, listOf("cc1", "cc2"), "2022-07-01T06:32:17.628417Z", ""),
                FolderInfo("id1", "stanleyzhong8@gmail.com", "title1", "summary1", FolderState.CREATED, listOf("name1", "name2"), thisUserNeedsToSign = true, othersNeedToSign = true, emptyList(), "2022-07-01T06:32:17.628417Z", ""),
                FolderInfo("id2", "email2", "title2", "summary2", FolderState.CREATED, listOf("name1", "name2"), thisUserNeedsToSign = false, othersNeedToSign = true, listOf("cc1"), "2022-07-01T06:32:17.628417Z", ""),
                FolderInfo("id3", "email3", "title3", "summary3", FolderState.SIGNED, listOf("name1"), thisUserNeedsToSign = false, othersNeedToSign = false, emptyList(), "2022-07-01T06:32:17.628417Z", "/"),
                FolderInfo("id3", "email3", "title3", "summary3", FolderState.SIGNED, listOf("name1"), thisUserNeedsToSign = false, othersNeedToSign = false, listOf("cc1", "cc2"), "2022-07-01T06:32:17.628417Z", "/"),
                FolderInfo("id4", "email4", "title4", "summary4", FolderState.CANCELED, listOf("name1", "name2", "name3"), thisUserNeedsToSign = false, othersNeedToSign = false, listOf("cc1"), "2022-07-01T06:32:17.628417Z", "")
            ), hasMore = true
        )
    }

    override suspend fun callNotifyFolder(folderId: String) {
        log.info("dummy callNotifyFolder with folderId=$folderId")
        // just don't do anything
    }

    override suspend fun callCancelFolder(folderId: String) {
        log.info("dummy callCancelFolder with folderId=$folderId")
        // do nothing
    }

    override suspend fun callGetSigningOptionUploadUrl(signingOptionToken: SigningOptionToken): UploadUrl {
        log.info("dummy callGetSigningOptionUploadUrl with signingOptionToken=$signingOptionToken")
        return UploadUrl("fake uploadSigningOption URL")
    }

    override suspend fun callCreateS3SigningOption(signingOption: S3SigningOption): SigningOptionId {
        log.info("dummy callCreateS3SigningOption with signingOption=$signingOption")
        return SigningOptionId("dummy S3 SigningOptionId")
    }

    override suspend fun callCreateTextSigningOption(signingOption: TextSigningOption): SigningOptionId {
        log.info("dummy callCreateTextSigningOption with signingOption=$signingOption")
        return SigningOptionId("dummy text SigningOptionId")
    }

    override suspend fun callDeleteSigningOption(signingOptionToken: SigningOptionToken, signingOptionId: String) {
        log.info("dummy callDeleteSigningOption with signingOptionToken=$signingOptionToken, signingOptionId=$signingOptionId")
    }

    override suspend fun callCreateTemplate(input: Template): TemplateId {
        log.info("dummy callCreateTemplate with input=$input")
        return TemplateId("1234567890abcdefABCDEF")
    }

    override suspend fun callInstantiateTemplate(templateId: String, input: TemplateInstantiation): FolderId {
        log.info("dummy callInstantiateTemplate with templateId=$templateId, input=$input")
        return FolderId("1234567890abcdefABCDEF")
    }

    override suspend fun callInstantiateTemplateLink(templateId: String, roles: TemplateLinkInstantiation): FolderId {
        log.info("dummy callInstantiateTemplateLink with templateId=$templateId, roles=$roles")
        return FolderId("1234567890abcdefABCDEF")
    }

    override suspend fun callCreateBatch(templateId: String, instantiationBatch: TemplateInstantiationBatch): BatchId {
        log.info("dummy callCreateBatch with templateId=$templateId")
        return BatchId("1234567890abcdefABCDEF")
    }

    override suspend fun callGetFoldersOfBatch(batchId: String): List<FolderInfo> {
        log.info("dummy callGetFoldersOfBatch with batchId=$batchId")
        return listOf(
                FolderInfo("id2", "email2", "title2", "summary2", FolderState.CREATED, listOf("name1", "name2"), thisUserNeedsToSign = false, othersNeedToSign = true, listOf("cc1"), "2022-07-01T06:32:17.628417Z", ""),
                FolderInfo("id3", "email3", "title3", "summary3", FolderState.SIGNED, listOf("name1"), thisUserNeedsToSign = false, othersNeedToSign = false, emptyList(), "2022-07-01T06:32:17.628417Z", "/"),
                FolderInfo("id4", "email4", "title4", "summary4", FolderState.CANCELED, listOf("name1", "name2", "name3"), thisUserNeedsToSign = false, othersNeedToSign = false, listOf("cc1"), "2022-07-01T06:32:17.628417Z", "")
            )
    }

    override suspend fun callGetTemplateParams(templateId: String) =
        TemplateParams(listOf("senderFieldName1"), listOf("role name 1"))

    override suspend fun callRetrieveUserSettings(): UserSettings {
        log.info("dummy callRetrieveUserSettings")
        val sleep = 2000L
        log.info("sleeping for $sleep ms")
        delay(sleep)
        log.info("finished sleeping")
        return UserSettings(
            DateFormat.MM_DD_YYYY_SLASH, notifyUponDocViewing = false, notifyUponDocSigning = false,
            shareLink = "dummyShareLink", shareLinkReferer = "dummyLinkReferer", showAds = false,
            isDeveloper = false
        )
    }

    override suspend fun callDeleteTemplate(templateId: String) {
        log.info("dummy callDeleteTemplate with templateId=$templateId")
        // do nothing
    }

    override suspend fun callSaveUserSettings(settings: UserSettings) {
        log.info("dummy callSaveUserSettings with settings=$settings")
        // do nothing
    }

    override suspend fun callEnableHipaa(disableHipaaOverride: DisableHipaa?) {
        log.info("dummy callEnableHipaa with disableHipaaOverride=$disableHipaaOverride")
        // do nothing
    }

    override suspend fun callEnableDevMode() {
        log.info("dummy callEnableDevMode")
        // do nothing
    }

    override suspend fun callCreateDevKey(apiKeyName: ApiKeyName) {
        log.info("dummy callCreateDevKey")
        // do nothing
    }

    override suspend fun callDeleteDevKey(keyId: String) {
        log.info("dummy callDeleteDevKey")
        // do nothing
    }

    override suspend fun callSaveWebhookUrl(webhook: Webhook) {
        log.info("dummy callSaveWebhookUrl")
        // do nothing
    }

    override suspend fun callGetComplianceReportUrl(compliance: Compliance): ComplianceReportUrl {
        log.info("dummy callGetComplianceReportUrl with compliance=$compliance")
        return ComplianceReportUrl("https://www.rabbitsign.com")
    }
}

fun getApi(): Api {
    return if (apiDebugSet()) {
        FakeApi
    } else {
        RealApi
    }
}


// this API makes sure that every payload is of String? type. callApiRaw is for cases where payload isn't a String
suspend fun callApi(
    method: String, url: String, payload: String?, headers: Map<String, String> = emptyMap(), apiId: Int,
    customHandler: (Short, Continuation<String?>) -> Boolean = { _, _ -> false }
): String? = callApiRaw(method, url, payload, headers, apiId, customHandler)

suspend fun callApiRaw(
    method: String, url: String, payload: Any?, headers: Map<String, String> = emptyMap(), apiId: Int,
    customHandler: (Short, CancellableContinuation<String?>) -> Boolean = { _, _ -> false }
): String? {
    console.log("invoked callAPI with method=$method, url=$url, payload, headers=$headers, apiId=$apiId, customHandler")

    return suspendCancellableCoroutine { continuation: CancellableContinuation<String?> ->
        val xmlHttp = XMLHttpRequest().apply {
            onload = {  // do NOT use onreadystatechange; that will also fire if request fails with CORS error
                if (continuation.isActive) {
                    if (!customHandler(status, continuation)) {
                        if (status in 400..599) {
                            displayApiErrorMessage(apiId, status, responseText)
                            continuation.resumeWithException(IllegalStateException("API $apiId returned status code $status"))
                        } else {
                            continuation.resume(response as String?)
                        }
                    }
                }
            }
            onerror = {
                if (continuation.isActive) {
                    displayApiErrorMessage(apiId, 0, it.toString())
                    continuation.resumeWithException(IllegalStateException("API $apiId failed with error: $it"))
                }
            }
            open(method, url, true)  // request must be "OPENED" to set request headers
            headers.forEach {
                setRequestHeader(it.key, it.value)
            }
        }
        xmlHttp.send(payload)
    }
}
