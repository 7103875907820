package com.rabbitsign.web.util

import com.rabbitsign.common.DateFormat
import com.rabbitsign.common.Email
import com.rabbitsign.common.util.format
import com.rabbitsign.web.getCookie
import com.rabbitsign.web.pageDebugSet
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.appendText
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import org.khronos.webgl.Uint8Array
import org.w3c.dom.*
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import kotlin.js.Date
import kotlin.math.pow
import kotlin.math.round
import kotlin.math.roundToInt
import kotlin.math.sqrt


fun currentTimeInSeconds() = (Date.now() / 1000).toLong()


fun getFormattedDateString(dateFormat: DateFormat): String {
    console.log("invoked getFormattedDateString with dateFormat=$dateFormat")
    // january is 0
    val date = com.rabbitsign.common.util.Date(Date().getFullYear(), Date().getMonth() + 1, Date().getDate())
    return date.format(dateFormat)
}


fun formatSeconds(seconds: Long) = "${seconds / 60}:${(seconds % 60).toString().padStart(2, '0')}"

fun toLocalTime(timestamp: String): Pair<String, String> {
    val date = Date(timestamp)
    val dateString = date.toLocaleDateString()
    val timeString = date.toLocaleTimeString(options = JSON.parse("""{"hour": "2-digit", "minute": "2-digit", "timeZoneName": "short"}"""))
    return Pair(dateString, timeString)
}

fun hide(cssSelector: String) {
    console.log("invoked hide with cssSelector=$cssSelector")
    for (node in document.querySelectorAll(cssSelector).asList()) {
        (node as HTMLElement).addClass("hidden")
    }
}

fun show(cssSelector: String) {
    console.log("invoked show with cssSelector=$cssSelector")
    for (node in document.querySelectorAll(cssSelector).asList()) {
        (node as HTMLElement).removeClass("hidden")
    }
}

fun addFullPageSpinnerAndLeaveDialog() {
    console.log("invoked addFullPageSpinnerAndLeaveDialog")
    show("#spinnerOverlay")
    show("#fullPageSpinner")
    setLeaveConfirmDialog()
}

fun initializeAllTooltips() {
    console.log("invoked initializeAllTooltips")
    js("$('[data-toggle=\"tooltip\"]').tooltip('dispose').tooltip()")
}

fun createDeleteButton(): HTMLButtonElement {
    console.log("invoked createDeleteButton")
    val deleteButton = document.createElement("button") as HTMLButtonElement
    deleteButton.addClass("close")
    deleteButton.type = "button"
    deleteButton.setAttribute("aria-label", "Close")

    val deleteSpan = document.createElement("span") as HTMLSpanElement
    deleteSpan.setAttribute("aria-hidden", "true")
    deleteSpan.textContent = "×"
    deleteButton.appendChild(deleteSpan)

    return deleteButton
}

fun removeHiddenCompatibilityClass() {
    console.log("invoked removeHiddenCompatibilityClass")
    val hidden = document.getElementsByClassName("hidden-compatibility").asList()
    for (elem in hidden) {
        elem.removeClass("hidden-compatibility")
    }
}

fun userLoggedIn() = getCookie("user_info").isNotEmpty()

fun getInputValue(inputId: String) = (document.getElementById(inputId) as HTMLInputElement).value

fun HTMLElement.resizeContentInputIfExists(width: Double = this.getBoundingClientRect().width, height: Double = this.getBoundingClientRect().height) {
    val content = (this.querySelector("input.content") ?: return) as HTMLInputElement
    content.style.minWidth = "calc(${width}px - (2px * var(--scale)))"
    content.style.height = "calc(${height}px - (2px * var(--scale)))"
}

// make sure the input element is already appended into the DOM
// also make sure that the input element and input-sizer have the same font
fun HTMLInputElement.autoResize() {
    console.log("invoked autoResize, this=$this, data-auto-resize=${this.getAttribute("data-auto-resize")}")
    if (this.getAttribute("data-auto-resize").toBoolean()) {
        return
    }
    val resizerSpan = document.createElement("span") as HTMLSpanElement
    this.insertAdjacentElement("beforebegin", resizerSpan)
    resizerSpan.addClass("input-sizer")

    if (this.getAttribute("data-font-family") != null) {
        resizerSpan.fontFamily = this.fontFamily
    }

    // add event listener to avoid overwriting anything
    this.addEventListener("input", {
        resizerSpan.textContent = this.value
        this.style.width = "calc(${resizerSpan.offsetWidth}px + 0.4em)"
    })
    resizerSpan.textContent = this.value
    this.style.width = "calc(${resizerSpan.offsetWidth}px + 0.4em)"

    this.setAttribute("data-auto-resize", true.toString())
}

// calls afterInputHandler after the element's most recent input was afterInputMilliseconds ago
fun HTMLInputElement.addAfterInputHandler(afterInputHandler: () -> Unit, afterInputMilliseconds: Int = 1000) {
    var timeoutId = -1
    this.addEventListener("input", {
        if (timeoutId != -1) {
            window.clearTimeout(timeoutId)
        }
        timeoutId = window.setTimeout(afterInputHandler, afterInputMilliseconds)
        Unit
    })
}

fun HTMLElement.isCloseButton(): Boolean = this.hasClass("close") || this.parentElement?.hasClass("close") ?: false

fun dataUriToBlob(dataUri: String): Blob {
    val mimeType = dataUri.split(":")[1].split(";")[0]
    val binary = window.atob(dataUri.split(",")[1])
    val arr = Uint8Array(Array(binary.length) { binary[it].code.toByte() })
    return Blob(arrayOf(arr), BlobPropertyBag(type = mimeType))
}

fun distance(x1: Double, y1: Double, x2: Double, y2: Double) = sqrt((x2 - x1).pow(2) + (y2 - y1).pow(2))

fun Double.round(decimalPlaces: Int): Double {
    val multiplier = 10.0.pow(decimalPlaces)
    return round(this * multiplier) / multiplier
}

fun openPage(pageUrl: String) {
    removeLeaveConfirmDialog()
    if (pageDebugSet()) {
        window.open(pageUrl, "_blank")
    } else {
        window.location.assign(pageUrl)
    }
}

fun String.getFileExtension() = this.substring(this.lastIndexOf(".") + 1, this.length).lowercase()


// you cannot set a custom message for onbeforeunload in any major browsers, as of August 12, 2021
// this is for security reasons and probably won't change in the near future
// the message for returnValue is for browsers which still support it, just in case
fun setLeaveConfirmDialog() {
    window.onbeforeunload = {
        it.preventDefault()
        it.returnValue = "Are you sure you want to leave? Your changes may not be saved."
        it.returnValue
    }
}

fun removeLeaveConfirmDialog() {
    window.onbeforeunload = { null }
}

fun retryUntilTrue(pauseTime: Double = 1.0, action: () -> Boolean) {
    if (!action()) {
        window.setTimeout({
            retryUntilTrue(pauseTime * 1.01, action)
        }, (pauseTime * 10).roundToInt())
    }
}

fun CanvasRenderingContext2D.clearCanvas() {
    this.clearRect(0.0, 0.0, this.canvas.getWidth(), this.canvas.getHeight())
}

fun domainSeemsMisspelled(email: Email): Boolean {
    val domain = email.split("@")[1]
    val commonEmailDomains = listOf(
        "aol.com",
        "bellsouth.net",
        "comcast.net",
        "gmail.com",
        "hotmail.co.uk",
        "hotmail.com",
        "hotmail.fr",
        "msn.com",
        "mail.ru",
        "mail.com",
        "orange.fr",
        "outlook.com",
        "seznam.cz",
        "wanadoo.fr",
        "yahoo.com",
        "yahoo.fr",
        "yandex.ru",
        "zoho.com",
    )
    val editDistances = commonEmailDomains.map { osaDistance(domain, it) }
    return editDistances.minOrNull()!! == 1
}


fun Document.createDiv(vararg cssClasses: String): HTMLDivElement {
    return (this.createElement("div") as HTMLDivElement).apply { addClass(*cssClasses) }
}

fun Document.createTableCell(vararg cssClasses: String, id: String = "", text: String = ""): HTMLTableCellElement {
    return (this.createElement("td") as HTMLTableCellElement).apply {
        this.addClass(*cssClasses)
        if (id.isNotEmpty()) this.id = id
        if (text.isNotEmpty()) this.textContent = text
    }
}

fun Document.createTimeCell(timeUtc: String): HTMLTableCellElement {
    return this.createTableCell("text-center", "date-cell", "info-cell").apply {
        val (dateString, timeString) = toLocalTime(timeUtc)
        appendText(dateString)
        appendChild(this@createTimeCell.createElement("br"))
        appendText(timeString)
    }
}