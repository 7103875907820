package com.rabbitsign.web.developerApi

import kotlinx.browser.window
import kotlinx.coroutines.suspendCancellableCoroutine
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Uint8Array
import org.khronos.webgl.get
import kotlin.coroutines.Continuation
import kotlin.coroutines.resume

external class TextEncoder {
    fun encode(string: String): Uint8Array
}

suspend fun computeSignature(str: String): String {
    return suspendCancellableCoroutine { continuation: Continuation<String> ->
        window.asDynamic().crypto.subtle.digest("SHA-512", TextEncoder().encode(str)).then { buf: ArrayBuffer ->
            val arr = Uint8Array(buf)
            val result = (0 until arr.length).joinToString("") { i ->
                arr[i].toString(16).padStart(2, '0')
            }
            continuation.resume(result)
        }
    }.uppercase()
}
