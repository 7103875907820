package com.rabbitsign.web

import com.rabbitsign.common.DocumentType
import com.rabbitsign.common.ME_NOW
import com.rabbitsign.common.SignerInfo
import com.rabbitsign.web.util.*
import com.rabbitsign.web.util.newDeleteButton
import com.rabbitsign.web.util.newFormControl
import kotlinx.browser.document
import kotlinx.dom.addClass
import org.w3c.dom.*
import kotlin.math.abs
import kotlin.math.round
import kotlin.math.roundToInt

const val SENDER_ME = "Sender (Me)"

@OptIn(ExperimentalJsExport::class)
@JsExport
@JsName("CreateTemplatePage")
object CreateTemplatePage : CreationPage(DocumentType.TEMPLATE) {
    override fun runPage() {
        log.info("run")

        initializeCreationPage()

        addRole()
        addAssigneeButton.onclick = { addRole() }

        val senderMeCheckbox = document.getElementById("RabbitSign-creation-senderFieldsCheckbox-input") as HTMLInputElement
        senderMeCheckbox.onchange = {
            if (senderMeCheckbox.checked) {
                addSenderMeRole()
            } else {
                removeSenderMeRole()
            }
        }
    }

    private fun addRole(roleName: String = ""): HTMLDivElement {
        log.info("addRole with roleName=$roleName")
        val newRole = document.createDiv("role-container", "form-row")
        newRole.appendChild(newFormControl("role-name", roleName, 7, 6, 5))
        newRole.appendChild(newSigningOrderInput())
        newRole.appendChild(newDeleteButton())

        assigneesForm.appendChild(newRole)
        return newRole
    }

    private fun addSenderMeRole(): HTMLDivElement {
        log.info("addSenderMeRole")
        val roleElem = addRole(SENDER_ME)
        roleElem.id = "RabbitSign-creation-templates-roles-senderMeRole-div"
        roleElem.addClass("order-first")
        roleElem.querySelectorAll("input").asList().forEach {
            (it as HTMLInputElement).readOnly = true
        }
        (roleElem.querySelector(".signing-order-input") as HTMLInputElement).apply {
            value = "1"
        }
        (roleElem.querySelector("button") as HTMLElement).remove()

        return roleElem
    }

    private fun removeSenderMeRole() {
        log.info("removeSenderMeRole")
        document.getElementById("RabbitSign-creation-templates-roles-senderMeRole-div")!!.remove()
        if (document.querySelector(".role-container") == null) {
            addRole()
        }
    }

    override fun getSignerInfo(): Map<String, SignerInfo>? {
        log.info("getSignerInfo")
        val rolesList = assigneesForm.querySelectorAll("div.role-container").asList()
            .map {
                val container = it as HTMLDivElement
                val roleNameInput = container.querySelector("input.role-name-input") as HTMLInputElement
                val signingOrderInput = container.querySelector("input.signing-order-input") as HTMLInputElement
                val signingOrder = if (signingOrderInput.disabled) 1.0 else signingOrderInput.valueAsNumber

                if (abs(signingOrder - round(signingOrder)) > 1e-8 || signingOrder.isInfinite() || signingOrder.isNaN()) {
                    displayWarningMessage(907, "Please use only whole numbers in the Signing Order.")
                    return null
                }
                SignerInfo(roleNameInput.value, roleNameInput.value, mutableListOf(), signingOrder.roundToInt())
            }
            .sortedBy { it.email != SENDER_ME }  // put Sender (Me) first; sort is stable

        if (rolesList.size != rolesList.distinctBy { it.email }.size) {
            displayWarningMessage(905, "Please remove any duplicate role names.")
            return null
        }
        if (rolesList.find { it.email == ME_NOW } != null) {
            displayInfoMessage("$ME_NOW is a reserved role name. Please use a different one.")
            return null
        }
        if (rolesList.find { it.email.startsWith(ADDED_SIGNER) } != null) {
            displayInfoMessage("$ADDED_SIGNER is a reserved role prefix. Please use a role name that starts with something else.")
            return null
        }

        val signingOrderValidityMessage = isSigningOrderValid(rolesList.map { it.signingOrder })
        if (signingOrderValidityMessage != null) {
            displayInfoMessage(signingOrderValidityMessage)
            return null
        }

        // https://kotlinlang.org/api/latest/jvm/stdlib/kotlin.sequences/associate-by.html
        // "The returned map preserves the entry iteration order of the original sequence."
        // this is important because the assigneeSelect element will be populated by iterating through this map
        return rolesList.associateBy { it.email }
    }
}
