package com.rabbitsign.common

import kotlinx.serialization.Serializable

// FIXME: decouple SignerStatus from SignerAction (used in FolderHistory)
enum class SignerStatus {
    CREATED, NOTIFIED, VIEWED, SIGNED, DELETED, CANCELED
}

@Serializable
data class FolderStatus(val folderId: String, val signers: List<SignerSummary>)

@Serializable
data class SignerSummary(val email: String, val name: String, val status: SignerStatus, val signingOrder: Int)

